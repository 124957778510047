
import simpleRestProvider from 'ra-data-simple-rest'
import { httpClient } from '../helpers'

const dataProvider = simpleRestProvider('/api', httpClient)

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    })

const cfsDataProvider = {
    ...dataProvider,

    create: (resource, params) => {
        if (resource !== 'unions' || !params.data.logo) {
            // fallback to the default implementation
            return dataProvider.create(resource, params)
        }

        const logoFile = params.data.logo
        return Promise.resolve(convertFileToBase64(logoFile))
            .then(newFile => dataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    logo: newFile
                }
            }))
    },

    update: (resource, params) => {
        if (resource !== 'unions' || !params.data.logo) {
            // fallback to the default implementation
            return dataProvider.update(resource, params)
        }

        const logoFile = params.data.logo
        if (typeof logoFile !== 'object') {
            return dataProvider.update(resource, params)
        }

        return Promise.resolve(convertFileToBase64(logoFile))
            .then(newFile => dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    logo: newFile
                }
            }))
    }
}

export default cfsDataProvider
