import React from 'react'
import { determineLangFromUri, getT, i18nUri } from '../../../helpers'
import SummaryBlock from './SummaryBlock'

export default function FlexPlanSummary({ i18n, state: { flexPlan, plans } }) {
    const t = getT()
    const lang = determineLangFromUri()
    const editUrl = i18nUri('/flex/plans')
    const selectedFlexPlan = flexPlan.options.find(opt => opt.id === plans.plan)
    return (
        <SummaryBlock header={t('flexplan')} editUrl={editUrl}>
            {() => {
                return (
                    <ul className="space-y-1">
                        <li className="font-semibold">{selectedFlexPlan.value[lang]}</li>
                        <li className="">{selectedFlexPlan.highlight[lang]}</li>
                    </ul>
                )
            }}
        </SummaryBlock>
    )
}
