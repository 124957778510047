import React from "react";
import { useHistory } from "react-router-dom";
import { getT, i18nUri } from "../../../helpers";
import Loading from "./Loading";

export default function ConfirmationWidget({
    flex,
    goBack,
    code,
    sending,
    message,
    warning,
    submissionError,
}) {
    const t = getT();
    const history = useHistory();
    const back = flex ? i18nUri("/flex/review") : i18nUri("/form/review");
    return (
        <div>
            {sending ? (
                <Loading />
            ) : (
                <>
                    {!submissionError ? (
                        <>
                            <div className="my-4 mx-6 bg-white rounded-xl p-4 pb-8">
                                <div className="flex ">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-16 text-dark-green flex-none h-16 w-16"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        />
                                    </svg>
                                    <div className="ml-1">
                                        <h1
                                            className=""
                                            style={{ fontSize: "40px" }}
                                        >
                                            {t("confirmation-title")}
                                        </h1>
                                        <p className="text-sm text-gray-600 mt-2">
                                            {message}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex mt-6 bg-yellow-50 py-6">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="19"
                                        viewBox="0 0 22 19"
                                        className="w-16 text-yellow-400 flex-none h-7 w-7"
                                    >
                                        <g
                                            fill="none"
                                            fillRule="evenodd"
                                            stroke="none"
                                            strokeWidth="1"
                                        >
                                            <g
                                                fill="#E4B949"
                                                fillRule="nonzero"
                                                transform="translate(-428 -359)"
                                            >
                                                <g transform="translate(376 170)">
                                                    <g transform="translate(32 168)">
                                                        <g transform="translate(20 21)">
                                                            <path d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z"></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <div className="">
                                        <h2 className="font-semibold text-yellow-700">
                                            {t("not-approval-title")}
                                        </h2>
                                        <p className="font-light leading-6 text-sm text-yellow-700">
                                            {warning}
                                        </p>
                                    </div>
                                </div>
                                <p className="py-8 text-gray-700">
                                    {t("submission-code")} &nbsp;
                                    <span className="p-2 bg-light-blue leading-8 font-bold">
                                        {code}
                                    </span>
                                </p>
                                <p className="pb-2 text-gray-700 text-sm">
                                    {t("confirmation-moreinfo")}
                                </p>
                                <p className="text-gray-700 text-sm">
                                    {t("confirmation-contactinfo")}
                                </p>
                                <p className="text-gray-700 text-sm">
                                    {t("confirmation-question")}
                                </p>
                            </div>

                            <div className="my-8 mx-6">
                                <button
                                    onClick={goBack}
                                    type="submit"
                                    className="form-btn flex justify-center items-center"
                                >
                                    {t("back-to-front")}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5 ml-2"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="my-4 mx-6 text-center space-y-4">
                            <div className="form-item">
                                <span className="form-error">
                                    {submissionError}
                                </span>
                            </div>
                            <button
                                className="form-btn flex justify-center items-center"
                                onClick={() => history.push(back)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-3"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                {t("edit-submission")}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
