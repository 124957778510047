import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    determineLangFromUri,
    fetchUnionLogo,
    findUnionObject,
    getT,
    getUniversityAndUnionNames,
    getUserSelectedUniversityAndUnion,
    i18nUri,
    isInvitation,
    useQuery,
} from "../helpers";
import AppContext from "../appContext";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useDocumentTitle } from "../helpers/useDocumentTitle";

function LanguageToggle() {
    const { i18n } = useTranslation();

    return (
        <>
            {i18n.language === "en" ? (
                <span className="font-bold">EN</span>
            ) : (
                <Link
                    to={i18nUri(null, "en")}
                    onClick={(e) => i18n.changeLanguage("en")}
                    className="underline"
                >
                    EN
                </Link>
            )}
            &nbsp; | &nbsp;
            {i18n.language === "fr" ? (
                <span className="font-bold">FR</span>
            ) : (
                <Link
                    to={i18nUri(null, "fr")}
                    onClick={(e) => i18n.changeLanguage("fr")}
                    className="underline"
                >
                    FR
                </Link>
            )}
        </>
    );
}

const UniversityUnionBanner = ({ canSwitchInstitution }) => {
    const t = getT();
    const [state, setState] = React.useContext(AppContext);
    const [data, setData] = React.useState();
    React.useEffect(() => {
        if (state.universities && state.selectedUnion) {
            const data = getUniversityAndUnionNames(
                state.universities,
                state.selectedUnion,
            );
            setData(data);
        }
    }, [state, state.universities, state.selectedUnion]);

    useDocumentTitle(
        data?.university && data?.union
            ? `${data.university} / ${data.union}`
            : "",
    );

    return (
        <>
            {data ? (
                <section
                    role="contentinfo"
                    className="h-16 p-4 text-center bg-light-blue text-sm"
                >
                    <span className="">{data.university}</span> /{" "}
                    <span className="">{data.union}</span>
                    {canSwitchInstitution && (
                        <p className="my-2 text-gray-600">
                            &raquo;{" "}
                            <Link
                                className="text-sm font-bold"
                                to="/institution"
                            >
                                {t("switch-institution")}
                            </Link>
                        </p>
                    )}
                </section>
            ) : (
                <section
                    role="contentinfo"
                    className="h-16 p-4 text-center bg-light-blue text-sm"
                >
                    <span>National Student Health Network</span>
                </section>
            )}
        </>
    );
};

export default function Layout({
    bgColor,
    schoolUnionInfo,
    canSwitchInstitution,
    children,
}) {
    const { i18n } = useTranslation();
    const [unionName, setUnionName] = useState();
    const query = useQuery();
    const [state, setState] = React.useContext(AppContext);
    const [selectedUnionCode, setSelectedUnionCode] = useState();
    const [linkToHomepage, setLinkToHomepage] = useState();
    const year = new Date().getFullYear().toString();
    React.useEffect(() => {
        const unionCode = query.get("union") || undefined;

        const logo = async () => {
            const path = await fetchUnionLogo(unionCode);
            setState((previous) => {
                return { ...previous, unionLogoPath: path };
            });
        };

        if (unionCode !== undefined) {
            setSelectedUnionCode(unionCode);
            setState((previous) => ({ ...previous, selectedUnion: unionCode }));
            logo();
        }

        const loadUniversities = async () => {
            await axios.get("/api/data/universities").then(({ data }) => {
                setState((state) => {
                    return { ...state, universities: data };
                });
            });
        };

        const loadInvitationPlan = async () => {
            const { data } = await axios.get(
                `/api/data/invitation?${query.toString()}`,
            );
            setState((prevState) => ({
                ...prevState,
                invitation: {
                    email: data.email,
                    expires: Number(query.get("expires")) * 1000,
                    plan_id: data.id,
                    plan: data,
                },
            }));
        };

        if (!state.hasOwnProperty("universities")) {
            loadUniversities();
        }

        if (isInvitation(query) && !state?.invitation?.plan) {
            loadInvitationPlan();
        }
    }, []);

    useEffect(() => {
        const { selectedUnion, universities } = state;
        const u = findUnionObject(selectedUnion, universities);
        if (u !== undefined) {
            setUnionName(u.name);
        }

        if (selectedUnion) {
            let link = `/?union=${selectedUnion}`;
            if (i18n.language === "fr") link = `/fr${link}`;
            setLinkToHomepage(link);
        }
    }, [state]);

    return (
        <>
            <Helmet
                bodyAttributes={{
                    style: `background-color:${bgColor ? bgColor : "#F4F7F8"}`,
                }}
            />
            <div className="flex flex-col min-h-screen">
                <header className="bg-white flex justify-between items-center h-20 py-4 px-6 shadow">
                    <Link
                        className="flex items-center"
                        to={i18nUri("/institution", i18n.language)}
                    >
                        <img
                            className=""
                            src="../img/NSHN.png"
                            alt="National Student Health Network"
                            height={60}
                            style={{ height: 60 }}
                        />
                    </Link>
                    <div className="">
                        <LanguageToggle />
                    </div>
                </header>
                <div className="flex-1">
                    {["/institution", "/fr/institution"].includes(
                        window.location.pathname,
                    ) ? null : (
                        <>
                            {state &&
                                state.unionLogoPath !== null &&
                                linkToHomepage && (
                                    <Link
                                        to={linkToHomepage}
                                        className="flex justify-center items-center bg-white border-t border-b py-2"
                                    >
                                        <img
                                            className="sm:max-w-lg max-h-28"
                                            src={state.unionLogoPath}
                                            alt={unionName}
                                        />
                                    </Link>
                                )}
                            {state &&
                                state.unionLogoPath === null &&
                                linkToHomepage &&
                                !["/", "/fr"].includes(
                                    window.location.pathname,
                                ) && (
                                    <div className="h-28 flex justify-center items-center border-t border-b bg-white">
                                        <Link
                                            to={linkToHomepage}
                                            className="text-sm "
                                        >
                                            &laquo; Home page
                                        </Link>
                                    </div>
                                )}
                        </>
                    )}
                    {<UniversityUnionBanner />}
                    <main className="max-w-3xl mx-auto">{children}</main>
                </div>
                <footer className="h-64 mt-4 px-6 py-4 bg-dark-blue text-center">
                    <div className="max-w-2xl m-auto">
                        {/*<ul className="flex justify-between items-center text-white text-sm">
                            <li>
                                <Link to="/">Privacy</Link>
                            </li>
                            <li>
                                <Link to="/">Accessibility</Link>
                            </li>
                            <li>
                                <Link to="/">Security</Link>
                            </li>
                            <li>
                                <Link to="/">Legal</Link>
                            </li>
                            <li className="text-xs text-white hidden sm:block">
                                &copy;{year} National Student Health Network
                            </li>
                        </ul>*/}
                        <p className="mt-6 mb-2 text-xs text-center text-white">
                            &copy;{year} National Student Health Network
                        </p>
                        <p className="mt-8 mb-2 text-xs text-white">
                            {i18n.t("footer.trademark")}
                        </p>
                        <p className="text-xs text-white">
                            {i18n.language === "en" ? (
                                <>
                                    National Student Health Network Canada is
                                    committed to inclusivity and providing
                                    accessible information and communications.
                                    If you require accessible communication
                                    format or support to use this site, or if
                                    you have any feedback on how we can make
                                    this site more accessible for persons with
                                    disabilities, please click{" "}
                                    <a href="mailto:support@studenthealth.ca">
                                        here
                                    </a>{" "}
                                    or contact{" "}
                                    <a href="mailto:support@studenthealth.ca">
                                        support@studenthealth.ca
                                    </a>
                                    .
                                </>
                            ) : (
                                <>
                                    National Student Health Network Canada est
                                    engagé envers l'inclusivité et fournit des
                                    informations et communications accessibles.
                                    Si vous nécessitez un format de
                                    communication accessible ou un soutien pour
                                    utiliser ce site, ou si vous avez des
                                    commentaires sur la manière dont nous
                                    pourrions rendre ce site plus accessible aux
                                    personnes handicapées, veuillez cliquer ici
                                    ou contacter{" "}
                                    <a href="mailto:support@studenthealth.ca">
                                        support@studenthealth.ca
                                    </a>
                                    .
                                </>
                            )}
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
}
