/**
 * Formats person/record's sex for export
 * @param {'male'|'female'|'other'} sex
 * @returns 'M'|'F'|'U'
 */
export function formatSex(sex) {
    switch (sex) {
        case "male":
            return "M";
        case "female":
            return "F";
        default:
            return "U";
    }
}
/**
 * Formats date of birth string to YYYYMMDD format e.g 23/01/1956 -> 19560123
 * @param {string} dob
 */
export function formatBirthDate(dob) {
    return dob.replaceAll(/[\/-]/g, "");
}
/**
 * Formats Family Stats based on enroll_additional value
 * @param {number} familySize
 * @returns 'S'|'C2'|'F1'
 */
export function formatFamilyStatus(familySize) {
    switch (familySize) {
        case 1:
            return "S";
        case 2:
            return "C2";
        default:
            return "F1";
    }
}

/**
 *
 * @param {'commonlaw'|'spouse'|'child'} relationship
 * @returns 'SU' | 'SP' | 'DE'
 */
export function formatDependentCode(relationship) {
    switch (relationship) {
        case "commonlaw":
            return "SU";
        case "spouse":
            return "SP";
        case "child":
        default:
            return "DE";
    }
}
