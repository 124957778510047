import React from 'react'
import DateOfBirth from './DateOfBirth'
import Identity from './Identity'
import Sex from './Sex'

export default function PersonCommonInfo({ pos }) {
    return (
        <>
            <Identity pos={pos} />
            <DateOfBirth pos={pos} />
            <Sex pos={pos} />
        </>
    )
}
