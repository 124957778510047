import { getPersonFieldName, getRelationList, getT } from "../../../helpers";
import ArrayFieldErrorMessage from "./ArrayFieldErrorMessage";
import PersonCommonInfo from "./PersonCommonInfo";
import Tooltip from "./Tooltip";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

function Person({ hasPartner, canClose, discard, pos }) {
    const [visible, setVisible] = useState(!pos);
    const formik = useFormikContext();
    const { i18n } = useTranslation();
    const t = getT();
    const relations = getRelationList();
    const index = Number(pos);

    /**
     * Filters out partner types if a partner has already been previously set in the form
     * @returns {{ [key: string]: { en: string, fr: string; }}}
     */
    const availableRelations = useMemo(() => {
        if (hasPartner) {
            const { spouse, commonlaw, ...rest } = relations;
            return rest;
        }
        return relations;
    }, [hasPartner, relations]);

    useEffect(() => {
        setTimeout(() => {
            setVisible(true); // fade in when new <Person /> is added
        }, 50);
    }, []);
    const onClose = () => {
        setVisible(false); // fade out when closed
        setTimeout(() => {
            discard();
        }, 500);
    };
    return (
        <div
            className={`mt-10 space-y-4 border border-dotted p-4 bg-white rounded-xl transition-all duration-500 ease-in-out ${
                visible ? "opacity-100" : "opacity-0"
            }`}
        >
            {canClose && (
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        type="button"
                        className="text-gray-500 rounded p-1 hover:bg-gray-200 hover:text-black"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>
            )}

            <div className="">
                <label>
                    <div className="flex">
                        {t("forms.relationship")}
                        <Tooltip label={t("helpers.relationship")} />
                    </div>
                    <select
                        className="w-full form-ring-item"
                        {...formik.getFieldProps(
                            getPersonFieldName("relation", pos)
                        )}
                    >
                        <option>{t("forms.selectFromList")}</option>
                        {Object.keys(availableRelations).map((code) => (
                            <option value={code} key={code}>
                                {availableRelations[code][i18n.language]}
                            </option>
                        ))}
                    </select>
                </label>
                {formik.touched.relation && formik.errors.relation ? (
                    <div className="form-error">{formik.errors.relation}</div>
                ) : null}
                <ArrayFieldErrorMessage name={`persons[${index}].relation`} />
            </div>

            <PersonCommonInfo pos={pos} />
        </div>
    );
}

Person.propTypes = {
    hasPartner: PropTypes.bool,
    canClose: PropTypes.bool,
    discard: PropTypes.func,
    pos: PropTypes.number,
};

Person.defaultProps = {
    hasPartner: false,
};

export default Person;
