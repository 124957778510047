import { httpClient } from "../../helpers";
import {
    formatBirthDate,
    formatDependentCode,
    formatFamilyStatus,
    formatSex,
} from "../../helpers/exporter";
import FileDownloadField from "./FileDownloadField";
import { Grid, Dialog } from "@material-ui/core";
import { Chip, makeStyles } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import { useTranslate } from "ra-core";
import React, { useState } from "react";
import {
    ArrayField,
    Datagrid,
    DateField,
    FunctionField,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TextInput,
    downloadCSV,
    useNotify,
    useRefresh,
    useShowController,
} from "react-admin";
import { Form } from "react-final-form";
import SubmissionApprovalDialog from "../Dialogs/SubmissionApprovalDialog";
import SubmissionRejectionDialog from "../Dialogs/SubmissionRejectionDialog";
import SubmissionRefundDialog from "../Dialogs/SubmissionRefundDialog";

const relationships = {
    spouse: "Spouse",
    commonlaw: "Common Law",
    child: "Child",
};

const sexes = {
    male: "Male",
    female: "Female",
    other: "Other",
};

const NonInput = React.memo(function NonInput({ children }) {
    return children;
});

const SubmissionTitle = ({ record }) => {
    return (
        <span>
            Submission for{" "}
            {record
                ? `${record.student_first_name} ${record.student_last_name} (${record.student_number})`
                : ""}
        </span>
    );
};

const Aside = ({ record }) => {
    const t = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const [updating, setUpdating] = useState(false);
    const [openApprovalDialog, setOpenApprovalDialog] = useState(false);
    const [openRejectionDialog, setOpenRejectionDialog] = useState(false);
    const [openRefundDialog, setOpenRefundDialog] = useState(false);

    const approveSubmission = async (values) => {
        setUpdating(true);
        try {
            await httpClient(`/api/submissions/${record.id}`, {
                method: "PUT",
                body: JSON.stringify({
                    approve: true,
                    approval_notes: values.approval_notes,
                }),
            })
                .then(({ json }) => {
                    refresh();
                    notify("cfs.submission-approved", "success");
                })
                .catch((err) => {
                    throw err;
                });
        } catch (err) {
            notify(
                t("cfs.submission-approved-error") +
                    ": " +
                    err.error.body.error,
                "error",
            );
        } finally {
            setUpdating(false);
        }
    };
    const rejectSubmission = async (values) => {
        if (values.refund_amount > record.charge_amount) {
            notify("cfs.submission-refunded-over-amount", "warning");
            return;
        }
        setUpdating(true);
        try {
            await httpClient(`/api/submissions/${record.id}`, {
                method: "PUT",
                body: JSON.stringify({
                    approve: false,
                    refund_amount: values.new_refund_amount,
                    rejection_notes: values.rejection_notes,
                    notes: values.response_notes,
                }),
            })
                .then(({ json }) => {
                    refresh();
                    notify("cfs.submission-rejected", "info");
                })
                .catch((err) => {
                    throw err;
                });
        } catch (err) {
            console.log({ err });
            notify(
                t("cfs.submission-rejected-error") +
                    ": " +
                    err.error.body.error,
                "error",
            );
        } finally {
            setUpdating(false);
        }
    };
    const refundSubmission = async (values) => {
        if (values.refund_amount > record.charge_amount) {
            notify("cfs.submission-refunded-over-amount", "warning");
            return;
        }
        setUpdating(true);
        try {
            await httpClient(`/api/submissions/${record.id}`, {
                method: "PUT",
                body: JSON.stringify({
                    refund: true,
                    refund_notes: values.refund_notes,
                    refund_amount: values.new_refund_amount,
                }),
            })
                .then(({ json }) => {
                    refresh();
                    notify("cfs.submission-refunded", "info");
                })
                .catch((err) => {
                    throw err;
                });
        } catch (err) {
            notify(
                t("cfs.submission-refunded-error") +
                    ": " +
                    err.error.body.error,
                "error",
            );
        } finally {
            setUpdating(false);
        }
    };

    return (
        <div style={{ width: 200, marginLeft: "1em" }}>
            <Form
                onSubmit={(values) => console.log(values)}
                initialValues={{
                    approval_notes: record ? record.approval_notes : "",
                    rejection_notes: record ? record.rejection_notes : "",
                    response_notes: record ? record.response_notes : "",
                    new_refund_amount: record
                        ? record.charge_amount - record.refund_amount
                        : 0,
                }}
                render={(props) => {
                    const { values } = props;
                    return (
                        <>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: "30px" }}
                                >
                                    <button
                                        type="button"
                                        className="flex w-full text-white rounded shadow py-1 justify-center uppercase"
                                        style={{
                                            backgroundColor: "rgb(46, 125, 50)",
                                        }}
                                        onClick={() =>
                                            setOpenApprovalDialog(true)
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 mr-2"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                        </svg>
                                        {t("cfs.submission-btn-approve")}
                                    </button>
                                    <SubmissionApprovalDialog
                                        updating={updating}
                                        record={record}
                                        onClose={() =>
                                            setOpenApprovalDialog(false)
                                        }
                                        onSubmit={() =>
                                            approveSubmission(values)
                                        }
                                        open={openApprovalDialog}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: "30px" }}
                                >
                                    <button
                                        type="button"
                                        className="flex w-full text-white rounded shadow py-1 justify-center uppercase"
                                        style={{
                                            backgroundColor: "rgb(211, 47, 47)",
                                        }}
                                        onClick={() =>
                                            setOpenRejectionDialog(true)
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 mr-2"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        {t("cfs.submission-btn-reject")}
                                    </button>
                                    <SubmissionRejectionDialog
                                        updating={updating}
                                        record={record}
                                        onClose={() =>
                                            setOpenRejectionDialog(false)
                                        }
                                        onSubmit={() =>
                                            rejectSubmission(values)
                                        }
                                        open={openRejectionDialog}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: "30px" }}
                                >
                                    <button
                                        disabled={!record.stripe_payment_id}
                                        type="button"
                                        className="flex w-full text-white rounded shadow py-1 justify-center uppercase"
                                        style={{
                                            backgroundColor:
                                                record.stripe_payment_id
                                                    ? "rgb(238, 136, 0)"
                                                    : "rgb(227,204,172)",
                                        }}
                                        onClick={() =>
                                            setOpenRefundDialog(true)
                                        }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 mr-2"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        {t("cfs.submission-btn-refund")}
                                    </button>
                                    <SubmissionRefundDialog
                                        updating={updating}
                                        record={record}
                                        onClose={() =>
                                            setOpenRefundDialog(false)
                                        }
                                        onSubmit={() =>
                                            refundSubmission(values)
                                        }
                                        open={openRefundDialog}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        multiline
                                        source="response_notes"
                                        disabled={
                                            !!(record && record.reviewed_at)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            />

            <div className="flex justify-center mt-8">
                {updating && (
                    <svg
                        className="text-light-green animate-spin -ml-1 mr-3 h-12 w-12"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            className="opacity-25"
                        ></circle>
                        <path
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            className="opacity-75"
                        ></path>
                    </svg>
                )}
            </div>
        </div>
    );
};

const StatusField = ({ value }) => {
    const t = useTranslate();
    return (
        <>
            {["approved", "partial refund"].includes(value) ? (
                <span className="flex justify-center items-center w-40 py-1 bg-green-100 border border-green-300 rounded text-gray-500 capitalize font-semibold">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-2 text-green-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                    </svg>{" "}
                    {t(
                        value === "partial refund"
                            ? "cfs.submission-status-partial-refund"
                            : "cfs.submission-btn-approve",
                    )}
                </span>
            ) : (
                <span className="flex justify-center items-center w-40 py-1 bg-red-100 border border-red-300 rounded text-gray-500 capitalize font-semibold">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-2 text-red-500"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                            clipRule="evenodd"
                        />
                    </svg>{" "}
                    {t(
                        value === "refunded"
                            ? "cfs.submission-btn-refund"
                            : "cfs.submission-btn-reject",
                    )}
                </span>
            )}
        </>
    );
};

export const SubmissionShow = (props) => {
    const { record } = useShowController(props);
    return (
        <Show
            title={<SubmissionTitle />}
            aside={record && record.status === "incomplete" ? <Aside /> : null}
            {...props}
        >
            <SimpleShowLayout>
                <GridShowLayout>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <DateField
                                addLabel={true}
                                source="created_at"
                                options={{
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                }}
                            />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            {record && record.status === "incomplete" ? (
                                <TextField source="status" />
                            ) : (
                                <StatusField
                                    value={record ? record.status : null}
                                />
                            )}
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <NumberField
                                source="charge_amount"
                                options={{ style: "currency", currency: "USD" }}
                            />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="university" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="union" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="plan" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_type" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_degree" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_enrollment" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_year_start" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_number" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_first_name" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_last_name" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_dob" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_sex" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_email" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_address" />
                            <TextField source="student_address_2" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_city" />
                            <TextField source="student_province" />
                            <TextField source="student_postalcode" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_area_code" />
                            <TextField source="student_phone_number" />
                        </RaGrid>
                    </RaGrid>
                    {record && record.status === "rejected" && (
                        <RaGrid container>
                            <RaGrid item xs={12}>
                                <TextField source="response_notes" />
                            </RaGrid>
                        </RaGrid>
                    )}
                    <RaGrid container>
                        <RaGrid item xs={12}>
                            <ArrayField source="persons" label="cfs.persons">
                                <Datagrid>
                                    <FunctionField
                                        source="relationship_to_student"
                                        render={(record) =>
                                            relationships[
                                                record.relationship_to_student
                                            ]
                                        }
                                        label="cfs.relationship"
                                    />
                                    <FunctionField
                                        source="sex"
                                        render={(record) => sexes[record.sex]}
                                        label="cfs.sex"
                                    />
                                    <TextField
                                        source="first_name"
                                        label="cfs.firstname"
                                    />
                                    <TextField
                                        source="last_name"
                                        label="cfs.lastname"
                                    />
                                    <DateField
                                        source="date_of_birth"
                                        options={{
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        }}
                                        label="cfs.dob"
                                    />
                                </Datagrid>
                            </ArrayField>
                        </RaGrid>
                    </RaGrid>

                    <RaGrid container>
                        <RaGrid item xs={12}>
                            <ArrayField source="files" label="cfs.files">
                                <Datagrid>
                                    <FileDownloadField source="" />
                                </Datagrid>
                            </ArrayField>
                        </RaGrid>
                    </RaGrid>
                </GridShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

const useQuickFilterStyles = makeStyles((theme) => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const submissionFilters = [
    <TextInput label="cfs.search" source="q" alwaysOn />,
    <ReferenceInput
        label="University"
        source="university_id"
        reference="universities"
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput label="Union" source="union_id" reference="unions">
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <QuickFilter
        source="status"
        label="cfs.incomplete-filter"
        defaultValue="incomplete"
    />,
];

const exporter = async (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, "plan_id", "plans")
        .then((plans) => {
            return records;

            // return records.map((record) => {
            //     return {
            //         ...record,
            //     };
            // });
        })
        .then((records) => {
            const data = [];
            records.map((record) => {
                const {
                    id,
                    persons,
                    student_email,
                    student_address,
                    student_address_2,
                    student_area_code,
                    student_phone_number,
                    ...fields
                } = record;

                data.push({
                    ...fields,
                    relation: "",
                    Address: student_address,
                    "Address 2": student_address_2,
                    City: fields.student_city,
                    Country: fields.student_country,
                    "Cell phone": `(${student_area_code}) ${student_phone_number}`,
                    "Email address": student_email,
                });
                if (persons) {
                    for (let p of persons) {
                        const person = { ...fields };
                        person.student_dob = p.date_of_birth;
                        person.student_first_name = p.first_name;
                        person.student_last_name = p.last_name;
                        person.student_sex = p.sex;
                        person.relation = p.relationship_to_student;
                        person["Dependent Codes"] = formatDependentCode(
                            p.relationship_to_student,
                        );
                        data.push(person);
                    }
                }
            });
            return data;
        })
        .then((rows) => {
            const data = rows.map(
                ({
                    university_id,
                    union_id,
                    plan_id,
                    persons,
                    planIsDental,
                    ...rest
                }) => ({
                    ...rest,
                    student_sex: formatSex(rest.student_sex),
                    student_dob: formatBirthDate(rest.student_dob),
                    "Action Indicator": "A",
                    Province: rest.student_province,
                    "Province of Residence": rest.student_province,
                }),
            );
            jsonExport(
                data,
                {
                    headers: [
                        "created_at",
                        "code",
                        "client_id",
                        "billing_division",
                        "student_number",
                        "Province of Residence",
                        "Province of Employment",
                        "student_last_name",
                        "student_first_name",
                        "student_sex",
                        "student_dob",
                        "Employment Date",
                        "Enrollment Date",
                        "Action Indicator",
                        "Action Indicator Effective Date",
                        "Dependent Codes",
                        "relation",
                        "language",
                        "default_family_status",
                        "benefit_type_code_1",
                        "family_status_1",
                        "Family Status Effective Date 1",
                        "benefit_type_code_2",
                        "family_status_2",
                        "Family Status Effective Date 2", // leave blank
                        "Address",
                        "Address 2",
                        "City",
                        "Province",
                        "Country",
                        "student_postalcode",
                        "Cell phone",
                        "Email address",
                        "university",
                        "union",
                        "plan",
                        "charge_amount",
                        "refund_amount",
                        "refunded_at",
                        "refund_notes",
                        "rejection_notes",
                        "approval_notes",
                        "status",
                        "student_city",
                        "student_province",
                        "student_country",
                        "student_degree",
                        "student_enrollment",
                        "student_type",
                        "student_year_start",
                        "reviewed_at",
                        "reviewed_by",
                        "response_notes",
                    ],
                    mapHeaders: (header) => {
                        switch (header) {
                            case "client_id":
                                return "Client ID";
                            case "billing_division":
                                return "Billing Division";
                            case "relation":
                                return "Relationship to the student";
                            case "default_family_status":
                                return "Default Family Status";
                            case "family_status_1":
                                return "Family Status 1";
                            case "family_status_2":
                                return "Family Status 2";
                            case "language":
                                return "Language Code";
                            case "benefit_type_code_1":
                                return "Benefit Type Code 1";
                            case "benefit_type_code_2":
                                return "Benefit Type Code 2";
                            case "student_number":
                                return "Student ID";
                            case "student_last_name":
                                return "Last Name";
                            case "student_first_name":
                                return "Legal First Name";
                            case "student_sex":
                                return "Sex";
                            case "student_dob":
                                return "Birth Date";
                            case "student_postalcode":
                                return "Postal Code";
                            case "student_province":
                                return "Province";
                            case "refund_amount":
                                return "Refund Amount";
                            case "refunded_at":
                                return "Date of the Refund";
                            case "refund_notes":
                                return "Refund Notes";
                            case "rejection_notes":
                                return "Rejection Notes";
                            case "approval_notes":
                                return "Approval Notes";
                            default:
                                return header;
                        }
                    },
                },
                (err, csv) => {
                    downloadCSV(csv, `submissions-${new Date().getTime()}`);
                },
            );
        });
};

export const SubmissionList = (props) => (
    <List {...props} filters={submissionFilters} exporter={exporter}>
        <Datagrid>
            <DateField
                source="created_at"
                options={{ year: "numeric", month: "short", day: "numeric" }}
            />
            <TextField source="university" sortBy="university_id" />
            <TextField source="union" sortBy="union_id" />
            <TextField source="plan" sortBy="plan_id" />
            <TextField source="student_number" />
            <TextField source="status" />
            <TextField source="student_first_name" />
            <TextField source="student_last_name" />
            <NumberField
                source="charge_amount"
                options={{ style: "currency", currency: "USD" }}
            />
            <ShowButton />
        </Datagrid>
    </List>
);
