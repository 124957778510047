import React from 'react'
import { Field, getIn } from 'formik'

export default function ArrayFieldErrorMessage({ name }) {
    return (
        <Field name={name}>
            {({ meta }) => (
                <div>
                    {meta.touched && meta.error && (
                        <div className="form-error">{meta.error}</div>
                    )}
                </div>
            )}
        </Field>
    )
}
