import React, { useState, useEffect } from 'react'
import {
    GridShowLayout,
    RaGrid
} from "ra-compact-ui"
import {
    useTranslate
} from "react-admin"
import { Typography } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'

const Controls = ({ options, setOptions, categories, setCategories, removeOption, addOption, removeCategory, addCategory }) => {
    const t = useTranslate()
    return (
        <>
            <RaGrid container>
                <RaGrid item xs={12} style={{ marginBottom: '2rem' }}>
                    <button
                        type="button"
                        className="flex w-full rounded shadow py-1 justify-center uppercase mb-2 bg-gray-400 text-white font-semibold"
                        onClick={() => addOption({ id: uuidv4(), value: { en: '', fr: '' }, highlight: { en: '', fr: '' } })}
                    >
                        {t('cfs.flex-add-option')}
                    </button>
                    {options
                        ? options.map(item => (
                            <div className="flex mb-2" key={item.id}>
                                <div className="flex-col">
                                    <div className="flex">
                                        <div className="flexplan-input-group">EN</div>
                                        <input type="text"
                                            name={`options[${item.id}]['en']`}
                                            value={item.value.en}
                                            onChange={e => setOptions(item.id, e.target.value, 'en')}
                                        />
                                    </div>
                                    <div className="flex">
                                        <div className="flexplan-input-group">FR</div>
                                        <input type="text"
                                            name={`options[${item.id}]['fr']`}
                                            value={item.value.fr}
                                            onChange={e => setOptions(item.id, e.target.value, 'fr')}
                                        />
                                    </div>
                                </div>
                                <button type="button"
                                    className="flex flex-none ml-2 hover:bg-gray-100 justify-center items-center px-2"
                                    onClick={() => removeOption(item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        ))
                        : <div />
                    }
                </RaGrid>

                <RaGrid item xs={12}>
                    <button
                        type="button"
                        className="flex w-full rounded shadow py-1 justify-center uppercase mb-2 bg-gray-400 text-white font-semibold"
                        onClick={() => addCategory({ id: uuidv4(), value: { en: '', fr: '' } })}
                    >
                        {t('cfs.flex-add-categ')}
                    </button>
                    {categories
                        ? categories.map(item => (
                            <div className="flex mb-2" key={item.id}>
                                <div className="flex-col">
                                    <div className="flex">
                                        <div className="flexplan-input-group">EN</div>
                                        <input type="text"
                                            name={`categories[${item.id}]['en']`}
                                            value={item.value.en}
                                            onChange={e => setCategories(item.id, e.target.value, 'en')}
                                        />
                                    </div>
                                    <div className="flex">
                                        <div className="flexplan-input-group">FR</div>
                                        <input type="text"
                                            name={`categories[${item.id}]['fr']`}
                                            value={item.value.fr}
                                            onChange={e => setCategories(item.id, e.target.value, 'fr')}
                                        />
                                    </div>
                                </div>
                                <button type="button"
                                    className="flex flex-none ml-2 hover:bg-gray-100 justify-center items-center px-2"
                                    onClick={() => removeCategory(item.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        ))
                        : <div />
                    }
                </RaGrid>
            </RaGrid>
        </>
    )
}

const Plan = ({ options, categories, setDescriptionValue, getDescriptionValue, displayLanguage, getHighlightValue, setHighlightValue }) => {
    const t = useTranslate()
    const allOptions = options ? options.filter(opt => opt.value[displayLanguage].length) : []
    const allCategories = categories ? categories.filter(categ => categ.value[displayLanguage].length) : []
    return (
        <div className="w-full">
            <div className="flex">
                <div className="flex-1"></div>
                {allOptions.map(option => (
                    <div className="flex-1" key={option.id}>
                        <Typography variant="h5">{option.value[displayLanguage]}</Typography>
                    </div>

                ))}
            </div>
            <ul className="">
                {options && (options.length > 0) && (<li key="highlights" className="flex h-48 border-2 border-gray-300 p-1 bg-yellow-50 rounded shadow">
                    <div className="flex-1">
                        <Typography variant="h5">{t('cfs.highligths')}</Typography>
                    </div>
                    {allOptions.map((option) => (
                        <div className="flex-1 p-2" key={option.id}>
                            {(displayLanguage === 'en') && <input
                                type="textarea"
                                name={`highlights[${option.id}]['en']`}
                                value={getHighlightValue(option.id, 'en')}
                                onChange={e => setHighlightValue(option.id, e.target.value, 'en')}
                                className="w-full h-full border border-gray-500 p-2"
                            />}
                            {(displayLanguage === 'fr') && <input
                                type="textarea"
                                name={`description[${option.id}]['fr']`}
                                value={getHighlightValue(option.id, 'fr')}
                                onChange={e => setHighlightValue(option.id, e.target.value, 'fr')}
                                className="w-full h-full border border-gray-500 p-2"
                            />}
                        </div>
                    ))}
                </li>)}
                {allCategories.map(category => (
                    <li key={category.id} className="flex h-48 border-b border-t border-gray-200">
                        <div className="flex-1">
                            <Typography variant="h5">{category.value[displayLanguage]}</Typography>
                        </div>
                        {allOptions.map((option) => (
                            <div className="flex-1 p-2" key={option.id}>
                                {(displayLanguage === 'en') && <input
                                    type="textarea"
                                    name={`description[${option.id}][${category.id}]['en']`}
                                    value={getDescriptionValue(option.id, category.id, 'en')}
                                    onChange={e => setDescriptionValue(option.id, category.id, e.target.value, 'en')}
                                    className="w-full h-full border border-gray-500 p-2"
                                />}
                                {(displayLanguage === 'fr') && <input
                                    type="textarea"
                                    name={`description[${option.id}][${category.id}]['fr']`}
                                    value={getDescriptionValue(option.id, category.id, 'fr')}
                                    onChange={e => setDescriptionValue(option.id, category.id, e.target.value, 'fr')}
                                    className="w-full h-full border border-gray-500 p-2"
                                />}
                            </div>
                        ))}
                    </li>
                ))}
            </ul>
        </div>
    )
}

const ToggleLanguage = ({ lang, onLanguageSelect }) => {
    const t = useTranslate()
    return (
        <div className="flex items-center justify-center mb-4">
            <button
                className={`flexplan-button-group rounded-l ${lang === 'en' ? 'bg-gray-500 text-white' : ''}`}
                onClick={() => onLanguageSelect('en')}
                type="button">
                {t('cfs.english')}
            </button>
            <button
                className={`flexplan-button-group rounded-r ${lang === 'fr' ? 'bg-gray-500 text-white' : ''}`}
                onClick={() => onLanguageSelect('fr')}
                type="button">
                {t('cfs.french')}
            </button>
        </div>
    )
}

export default function FlexPlan({
    record, options, categories,
    setOptions, setCategories,
    setDescriptionValue, getDescriptionValue, setDescriptions
}) {
    const [displayLanguage, setDisplayLanguage] = useState('en')
    useEffect(() => {
        const options = (record && record.flexPlan && record.flexPlan.options.length)
            ? record.flexPlan.options
            : []
        const categories = (record && record.flexPlan && record.flexPlan.categories.length)
            ? record.flexPlan.categories
            : []
        const descriptions = (record && record.flexPlan) ? record.flexPlan.descriptions : {}
        setOptions(options)
        setCategories(categories)
        setDescriptions(descriptions)
    }, [record])
    const onOptionChange = (id, value, lang) => {
        const option = options.find(option => option.id === id)
        option.value[lang] = value
        setOptions([...options])
    }
    const onCategoryChange = (id, value, lang) => {
        const category = categories.find(category => category.id === id)
        category.value[lang] = value
        setCategories([...categories])
    }
    const addOption = option => {
        const maxOrderOption = options.length ? options.reduce(function (prev, current) {
            return (prev.order > current.order) ? prev : current
        }) : 0
        option['order'] = maxOrderOption ? maxOrderOption.order + 1 : 1
        setOptions([...options, option])
    }
    const removeOption = id => setOptions([...options.filter(opt => opt.id !== id)])
    const addCategory = category => {
        const maxOrderCategory = categories.length ? categories.reduce(function (prev, current) {
            return (prev.order > current.order) ? prev : current
        }) : 0
        category['order'] = maxOrderCategory ? maxOrderCategory.order + 1 : 1
        setCategories([...categories, category])
    }
    const getHighlightValue = (optionId, lang) => {
        const option = options.find(option => option.id === optionId)
        return option.highlight[lang]
    }
    const setHighlightValue = (optionId, value, lang) => {
        const option = options.find(option => option.id === optionId)
        option.highlight[lang] = value
        setOptions([...options])
    }
    const removeCategory = id => setCategories([...categories.filter(category => category.id !== id)])
    return (
        <GridShowLayout>
            <RaGrid container>
                <RaGrid item xs={12}>
                    <ToggleLanguage lang={displayLanguage} onLanguageSelect={lang => setDisplayLanguage(lang)} />
                </RaGrid>
            </RaGrid>
            <RaGrid container>
                <RaGrid item xs={12}>
                    <div className="flex w-full">
                        <div className="w-1/4 mr-4">
                            <Controls
                                options={options}
                                setOptions={onOptionChange}
                                addOption={addOption}
                                removeOption={removeOption}
                                categories={categories}
                                setCategories={onCategoryChange}
                                addCategory={addCategory}
                                removeCategory={removeCategory}
                            />
                        </div>
                        <div className="w-3/4 bg-gray-100 rounded-lg border p-4">
                            <Plan
                                options={options}
                                displayLanguage={displayLanguage}
                                categories={categories}
                                setDescriptionValue={setDescriptionValue}
                                getDescriptionValue={getDescriptionValue}
                                getHighlightValue={getHighlightValue}
                                setHighlightValue={setHighlightValue}
                            />
                        </div>
                    </div>
                </RaGrid>
            </RaGrid>
        </GridShowLayout>
    )
}
