import { useFormikContext } from 'formik'
import React from 'react'
import { getPersonFieldName, getT } from '../../../helpers'
import ArrayFieldErrorMessage from './ArrayFieldErrorMessage'
import Tooltip from './Tooltip'

export default function Sex({ pos }) {
    const t = getT()
    const formik = useFormikContext()
    const fieldValue = (pos !== undefined) ? formik.values.persons[pos].sex : formik.values.sex
    // console.log(formik.values.persons[pos])
    return (
        <div className="flex flex-col">
            <div className="flex">
                <span className="">{t('forms.sex')}</span>
                <Tooltip label={t('helpers.sex')} />
            </div>
            <ul className="flex justify-between">
                <li>
                    <label>
                        <input className="mr-2 text-dark-green" type="radio" name={getPersonFieldName('sex', pos)} value="male"
                            checked={fieldValue === 'male'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {t('forms.male')}
                    </label>
                </li>
                <li>
                    <label>
                        <input className="mr-2 text-dark-green" type="radio" name={getPersonFieldName('sex', pos)} value="female"
                            checked={fieldValue === 'female'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {t('forms.female')}
                    </label>
                </li>
                <li>
                    <label>
                        <input className="mr-2 text-dark-green" type="radio" name={getPersonFieldName('sex', pos)} value="other"
                            checked={fieldValue === 'other'}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {t('forms.othersex')}
                    </label>
                </li>
            </ul>
            {formik.touched.sex && formik.errors.sex ? (
                <div className='form-error'>{formik.errors.sex}</div>
            ) : null}
            <ArrayFieldErrorMessage name={`persons[${Number(pos)}].sex`} />
        </div>
    )
}
