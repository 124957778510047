import React from 'react'
import AppContext from '../appContext'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { findUnionObject, getT } from '../helpers'

const prefixLangifRequired = (path, dest) => path.startsWith('/fr') ? `/fr${dest}` : dest

const prevNext = history => {
    const [state] = React.useContext(AppContext)
    const { location: { pathname } } = history
    const { selectedUnion } = state

    switch (pathname) {
        case "/form/school":
        case "/fr/form/school":
            return {
                prev: null,
                next: prefixLangifRequired(pathname, "/form/options")
            }

        case "/form/options":
        case "/fr/form/options":
            return {
                prev: prefixLangifRequired(pathname, "/form/school"),
                next: prefixLangifRequired(pathname, "/form/plans")
            }

        case "/form/plans":
        case "/fr/form/plans":
            return {
                prev: prefixLangifRequired(pathname, "/form/options"),
                next: prefixLangifRequired(pathname, "/form/student")
            }

        case "/form/student":
        case "/fr/form/student":
            return {
                prev: prefixLangifRequired(pathname, "/form/plans"),
                next: prefixLangifRequired(pathname, "/form/single-person")
            }

        case "/form/single-person":
        case "/fr/form/single-person":
            return {
                prev: prefixLangifRequired(pathname, "/form/student"),
                next: prefixLangifRequired(pathname, "/form/extra-persons")
            }

        case "/form/extra-persons":
        case "/fr/form/extra-persons":
            return {
                prev: prefixLangifRequired(pathname, "/form/single-person"),
                next: prefixLangifRequired(pathname, "/form/review")
            }

        case "/form/documents":
        case "/fr/form/documents":
            return {
                prev: prefixLangifRequired(pathname, "/form/student"),
                next: prefixLangifRequired(pathname, "/form/review")
            }

        case "/form/review":
        case "/fr/form/review":
            return {
                prev: prefixLangifRequired(pathname, "/form/extra-persons"),
                next: prefixLangifRequired(pathname, "/form/payment")
            }

        case "/form/payment":
        case "/fr/form/payment":
            return {
                prev: prefixLangifRequired(pathname, "/form/review"),
                next: prefixLangifRequired(pathname, "/form/confirmation")
            }

        case "/form/confirmation":
        case "/fr/form/confirmation":
            return {
                prev: prefixLangifRequired(pathname, "/form/payment"),
                next: prefixLangifRequired(pathname, "/")
            }

        case "/flex/plans":
        case "/fr/flex/plans":
            return {
                prev: prefixLangifRequired(pathname, "/"),
                next: prefixLangifRequired(pathname, "/flex/student")
            }

        case "/flex/student":
        case "/fr/flex/student":
            let next = null;
            const union = findUnionObject(selectedUnion, state.universities)
            return {
                prev: prefixLangifRequired(pathname, "/flex/plans"),
                next: (union && union.flexDocUpload)
                    ? prefixLangifRequired(pathname, "/flex/documents")
                    : prefixLangifRequired(pathname, "/flex/review")
            }

        case "/flex/documents":
        case "/fr/flex/documents":
            return {
                prev: prefixLangifRequired(pathname, "/flex/student"),
                next: prefixLangifRequired(pathname, "/flex/review")
            }

        case "/flex/review":
        case "/fr/flex/review":
            return {
                prev: prefixLangifRequired(pathname, "/flex/documents"),
                next: prefixLangifRequired(pathname, "/flex/confirmation")
            }

        case "/flex/confirmation":
        case "/fr/flex/confirmation":
            return {
                prev: prefixLangifRequired(pathname, "/flex/review"),
                next: prefixLangifRequired(pathname, "/")
            }

        default:
            break;
    }
}

const withHelpers = FormComponent => props => {
    const history = useHistory()
    const t = getT()
    const { i18n } = useTranslation()
    const [state, setState] = React.useContext(AppContext)
    return (
        <FormComponent
            t={t}
            history={history}
            state={state}
            i18n={i18n}
            setState={setState}
            prevNext={prevNext(history)}
            {...props}
        />
    )
}

export default withHelpers