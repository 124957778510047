import React, { useState, useRef } from "react";
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceArrayInput,
    Button,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TabbedForm,
    FormTab,
    ReferenceInput,
    SelectInput,
    TextInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    required,
    BooleanField,
    number,
    BooleanInput,
    useEditController,
    Show,
    ShowButton,
    SimpleShowLayout,
    ImageInput,
    ImageField,
    useNotify,
    useMutation,
    useRedirect,
    FunctionField,
    regex,
    DateInput,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    FormDataConsumer,
    useRefresh,
    email,
} from "react-admin";
import { useTranslate } from "ra-core";
import { GridShowLayout } from "ra-compact-ui/dist/details";
import RaGrid from "ra-compact-ui/dist/details/RaGrid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FlexPlan from "./FlexPlan";
import { CustomCreateToolbar, CustomEditToolbar } from "./CustomToolbars";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    convertToRaw,
    convertFromHTML,
    ContentState,
    EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import EmailTemplatesTab from "./Unions/Tabs/EmailTemplatesTab";
import OptInPeriodTab from "./Unions/Tabs/OptInPeriodTab";
import { Grid, Typography } from "@material-ui/core";
import Api from "../../services/api";
import { useForm } from "react-final-form";

const unionFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <BooleanInput source="flex" />,
];

export const UnionList = (props) => (
    <List filters={unionFilters} {...props}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField
                source="university_id"
                reference="universities"
                link={props.permissions === "admin"}
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <TextField source="code" />
            <BooleanField
                source="flex"
                valueLabelTrue="1"
                valueLabelFalse="0"
                looseValue
            />
            <BooleanField
                source="flexDocUpload"
                valueLabelTrue="1"
                valueLabelFalse="0"
                looseValue
            />
            <BooleanField
                source="opt_out_url_available"
                valueLabelTrue="1"
                valueLabelFalse="0"
                looseValue
            />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

const UnionTitle = ({ record }) => {
    return <span>Union {record ? `"${record.name}"` : ""}</span>;
};

export const UnionShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <GridShowLayout>
                    <RaGrid container>
                        <RaGrid item xs={6}>
                            <TextField disabled source="id" />
                            <ReferenceField
                                source="university_id"
                                reference="universities"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <TextField source="name" />
                            <TextField source="code" />
                            <TextField source="client_id" label="Client ID" />
                        </RaGrid>
                        <RaGrid item xs={6}>
                            <RaGrid item xs={12}>
                                <FunctionField
                                    label="Portal URL"
                                    render={(record) => {
                                        const url = $(
                                            'meta[name="portal-url"]',
                                        ).attr("content");
                                        const portalUrl = `${url}/?union=${record.code}`;
                                        return (
                                            <div className="flex items-center">
                                                <span className="w-full">
                                                    {portalUrl}
                                                </span>
                                                <CopyToClipboard
                                                    text={portalUrl}
                                                >
                                                    <button className="ml-2 p-1 border rounded hover:border hover:bg-gray-100">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-5 w-5"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                                                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                                                        </svg>
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        );
                                    }}
                                />
                            </RaGrid>
                            <RaGrid item xs={12}>
                                <ImageField source="logo" />
                            </RaGrid>
                            <RaGrid item xs={12}>
                                <TextField source="opt_out_url" />
                            </RaGrid>
                        </RaGrid>
                    </RaGrid>
                </GridShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};

const validateUrl = regex(
    /^https?:\/\/[^\s$.?#].[^\s]*$/,
    "The opt out url must be a valid URL.",
);

export const UnionEdit = (props) => {
    const notify = useNotify();
    const [mutate] = useMutation();
    const redirect = useRedirect();
    const { record } = useEditController(props);
    const [editorState, setEditorState] = React.useState();
    const [studentTypeItems, setStudentTypeItems] = useState([]);
    const [degreeTypeItems, setDegreeTypeItems] = useState([]);
    const [startDateItems, setStartDateItems] = useState([]);
    // const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty())
    React.useEffect(() => {
        if (record !== undefined) {
            const html = record.coverage ? record.coverage : "";
            const blocksFromHTML = convertFromHTML(html);
            const edtState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(edtState));

            if (record.student_types) {
                // student types
                var studentItems = [];
                for (let type of record.student_types) {
                    studentItems.push({ id: type.id, name: type.name_en });
                }
                setStudentTypeItems(studentItems);
            }

            if (record.degree_types) {
                // degree types
                var degreeItems = [];
                for (let type of record.degree_types) {
                    degreeItems.push({ id: type.id, name: type.name_en });
                }
                setDegreeTypeItems(degreeItems);
            }

            if (record.start_dates) {
                // start dates
                var dateItems = [];
                for (let date of record.start_dates) {
                    dateItems.push({ id: date.id, name: date.name_en });
                }
                setStartDateItems(dateItems);
            }
        }
    }, [record]);
    const [options, setOptions] = useState(null);
    const [categories, setCategories] = useState(null);
    const [descriptions, setDescriptions] = useState({});
    const translate = useTranslate();
    const setDescriptionValue = (optId, categId, description, lang) => {
        const optObj = descriptions.hasOwnProperty(optId)
            ? descriptions[optId]
            : {};
        if (!optObj.hasOwnProperty(categId)) {
            optObj[categId] = { en: "", fr: "" };
        }
        optObj[categId][lang] = description;
        descriptions[optId] = optObj;
        setDescriptions({ ...descriptions });
    };
    const getDescriptionValue = (optId, categId, lang) => {
        if (descriptions.hasOwnProperty(optId)) {
            const optObj = descriptions[optId];
            return optObj.hasOwnProperty(categId) ? optObj[categId][lang] : "";
        }
        return "";
    };
    const flexData = {
        options,
        categories,
        descriptions,
    };
    const flexRef = useRef();
    flexRef.current = flexData;
    function formatLogo(value) {
        if (!value || typeof value === "string") {
            return { src: value };
        } else {
            return value;
        }
    }
    const save = async (values) => {
        let errors = false;
        const { options, categories, descriptions } = flexData;
        let optionsCheck = false,
            categoriesCheck = false,
            withFlexData = false;

        if (options && options.length) {
            optionsCheck = true;
            for (let opt of options) {
                if (
                    !opt.value.en.length ||
                    !opt.value.fr.length ||
                    !opt.highlight.en.length ||
                    !opt.highlight.fr.length
                ) {
                    errors = true;
                    break;
                }
            }
        }

        if (categories && categories.length) {
            categoriesCheck = true;
            for (let categ of categories) {
                if (!categ.value.en.length || !categ.value.fr.length) {
                    errors = true;
                    break;
                }
            }
        }

        // check descriptions
        for (let opt in options) {
            const optionId = options[opt].id;
            for (let categ in categories) {
                const categId = categories[categ].id;
                if (
                    !descriptions.hasOwnProperty(optionId) ||
                    !descriptions[optionId].hasOwnProperty(categId) ||
                    !descriptions[optionId][categId].en.length ||
                    !descriptions[optionId][categId].fr.length
                ) {
                    errors = true;
                    break;
                }
            }
        }

        // options, categories and descriptions are mandatory
        if (
            (optionsCheck || categoriesCheck) &&
            !Object.keys(descriptions).length
        ) {
            errors = true;
        } else {
            withFlexData = true;
        }

        if (errors) {
            notify("cfs.flexplan-empty-fields", "error");
            return { _error: "Flex plan error" };
        } else {
            const coverage = draftToHtml(
                convertToRaw(editorState.getCurrentContent()),
            );
            try {
                await mutate(
                    {
                        type: "update",
                        resource: "unions",
                        payload: {
                            id: record.id,
                            data: { ...values, flexPlan: flexData, coverage },
                        },
                    },
                    { returnPromise: true },
                );

                redirect("list", props.basePath);
            } catch (error) {
                if (error.message) {
                    notify(error.message, "error");
                }
                if (error.body.errors) {
                    return error.body.errors;
                }
            }
        }
    };

    return (
        <Edit
            mutationMode="pessimistic"
            title={<UnionTitle />}
            transform={(data) => ({ ...data, flexPlan: flexRef.current })}
            {...props}
        >
            <TabbedForm
                toolbar={<CustomEditToolbar alwaysEnableSaveButton />}
                save={save}
                redirect="list"
            >
                <FormTab label={translate("cfs.infos")}>
                    <TextInput disabled source="id" />
                    <ReferenceInput
                        source="university_id"
                        reference="universities"
                        validate={required()}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput source="name" validate={required()} />
                    <TextInput source="code" validate={required()} />
                    <TextInput source="client_id" label="Client ID" />
                    <BooleanInput source="flex" />
                    <BooleanInput source="flexDocUpload" />
                    <FormDataConsumer subscription={{ values: true }}>
                        {({ formData, ...rest }) =>
                            formData.flexDocUpload && (
                                <>
                                    <div>
                                        <TextInput
                                            multiline
                                            source="doc_description_en"
                                            validate={
                                                record.flexDocUpload
                                                    ? required()
                                                    : undefined
                                            }
                                            {...rest}
                                        />
                                    </div>
                                    <div>
                                        <TextInput
                                            multiline
                                            source="doc_description_fr"
                                            validate={
                                                record.flexDocUpload
                                                    ? required()
                                                    : undefined
                                            }
                                            {...rest}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </FormDataConsumer>

                    <ImageInput
                        format={formatLogo}
                        source="logo"
                        label="Union logo"
                        accept="image/*"
                        multiple={false}
                    >
                        <ImageField source="src" title="title" />
                    </ImageInput>
                    <TextInput
                        source="opt_out_url"
                        type="url"
                        validate={validateUrl}
                        placeholder="http://"
                    />
                </FormTab>
                <FormTab label={translate("cfs.optin-period")}>
                    {/*<Grid continer>
                        <Grid container item xs={12} spacing={3}>
                            <Grid item xs={6}>
                                <DateInput fullWidth source="start" />
                            </Grid>
                            <Grid item xs={6}>
                                <DateInput fullWidth source="end" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <BooleanInput
                        label="Custom Opt-In Link"
                        source="enable_invitations"
                    />
                    <FormDataConsumer subscription={{ values: true }}>
                        {({ formData, ...rest }) => {
                            const { change } = useForm();
                            const populateInvitationValues = async (
                                planId,
                                scopeId = "invite_only_plans[0]",
                            ) => {
                                try {
                                    const plan = await Api.plans.get(planId);
                                    for (const property in plan) {
                                        switch (property) {
                                            case "valid_degree_types":
                                            case "valid_student_types":
                                            case "valid_start_dates":
                                                change(
                                                    property,
                                                    JSON.parse(plan[property]),
                                                );
                                                break;
                                            default:
                                                change(
                                                    `${scopeId}.${property}`,
                                                    plan[property],
                                                );
                                                break;
                                        }
                                    }
                                } catch (err) {
                                    console.error(err);
                                }
                            };

                            return formData.enable_invitations ? (
                                <ArrayInput
                                    source="invite_only_plans"
                                    {...rest}
                                >
                                    <SimpleFormIterator
                                        TransitionProps={{ enter: false }}
                                    >
                                        <FormDataConsumer
                                            subscription={{ values: true }}
                                        >
                                            {({
                                                formData,
                                                scopedFormData,
                                                getSource,
                                                ...rest
                                            }) => (
                                                <Grid
                                                    container
                                                    direction="column"
                                                >
                                                    <ReferenceInput
                                                        source={getSource(
                                                            "parent_plan_id",
                                                        )}
                                                        reference="plans"
                                                        filter={{
                                                            planable_id:
                                                                record.id,
                                                        }}
                                                        onChange={(evt) =>
                                                            populateInvitationValues(
                                                                evt.target
                                                                    .value,
                                                                rest.id,
                                                            )
                                                        }
                                                    >
                                                        <SelectInput
                                                            optionText="name_en"
                                                            onChange={
                                                                populateInvitationValues
                                                            }
                                                        />
                                                    </ReferenceInput>
                                                    <TextInput
                                                        source={"email"}
                                                        validate={[
                                                            required(),
                                                            email(),
                                                        ]}
                                                        type="email"
                                                        label="Email Address"
                                                        {...rest}
                                                    />
                                                    <Grid container spacing={1}>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Typography variant="subtitle2">
                                                                Plan Costs
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Grid item xs={4}>
                                                                <NumberInput
                                                                    fullWidth
                                                                    source={
                                                                        "cost"
                                                                    }
                                                                    validate={[
                                                                        required(),
                                                                        number(),
                                                                    ]}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <NumberInput
                                                                    fullWidth
                                                                    source={
                                                                        "cost_student_and_one"
                                                                    }
                                                                    validate={[
                                                                        required(),
                                                                        number(),
                                                                    ]}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <NumberInput
                                                                    fullWidth
                                                                    source={
                                                                        "cost_student_and_two_plus"
                                                                    }
                                                                    validate={[
                                                                        required(),
                                                                        number(),
                                                                    ]}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Grid item xs={4}>
                                                                <NumberInput
                                                                    fullWidth
                                                                    source={
                                                                        "cost_plus_one"
                                                                    }
                                                                    validate={[
                                                                        required(),
                                                                        number(),
                                                                    ]}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <NumberInput
                                                                    fullWidth
                                                                    source={
                                                                        "cost_two_plus"
                                                                    }
                                                                    validate={[
                                                                        required(),
                                                                        number(),
                                                                    ]}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={4}
                                                            ></Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1}>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Typography variant="subtitle2">
                                                                Name
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Grid item xs={6}>
                                                                <TextInput
                                                                    fullWidth
                                                                    source={
                                                                        "name_en"
                                                                    }
                                                                    label={translate(
                                                                        "labels.unions.name_en",
                                                                    )}
                                                                    validate={required()}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextInput
                                                                    fullWidth
                                                                    source={
                                                                        "name_fr"
                                                                    }
                                                                    label={translate(
                                                                        "labels.unions.name_fr",
                                                                    )}
                                                                    validate={required()}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <SelectInput
                                                        disabled
                                                        fullWidth
                                                        source={"plan_type"}
                                                        choices={[
                                                            {
                                                                id: "EH",
                                                                name: "Health",
                                                            },
                                                            {
                                                                id: "DE",
                                                                name: "Dental",
                                                            },
                                                            {
                                                                id: "EHDE",
                                                                name: "Health & Dental",
                                                            },
                                                        ]}
                                                        {...rest}
                                                    />
                                                    <Grid container spacing={1}>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Typography variant="subtitle1">
                                                                Description
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            spacing={3}
                                                        >
                                                            <Grid item xs={6}>
                                                                <TextInput
                                                                    fullWidth
                                                                    label={translate(
                                                                        "labels.unions.description_en",
                                                                    )}
                                                                    source={
                                                                        "description_en"
                                                                    }
                                                                    multiline
                                                                    minRows={3}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextInput
                                                                    fullWidth
                                                                    label={translate(
                                                                        "labels.unions.description_fr",
                                                                    )}
                                                                    source={
                                                                        "description_fr"
                                                                    }
                                                                    multiline
                                                                    minRows={3}
                                                                    {...rest}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <CheckboxGroupInput
                                                        source={
                                                            "valid_student_types"
                                                        }
                                                        choices={
                                                            studentTypeItems
                                                        }
                                                        {...rest}
                                                    />
                                                    <CheckboxGroupInput
                                                        source={
                                                            "valid_degree_types"
                                                        }
                                                        choices={
                                                            degreeTypeItems
                                                        }
                                                        {...rest}
                                                    />
                                                    <CheckboxGroupInput
                                                        source={
                                                            "valid_start_dates"
                                                        }
                                                        choices={startDateItems}
                                                        {...rest}
                                                    />

                                                    <PlanInvitationForm
                                        record={ record }
                                        formData={ formData }
                                        studentTypeItems={ studentTypeItems }
                                        degreeTypeItems={ degreeTypeItems }
                                        startDateItems={ startDateItems }
                                        {...rest}
                                    />
                                                </Grid>
                                            )}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            ) : null;
                        }}
                    </FormDataConsumer>*/}
                    {!!record && (
                        <OptInPeriodTab
                            record={record}
                            studentTypeItems={studentTypeItems}
                            degreeTypeItems={degreeTypeItems}
                            startDateItems={startDateItems}
                        />
                    )}
                </FormTab>
                {record && record.flex ? (
                    <FormTab label={translate("cfs.flex-plan")}>
                        <FlexPlan
                            record={record}
                            options={options}
                            setOptions={setOptions}
                            categories={categories}
                            setCategories={setCategories}
                            setDescriptionValue={setDescriptionValue}
                            getDescriptionValue={getDescriptionValue}
                            descriptions={descriptions}
                            setDescriptions={setDescriptions}
                        />
                    </FormTab>
                ) : (
                    <FormTab label={translate("cfs.plan-prices")}>
                        <ArrayInput source="plans">
                            <SimpleFormIterator
                                TransitionProps={{ enter: false }}
                            >
                                <TextInput source="id" disabled hidden />
                                <NumberInput
                                    source="cost"
                                    validate={[required(), number()]}
                                />
                                <NumberInput
                                    source="cost_student_and_one"
                                    validate={[required(), number()]}
                                />
                                <NumberInput
                                    source="cost_student_and_two_plus"
                                    validate={[required(), number()]}
                                />
                                <NumberInput
                                    source="cost_plus_one"
                                    validate={[required(), number()]}
                                />
                                <NumberInput
                                    source="cost_two_plus"
                                    validate={[required(), number()]}
                                />
                                <TextInput
                                    source="name_en"
                                    label={translate("labels.unions.name_en")}
                                    validate={required()}
                                />
                                <TextInput
                                    source="name_fr"
                                    label={translate("labels.unions.name_fr")}
                                    validate={required()}
                                />
                                <RadioButtonGroupInput
                                    source="plan_type"
                                    choices={[
                                        {
                                            id: "EH",
                                            name: "Health",
                                        },
                                        {
                                            id: "DE",
                                            name: "Dental",
                                        },
                                        {
                                            id: "EHDE",
                                            name: "Health & Dental",
                                        },
                                    ]}
                                />
                                <TextInput
                                    label={translate(
                                        "labels.unions.description_en",
                                    )}
                                    source="description_en"
                                    multiline
                                />
                                <TextInput
                                    label={translate(
                                        "labels.unions.description_fr",
                                    )}
                                    source="description_fr"
                                    multiline
                                />
                                <CheckboxGroupInput
                                    source="valid_student_types"
                                    choices={studentTypeItems}
                                />
                                <CheckboxGroupInput
                                    source="valid_degree_types"
                                    choices={degreeTypeItems}
                                />
                                <CheckboxGroupInput
                                    source="valid_start_dates"
                                    choices={startDateItems}
                                />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                )}
                <FormTab label={translate("cfs.plan-coverage")}>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="timenode-desc-wrapper"
                        editorClassName="timenode-desc-editor"
                        onEditorStateChange={setEditorState}
                        toolbar={{
                            options: ["inline", "blockType", "list", "link"],
                            inline: {
                                options: ["bold", "italic", "underline"],
                            },
                            blockType: {
                                inDropdown: true,
                                options: [
                                    "Normal",
                                    "H1",
                                    "H2",
                                    "H3",
                                    "H4",
                                    "H5",
                                    "H6",
                                    "Blockquote",
                                ],
                            },
                            list: {
                                inDropdown: false,
                                options: ["unordered", "ordered"],
                            },
                        }}
                    />
                </FormTab>
                <FormTab label={translate("cfs.student-type")}>
                    <ArrayInput source="student_types">
                        <SimpleFormIterator
                            removeButton={
                                <Button
                                    label={translate("labels.buttons.disable")}
                                />
                            }
                            TransitionProps={{ enter: false }}
                        >
                            <TextInput source="id" disabled hidden />
                            <TextInput
                                source="name_en"
                                label={translate("labels.unions.name_en")}
                                validate={required()}
                            />
                            <TextInput
                                source="name_fr"
                                label={translate("labels.unions.name_fr")}
                                validate={required()}
                            />
                            <TextInput source="billing_id" />
                            <BooleanInput
                                source="billing_disabled"
                                valueLabelTrue="1"
                                valueLabelFalse="0"
                                looseValue
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={translate("cfs.degree-type")}>
                    <ArrayInput source="degree_types">
                        <SimpleFormIterator
                            removeButton={
                                <Button
                                    label={translate("labels.buttons.disable")}
                                />
                            }
                            TransitionProps={{ enter: false }}
                        >
                            <TextInput source="id" disabled hidden />
                            <TextInput
                                source="name_en"
                                label={translate("labels.unions.name_en")}
                                validate={required()}
                            />
                            <TextInput
                                source="name_fr"
                                label={translate("labels.unions.name_fr")}
                                validate={required()}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label={translate("cfs.email-templates")}>
                    {!!record && <EmailTemplatesTab record={record} />}
                </FormTab>
                <FormTab label={translate("cfs.start-dates")}>
                    <ArrayInput source="start_dates">
                        <SimpleFormIterator
                            removeButton={
                                <Button
                                    label={translate("labels.buttons.disable")}
                                />
                            }
                            TransitionProps={{ enter: false }}
                        >
                            <TextInput source="id" disabled hidden />
                            <TextInput
                                source="name_en"
                                label={translate("labels.unions.name_en")}
                                validate={required()}
                            />
                            <TextInput
                                source="name_fr"
                                label={translate("labels.unions.name_fr")}
                                validate={required()}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export const UnionCreate = (props) => (
    <Create title="Add Union" {...props}>
        <SimpleForm toolbar={<CustomCreateToolbar />}>
            <TextInput source="name" validate={required()} />
            <TextInput source="code" validate={required()} />
            <ReferenceInput
                source="university_id"
                reference="universities"
                validate={required()}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source="flex" />
            <BooleanInput source="flexDocUpload" />
            <ImageInput
                source="logo"
                label="Union logo"
                accept="image/*"
                multiple={false}
            >
                <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput
                source="opt_out_url"
                type="url"
                validate={validateUrl}
                placeholder="http://"
            />
            <TextInput source="client_id" label="Client ID" />
        </SimpleForm>
    </Create>
);
