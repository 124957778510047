const authProvider = {
    // authentication
    login: ({ username, password }) => {
        const request = new Request('/api/token', {
            method: 'POST',
            body: JSON.stringify({ 'email': username, password }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }),
        });
        return fetch(request)
            .then(response => {
                // console.log(response)
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                const token = auth.token
                const [, , role] = token.split('|')
                sessionStorage.setItem('auth', JSON.stringify(auth))
                sessionStorage.setItem('role', role)
            })
            .catch(err => {
                console.error('Error:', err);
                throw new Error(err)
            });
    },
    checkAuth: (params) => {
        return sessionStorage.getItem('auth') ? Promise.resolve() : Promise.reject()
    },
    logout: () => {
        sessionStorage.removeItem('auth')
        sessionStorage.removeItem('role')
        return Promise.resolve()
    },
    checkError: error => Promise.resolve(),

    // getIdentity: () => Promise.resolve(),
    getPermissions: params => {
        const role = sessionStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider