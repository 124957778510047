import { useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getPersonFieldName, getT } from '../../../helpers'
import ArrayFieldErrorMessage from './ArrayFieldErrorMessage'
import Tooltip from './Tooltip'

export default function DateOfBirth({ pos }) {
    const t = getT()
    const { i18n } = useTranslation()
    const formik = useFormikContext()
    const index = Number(pos)
    const months = {
        1: {
            'en': 'January',
            'fr': 'Janvier'
        },
        2: {
            'en': 'February',
            'fr': 'Février'
        },
        3: {
            'en': 'March',
            'fr': 'Mars'
        },
        4: {
            'en': 'April',
            'fr': 'Avril'
        },
        5: {
            'en': 'May',
            'fr': 'Mai'
        },
        6: {
            'en': 'June',
            'fr': 'Juin'
        },
        7: {
            'en': 'July',
            'fr': 'Juillet'
        },
        8: {
            'en': 'August',
            'fr': 'Août'
        },
        9: {
            'en': 'September',
            'fr': 'Septembre'
        },
        10: {
            'en': 'October',
            'fr': 'Octobre'
        },
        11: {
            'en': 'November',
            'fr': 'Novembre'
        },
        12: {
            'en': 'December',
            'fr': 'Décembre'
        },
    }
    return (
        <>
            <div className="flex w-full space-x-2">
                <div className="w-1/2">
                    <div>
                        <label className="flex mb-1" htmlFor={getPersonFieldName('dobMM', pos)}>{t('forms.dob')}</label>
                        <select id={getPersonFieldName('dobMM', pos)}
                            style={{ borderColor: (formik.values.dobMM === "") ? 'rgb(209, 213, 219)' : '#181818' }}
                            className={`w-full form-ring-item ${formik.errors.dobMM ? 'border-red-500' : ''}`}
                            {...formik.getFieldProps(getPersonFieldName('dobMM', pos))}>
                            <option value="">{t('forms.selectMonth')}</option>
                            {Object.keys(months).map(code => (
                                <option value={code} key={code}>{months[code][i18n.language]}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        {formik.touched.dobMM && formik.errors.dobMM
                            ? (<div className='form-error'>{formik.errors.dobMM}</div>)
                            : null
                        }
                        {formik.touched.persons && formik.errors.persons && formik.errors.persons[index]
                            && formik.errors.persons[index].dobMM
                            ? <ArrayFieldErrorMessage name={`persons[${index}].dobMM`} />
                            : null}
                    </div>
                </div>
                <div className="w-1/4">
                    <div>
                        <label className="flex mb-1" htmlFor={getPersonFieldName('dobDD', pos)}>{t('forms.dobDD')}</label>
                        <input
                            id={getPersonFieldName('dobDD', pos)}
                            type="text"
                            className={`w-full form-ring-item ${formik.errors.dobDD ? 'border-red-500' : ''}`}
                            placeholder={t('forms.dobDDDesc')}
                            {...formik.getFieldProps(getPersonFieldName('dobDD', pos))}
                            maxLength={2}
                        />
                    </div>
                    <div>
                        {formik.errors.dobDD
                            ? (<div className='form-error'>{formik.errors.dobDD}</div>)
                            : null
                        }
                        {formik.touched.persons && formik.errors.persons && formik.errors.persons[index]
                            && formik.errors.persons[index].dobDD
                            ? <ArrayFieldErrorMessage name={`persons[${index}].dobDD`} />
                            : null}
                    </div>
                </div>
                <div className="w-1/4">
                    <div>
                        <div className="flex mb-1">
                            <label className="flex" htmlFor={getPersonFieldName('dobYY', pos)}>{t('forms.dobYY')}</label>
                            <Tooltip label={t('helpers.dateOfBirth')} />
                        </div>
                        <input
                            id={getPersonFieldName('dobYY', pos)}
                            type="text"
                            className={`w-full form-ring-item ${formik.errors.dobYY ? 'border-red-500' : ''}`}
                            placeholder={t('forms.dobYYDesc')}
                            {...formik.getFieldProps(getPersonFieldName('dobYY', pos))}
                            maxLength={4}
                        />
                    </div>
                    <div>
                        {formik.errors.dobYY
                            ? (<div className='form-error'>{formik.errors.dobYY}</div>)
                            : null
                        }
                        {formik.touched.persons && formik.errors.persons && formik.errors.persons[index]
                            && formik.errors.persons[index].dobYY
                            ? <ArrayFieldErrorMessage name={`persons[${index}].dobYY`} />
                            : null}
                    </div>
                </div>
            </div>
            {formik.touched.persons && formik.errors.persons && formik.errors.persons[index]
                && (formik.errors.persons[index].dobMM || formik.errors.persons[index].dobDD || formik.errors.persons[index].dobYY)
                ? <ArrayFieldErrorMessage name={`persons[${index}].dobMM`} />
                : null}
        </>
    )
}
