import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import PropTypes from "prop-types";
import {TextInput} from 'react-admin';
import {useTranslate} from 'ra-core';

// interface SubmissionApprovalDialogProps {
//     updating: boolean,
//     open: boolean,
//     onClose: () => void,
//     onSubmit: () => void,
//     record: Submission
// }
function SubmissionApprovalDialog({ updating, open, onClose, onSubmit, record }) {
    const t = useTranslate();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="submission-approval-dialog-title"
        >
            <DialogTitle id="submission-approval-dialog-title">{t("cfs.submission-approval-confirmation")}</DialogTitle>
            <DialogContent>
                <TextInput
                    autoFocus
                    multiline
                    fullWidth
                    resettable
                    placeholder="Approval Notes"
                    source="approval_notes"
                    disabled={
                        !!(record && record.reviewed_at)
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button type="submit" onClick={onSubmit} color="secondary" disabled={updating}>
                    Approve
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SubmissionApprovalDialog.propTypes = {
    updating: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired
}

export default SubmissionApprovalDialog
