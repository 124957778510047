import React from 'react'
import { dateFormat, getRelationList, getT, i18nUri, makeDate } from '../../../helpers'
import SummaryBlock from '../comps/SummaryBlock'

const PersonEntry = ({ person, i18n }) => {
    const t = getT()
    const relation = getRelationList()[person.relation][i18n.language]
    const data = [
        // {
        //     label: t('forms.relationship'),
        //     value: relations[person.relation][i18n.language],
        // },
        {
            label: t('forms.firstName'),
            value: person.firstName,
        },
        {
            label: t('forms.lastName'),
            value: person.lastName,
        },
        {
            label: t('forms.dob'),
            value: dateFormat(makeDate(person.dobMM, person.dobDD, person.dobYY), i18n.language),
        },
        {
            label: t('forms.sex'),
            value: person.sex,
        }
    ]
    return (
        <SummaryBlock header={relation} editUrl={person.editUrl}>
            {() => {
                return (
                    <ul className="space-y-1">
                        {data.map((item, index) => (
                            <li className="flex" key={index}>
                                <span className="">{item.label}:&nbsp;</span>
                                <span className="font-semibold">{item.value}</span>
                            </li>
                        ))}
                    </ul>
                )
            }}
        </SummaryBlock>
    )
}

export default function PersonsSummary({ state, i18n }) {
    const persons = []
    if (state.person) {
        const editUrl = i18nUri('/form/single-person')
        persons.push({ ...state.person, editUrl })
    }
    if (state.extras && state.extras.hasOwnProperty('persons')) {
        const editUrl = i18nUri('/form/extra-persons')
        for (const p of state.extras.persons) {
            persons.push({ ...p, editUrl })
        }
    }
    return (
        <>
            {persons.map((person, index) => (
                <PersonEntry key={index} person={person} i18n={i18n} />
            ))}
        </>
    )
}
