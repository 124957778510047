import React from 'react'
import ReactDOM from 'react-dom'
import AppContext from "../appContext"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom"
import "../i18n"
import SchoolFormPage from './wizard/schoolFormPage'
import OptionsFormPage from './wizard/optionsFormPage'
import PlansFormPage from './wizard/plansFormPage'
import WelcomePage from './WelcomePage'
import StudentFormPage from './wizard/studentFormPage'
import SinglePersonFormPage from './wizard/singlePersonFormPage'
import ExtraPersonsFormPage from './wizard/extraPersonsFormPage'
import ReviewPage from './wizard/reviewPage'
import PaymentFormPage from './wizard/paymentFormPage'
import ConfirmationPage from './wizard/confirmationPage'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PlansFlexPage from './wizard/PlansFlexPage'
import StudentFlexPage from './wizard/StudentFlexPage'
import InstitutionPage from './wizard/InstitutionPage'
import DocumentsFlexPage from './wizard/DocumentsFlexPage'
import ReviewFlexPage from './wizard/ReviewFlexPage'
import ConfirmationFlexPage from './wizard/ConfirmationFlexPage'
import ScrollToTop from './wizard/comps/ScrollToTop'
import DocumentsFormPage from './wizard/DocumentsFormPage'

const stripePromise = loadStripe(process.env.MIX_STRIPE_KEY)

function App() {
    const stateHook = React.useState({})
    return (
        <AppContext.Provider value={stateHook}>
            <Router>
                <ScrollToTop>
                    <Switch>
                        {/* <Redirect from="/:lang?/form" exact to="/:lang?/form/school" />
                    <Redirect from="/:lang?/flex" exact to="/:lang?/flex/plans" /> */}

                        <Route path="/:lang?/institution">
                            <InstitutionPage />
                        </Route>

                        <Route path="/:lang?/form/school">
                            <SchoolFormPage />
                        </Route>
                        <Route path="/:lang?/form/options">
                            <OptionsFormPage />
                        </Route>
                        <Route path="/:lang?/form/plans">
                            <PlansFormPage />
                        </Route>
                        <Route path="/:lang?/form/student">
                            <StudentFormPage />
                        </Route>
                        <Route path="/:lang?/form/single-person">
                            <SinglePersonFormPage />
                        </Route>
                        <Route path="/:lang?/form/extra-persons">
                            <ExtraPersonsFormPage />
                        </Route>
                        <Route path="/:lang?/form/review">
                            <ReviewPage />
                        </Route>
                        <Route path="/:lang?/form/payment">
                            <Elements stripe={stripePromise}>
                                <PaymentFormPage />
                            </Elements>
                        </Route>
                        <Route path="/:lang?/form/confirmation">
                            <ConfirmationPage />
                        </Route>

                        <Route path="/:lang?/flex/plans/:code?">
                            <PlansFlexPage />
                        </Route>
                        <Route path="/:lang?/flex/student">
                            <StudentFlexPage />
                        </Route>
                        <Route path="/:lang?/flex/documents">
                            <DocumentsFlexPage />
                        </Route>
                        <Route path="/:lang?/form/documents">
                            <DocumentsFormPage />
                        </Route>
                        <Route path="/:lang?/flex/review">
                            <ReviewFlexPage />
                        </Route>
                        <Route path="/:lang?/flex/confirmation">
                            <ConfirmationFlexPage />
                        </Route>

                        <Route path="/:lang?">
                            <WelcomePage />
                        </Route>
                    </Switch>
                </ScrollToTop>
            </Router>
        </AppContext.Provider>
    )
}

export default App;

if (document.getElementById('app')) {
    ReactDOM.render(<App />, document.getElementById('app'));
}
