import React from "react"
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TabbedForm,
    FormTab,
    TextInput,
    number,
} from 'react-admin'
import { useTranslate } from 'ra-core'
import { CustomCreateToolbar, CustomEditToolbar } from "./CustomToolbars"

export const UniversityList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="code" />
            <EditButton />
            {/* <ActionBtn /> */}
        </Datagrid>
    </List>
)

const validatePrice = [number()]

const UniversityTitle = ({ record }) => {
    return <span>{record ? `"${record.name}"` : ''}</span>;
};

export const UniversityEdit = props => {
    const translate = useTranslate()
    return (
        <Edit undoable={false} title={<UniversityTitle />} {...props}>
            <TabbedForm toolbar={<CustomEditToolbar alwaysEnableSaveButton />}>
                <FormTab label={translate('cfs.infos')}>
                    <TextInput disabled source="id" />
                    <TextInput source="code" />
                    <TextInput source="name" />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

export const UniversityCreate = props => (
    <Create title="Add University" {...props}>
        <SimpleForm toolbar={<CustomCreateToolbar />}>
            <TextInput source="name" />
            <TextInput source="code" />
        </SimpleForm>
    </Create>
);