import {
    ArrayInput,
    BooleanInput,
    Button,
    FormDataConsumer,
    SimpleFormIterator,
    TextInput,
    TranslatableInputs,
    useNotify,
} from "react-admin";
import React, { useEffect } from "react";
import { useTranslate, use } from "ra-core";
import RichTextInput from "ra-input-rich-text";
import {
    defaultMailTemplates,
    getTemplateNameByMailable,
} from "../../../../helpers/unions";
import PropTypes from "prop-types";
import { Chip, Grid } from "@material-ui/core";
import { useForm, useFormState } from "react-final-form";

function EmailTemplatesTab({ record }) {
    const { change } = useForm();
    const { values } = useFormState();
    const translate = useTranslate();
    const notify = useNotify();

    const useVariable = (variable, id) => {
        // const idNum = id.match(/\[(\d)]/)[1];
        // console.log(values.mail_templates[idNum].html_template);
        navigator.clipboard.writeText(`{{${variable}}}`).then(
            () => {
                notify(`${variable} variable copied to clipboard.`, "info");
            },
            () => {
                notify(
                    `${variable} variable was not copied to clipboard.`,
                    "warning",
                );
            },
        );
        // values.mail_templates[idNum].html_template += `{{${variable}}}`
    };

    useEffect(() => {
        let templates = defaultMailTemplates.map((mt1) => {
            const found = record.mail_templates.find((mt2) =>
                mt2.mailable.includes(mt1.mailable),
            );
            return found ? { ...mt1, ...found, union_id: record.id } : mt1;
        });
        change("mail_templates", templates);
    }, []);

    return (
        <ArrayInput source="mail_templates">
            <SimpleFormIterator
                disableAdd
                disableReordering
                disableRemove
                removeButton={
                    <Button label={translate("labels.buttons.disable")} />
                }
                TransitionProps={{ enter: false }}
            >
                <FormDataConsumer>
                    {({ scopedFormData, id, getSource, ...rest }) =>
                        scopedFormData ? (
                            <h5
                                className="mt-4"
                                id={id + "_" + getSource("mailable")}
                            >
                                {getTemplateNameByMailable(
                                    scopedFormData.mailable,
                                )}
                            </h5>
                        ) : null
                    }
                </FormDataConsumer>

                <BooleanInput label="Custom Email" source="enabled" />

                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, scopedFormData, getSource, ...rest }) =>
                        scopedFormData?.enabled ? (
                            <Grid item xs={10}>
                                <TranslatableInputs locales={["en", "fr"]}>
                                    <TextInput
                                        {...rest}
                                        className="!w-full"
                                        required
                                        source={getSource("subject")}
                                        label="Subject"
                                        fullWidth
                                    />
                                </TranslatableInputs>
                            </Grid>
                        ) : null
                    }
                </FormDataConsumer>

                <FormDataConsumer subscription={{ values: true }}>
                    {({ scopedFormData, id, getSource, ...rest }) => {
                        // debugger;
                        return scopedFormData?.enabled ? (
                            <Grid item xs={12} className="flex mt-4">
                                <p
                                    className="mr-4"
                                    id={id + "_" + getSource("variables")}
                                >
                                    Variables:
                                </p>
                                {scopedFormData.variables.length > 0
                                    ? scopedFormData.variables.map((v) => (
                                          <Chip
                                              className="mr-4"
                                              key={v}
                                              label={v}
                                              size="small"
                                              onClick={() => useVariable(v, id)}
                                          />
                                      ))
                                    : "None"}
                            </Grid>
                        ) : null;
                    }}
                </FormDataConsumer>

                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, scopedFormData, getSource, ...rest }) =>
                        scopedFormData?.enabled ? (
                            <Grid item xs={10}>
                                <TranslatableInputs locales={["en", "fr"]}>
                                    <RichTextInput
                                        {...rest}
                                        source={getSource("html_template")}
                                        label=""
                                    />
                                </TranslatableInputs>
                            </Grid>
                        ) : null
                    }
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
    );
}

EmailTemplatesTab.propTypes = {
    record: PropTypes.object.isRequired,
};

export default EmailTemplatesTab;
