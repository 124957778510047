import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import SubmitBtn from "../SubmitBtn";
import {
    determineLangFromUri,
    determinePlanCost,
    getPlanCostField,
    getUnionPlans,
    optInInfo,
} from "../../helpers";
import Drawer from "./comps/Drawer";
import Tooltip from "./comps/Tooltip";
import TogglePanel from "./comps/TogglePanel";

const PlansFormPage = ({
    t,
    history,
    state,
    setState,
    prevNext: { prev, next },
}) => {
    const [drawerIsOpened, setDrawerIsOpened] = useState(false);
    const [content, setContent] = useState(null);
    const showCriteriaPanel = (e, description, name) => {
        e.preventDefault();

        const cttx = `<div class="">
            <div class="px-6">
                <div class="text-2xl font-bold mt-2">${name}</div>
            </div>
            <div class="p-6">${description}</div>
        </div>`;

        setContent(cttx);
        setDrawerIsOpened(true);
    };

    const filterAvailablePlans = () => {
        let plans = getUnionPlans();
        if (plans === undefined) return [];

        // filter against the selected
        let filtered = plans.filter((plan) => {
            if (
                plan.valid_student_types == null ||
                !plan.valid_student_types.includes(
                    parseInt(state.school.studentType),
                )
            )
                return false;
            else if (
                plan.valid_degree_types == null ||
                !plan.valid_degree_types.includes(
                    parseInt(state.school.degreeType),
                )
            )
                return false;
            else if (
                plan.valid_start_dates == null ||
                !plan.valid_start_dates.includes(
                    parseInt(state.school.academicYearStartDate),
                )
            )
                return false;
            else return true;
        });
        return filtered;
    };

    const plans = filterAvailablePlans();
    const formik = useFormik({
        initialValues: state["plans"]
            ? state["plans"]
            : // { "options": "", "plans": "health-dental" },
              {
                  options: "",
                  plan: "",
              },
        validationSchema: Yup.object().shape({
            plan: Yup.string().required(t("errors.plan-option-required")),
        }),
        onSubmit: (values) => {
            const plan = plans.find((p) => p.id === Number(values.plan));
            values.options = chosenOptions(state);
            values.cost = determinePlanCost(state.options, plan);
            values.planCostField = getPlanCostField(state.options);
            const data = { plans: values };
            setState({ ...state, ...data });
            history.push(next);
        },
    });
    const chosenOptions = (state) => {
        if (!state.options) return undefined;
        const { options, additional } = state.options;
        return `${options}-${additional}`;
    };
    const planName = (plan) => {
        const lang = determineLangFromUri();
        const field = `name_${lang}`;
        return plan[field];
    };
    const planDescription = (plan) => {
        const lang = determineLangFromUri();
        const field = `description_${lang}`;
        return plan[field];
    };

    useEffect(() => {
        if (!state["options"]) {
            history.push(prev);
        }
    }, []);

    const [planPanelVisibility, setPlanPanelVisibility] = useState({});
    const togglePlanPanel = (e, planId) => {
        e.preventDefault();
        if (planPanelVisibility[planId]) {
            const newState = { ...planPanelVisibility };
            newState[planId] = false;
            setPlanPanelVisibility(newState);
        } else {
            const newState = { ...planPanelVisibility };
            newState[planId] = true;
            setPlanPanelVisibility(newState);
        }
    };

    const planSelector = () => {
        if (plans.length == 0) {
            return <div className="form-error">{t("no-valid-plans")}</div>;
        }
        return (
            <ul className="space-y-4 mt-2">
                {plans &&
                    Object.keys(plans).map((id) => (
                        <li
                            key={id}
                            className={
                                formik.values.plan == plans[id].id
                                    ? "form-item-selected"
                                    : "form-item"
                            }
                        >
                            <label className="flex justify-between">
                                <div className="flex w-full">
                                    <input
                                        name="plan"
                                        type="radio"
                                        value={plans[id].id}
                                        checked={
                                            formik.values.plan == plans[id].id
                                        }
                                        onChange={formik.handleChange}
                                        className="mt-1 text-dark-green"
                                    />
                                    <div className="w-full">
                                        <span className="ml-2">
                                            {planName(plans[id])}
                                        </span>
                                        <br />
                                        <a
                                            onClick={(e) =>
                                                showCriteriaPanel(
                                                    e,
                                                    planDescription(plans[id]),
                                                    planName(plans[id]),
                                                )
                                            }
                                            href="#"
                                            className="underline text-light-green text-sm sm:hidden"
                                        >
                                            {t("plan-details")}
                                        </a>
                                        <div className="hidden sm:block float-right">
                                            <a
                                                onClick={(e) =>
                                                    togglePlanPanel(
                                                        e,
                                                        plans[id].id,
                                                    )
                                                }
                                                href="#"
                                                className="flex underline text-light-green text-sm"
                                            >
                                                {t("learn-more")}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 border border-dark-green rounded-full ml-2 "
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <span className="font-bold">
                                    $
                                    {determinePlanCost(
                                        state.options,
                                        plans[id],
                                    )}
                                </span>
                            </label>
                            <TogglePanel
                                visible={planPanelVisibility[plans[id].id]}
                                content={planDescription(plans[id])}
                            />
                        </li>
                    ))}
            </ul>
        );
    };

    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t("plan-options-title")}</h1>
                <p className="text-sm text-gray-600 mt-2">
                    {t("plan-options-desc")}
                </p>

                <form onSubmit={formik.handleSubmit} className="mt-4">
                    <div className="form-item-selected">
                        <div>
                            <Tooltip label={t("helpers.selected-option")} />
                        </div>
                        <label className="flex" htmlFor="options">
                            <input
                                id="options"
                                name="options"
                                type="radio"
                                value={chosenOptions(state)}
                                checked
                                disabled
                                className="mt-1 text-dark-green"
                            />
                            <span className="ml-2">{optInInfo(state)}</span>
                        </label>
                    </div>

                    <div className="mt-4">
                        <p>{t("select-option-text")}</p>
                        {planSelector()}
                        {formik.touched.plan && formik.errors.plan ? (
                            <div className="form-error">
                                {formik.errors.plan}
                            </div>
                        ) : null}
                    </div>

                    <div className="mt-4">
                        <SubmitBtn />
                    </div>
                </form>

                <Drawer
                    isOpen={drawerIsOpened}
                    setIsOpen={setDrawerIsOpened}
                    content={content}
                />
            </div>
        </Layout>
    );
};

export default withHelpers(PlansFormPage);
