import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    fetchUnionLogo,
    findUnionObject,
    findUniversityOfUnion,
    i18nUri,
    isInvitation,
    useQuery,
} from "../helpers";
import withHelpers from "../hooks/withHelpers";
import Layout from "./Layout";
import Loading from "./wizard/comps/Loading";
import AppContext from "../appContext";

const WelcomePage = ({ t, history, state, setState }) => {
    const query = useQuery();
    const [loading, setLoading] = useState(true);
    const unionCode = query.get("union") || undefined;
    const institutionUri = i18nUri("/institution");
    const optinUriPrefix = i18nUri("/form/school?union=");
    const flexUriPrefix = i18nUri("/flex/plans?union=");
    const [union, setUnion] = useState();
    const [code, setCode] = useState();
    const [optinPossible, setOptinPossible] = useState(false);

    useEffect(() => {
        const logo = async (code) => {
            const path = await fetchUnionLogo(code);
            setState((previous) => ({ ...previous, unionLogoPath: path }));
        };

        if (!unionCode && state && !state.selectedUnion) {
            history.push(institutionUri);
        } else {
            const code = unionCode || state.selectedUnion;
            setCode(code);
            logo(code);
        }

        if (state.universities && code) {
            const unionObj = findUnionObject(code, state.universities);
            if (unionObj) {
                setUnion(unionObj);
                setLoading(false);
                const { start, end } = unionObj;
                const nowDt = new Date(),
                    startDt = new Date(start),
                    endDt = new Date(end);
                if (
                    (nowDt.getTime() > startDt.getTime() &&
                        endDt.getTime() > nowDt.getTime()) ||
                    (isInvitation &&
                        new Date(
                            Number(query.get("expires") * 1000),
                        ).getTime() > nowDt.getTime())
                )
                    setOptinPossible(true);
            } else {
                history.push("/institution");
            }
        }
    }, [code, state.universities]);

    const checkIfOptinIsPossible = (e, isFlexPlan) => {
        if (!optinPossible) {
            e.preventDefault();
            alert(
                t(
                    isFlexPlan
                        ? "flexplan-period-closed"
                        : "optin-period-closed",
                ),
            );
        }
    };
    return (
        <Layout canSwitchInstitution={true} bgColor="#fff">
            <div className="mt-10 mb-4 mx-6">
                <h1 className="mb-4">{t("welcome-headline")}</h1>
                <p>{t("welcome-description")}</p>

                <div className="">
                    {/*<h3 className="my-4 py-2 font-bold text-md">
                        {t("welcome-manage-benefits")}
                    </h3>*/}
                    {loading ? (
                        <Loading />
                    ) : (
                        <ul className="flex space-x-4">
                            <li
                                className={`w-1/2 flex flex-col ${
                                    optinPossible
                                        ? "bg-light-blue"
                                        : "bg-gray-200"
                                } justify-center items-center border ${
                                    optinPossible
                                        ? "border-light-blue"
                                        : "border-black"
                                } rounded py-4 shadow text-center hover:border-stroke-blue focus:border-stroke-blue active:border-stroke-blue`}
                            >
                                <Link
                                    to={`${optinUriPrefix}${state.selectedUnion}`}
                                    onClick={(e) => checkIfOptinIsPossible(e)}
                                    className="w-full flex flex-col justify-center"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="72"
                                        height="67"
                                        viewBox="0 0 72 67"
                                        className="w-20 h-20 mx-auto mb-4"
                                    >
                                        <g
                                            fill="none"
                                            fillRule="evenodd"
                                            stroke="none"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                        >
                                            <g
                                                fill="#0275A7"
                                                stroke="#0275A7"
                                                strokeWidth="2"
                                                transform="translate(-69 -369)"
                                            >
                                                <g transform="translate(70 370)">
                                                    <path d="M27.042 53.133l-4.308-2.53s-2.273 2.207-5.683 2.207c-3.41 0-5.684-2.208-5.684-2.208l-1.98.481C5.057 52.134 1.76 55.55.961 59.815L0 64.94h17.05c.849-2.891 1.87-5.095 3.064-6.611 1.193-1.517 3.503-3.249 6.928-5.196z"></path>
                                                    <path d="M20.255 46.386c3.147-2.049 2.855-3.364 3.23-6.344.201-1.608.263-3.341-.532-4.817-.728-1.352-2.064-2.288-3.524-2.77a8.253 8.253 0 00-5.123 0c-1.46.482-2.796 1.418-3.524 2.77-.795 1.476-.733 3.209-.531 4.817.374 2.98.082 4.295 3.228 6.344 3.147 2.048 3.63 2.048 6.776 0z"></path>
                                                    <path d="M45.542 55.072l3.335-1.096s2.224 2.193 5.559 2.193 5.559-2.193 5.559-2.193l1.608.453c3.82 1.076 6.767 4.082 7.73 7.883L70 64.94H53.976c-.771-2.458-1.668-4.285-2.691-5.482s-2.937-2.659-5.743-4.386z"></path>
                                                    <path d="M50.588 49.759c-3.146-2.048-2.855-3.364-3.229-6.344-.202-1.608-.263-3.34.531-4.817.728-1.352 2.064-2.287 3.525-2.77a8.253 8.253 0 015.122 0c1.46.483 2.796 1.418 3.524 2.77.795 1.476.734 3.209.532 4.817-.374 2.98-.083 4.296-3.23 6.344-3.145 2.048-3.63 2.048-6.775 0z"></path>
                                                    <path d="M23.166 62.528c.996-1.937 2.715-3.453 4.825-4.254l2.437-.925s1.77 1.085 4.572 1.085c2.801 0 4.572-1.085 4.572-1.085l2.438.925c2.109.8 3.828 2.317 4.824 4.254l1.238 2.412H21.928l1.238-2.412z"></path>
                                                    <path d="M32.247 53.133c-2.556-1.687-2.32-2.77-2.623-5.225-.164-1.324-.214-2.75.431-3.967.592-1.113 1.677-1.884 2.864-2.28a6.623 6.623 0 014.162 0c1.187.396 2.272 1.167 2.864 2.28.645 1.216.595 2.643.431 3.967-.304 2.455-.067 3.538-2.623 5.225-2.556 1.686-2.95 1.686-5.506 0z"></path>
                                                    <path d="M49.7590361 16.0240964L49.7590361 20.1205229 39.8184211 20.1205229 39.8184211 30.3614458 29.8780847 30.3614458 29.8780847 20.1205229 21.9277108 20.1205229"></path>
                                                    <path d="M21.274475 19.4286278L49.7590361 16.0240964 50.3951969 10.2409228 40.4818331 10.2409228 40.4818331 0 30.3615404 0 30.3615404 10.2409228 21.274475 10.2409228 21.274475 14.442958z"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span className="text-sm">
                                        {t("optin")}
                                    </span>
                                </Link>
                            </li>
                            {union && union.flex ? (
                                <li
                                    className={`w-1/2 flex flex-col ${
                                        optinPossible
                                            ? "bg-light-blue"
                                            : "bg-gray-200"
                                    } justify-center items-center border ${
                                        optinPossible
                                            ? "border-light-blue"
                                            : "border-black"
                                    } rounded py-4 shadow text-center hover:border-stroke-green focus:border-stroke-green active:border-stroke-green`}
                                    style={{ backgroundColor: "#EEF7EF" }}
                                >
                                    <Link
                                        to={`${flexUriPrefix}${state.selectedUnion}`}
                                        onClick={(e) =>
                                            checkIfOptinIsPossible(e, true)
                                        }
                                        className="w-full flex flex-col justify-center"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="73"
                                            height="70"
                                            viewBox="0 0 73 70"
                                            className="w-20 h-20 mx-auto mb-4"
                                        >
                                            <g
                                                fill="none"
                                                fillRule="evenodd"
                                                stroke="none"
                                                strokeWidth="1"
                                            >
                                                <g
                                                    fill="#81BC00"
                                                    stroke="#81BC00"
                                                    transform="translate(-239 -369)"
                                                >
                                                    <g transform="translate(240 370)">
                                                        <path d="M11.285307 46.7894768L46 48 27 57.7617369 18 62 9 49.1251915z"></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M0 49.4668562L6.74683505 46 18 64.5331438 12.3784814 68"
                                                        ></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M18.564 48.14L28 44.622l6.5-.287c2.522 0 10.461 0 10.461-2.37 0-2.369-4.94-2.965-7.461-2.965H25.654c-1.817 0-3.6.474-5.171 1.373L8 47.523l10.564.617z"
                                                        ></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M45.087 47.727l19.568-8.384c2.333-.954 5.031.15 5.997 2.454.967 2.303-.151 4.968-2.484 5.922L47.89 57.336a11.604 11.604 0 01-4.477.898H24.735c-.818 0-1.622.216-2.327.626L17 62"
                                                        ></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M48 19L48 21.857309 40.8567899 21.857309 40.8567899 29 33.7132891 29 33.7132891 21.857309 28 21.857309"
                                                        ></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M27 18.4881329L27 21.857309 29 21.857309 48 19 48 15.142691 41.000095 15.142691 41.000095 8 33.999905 8 33.999905 15.142691 27 15.142691 27 18"
                                                        ></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M58.666 13.055C55.188 9.933 53 7.583 53 4.795 53 2.11 55.178 0 57.95 0c1.566 0 3.069.706 4.05 1.822A5.463 5.463 0 0166.05 0C68.822 0 71 2.11 71 4.796c0 3.296-3.06 5.981-7.695 10.062L62 16l-3.334-2.945z"
                                                        ></path>
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M6.133 9.925S5.971 9.1 5.128 8.17c-2.387-2.634-1.13-9.406 5.15-7.901 0 0 2.01.878 4.272 0 2.46-.956 4.899.878 5.527 1.881 0 0 2.638 3.386-1.005 6.898 0 0-1.423 1.658-1.504 6.353-.005.307-.12.604-.33.829-.481.512-1.45 1.228-2.61.376a1.336 1.336 0 01-.525-.854c-.195-1.149-.8-4.099-1.814-3.694 0 0-1.16-.463-1.697 3.73a1.2 1.2 0 01-.627.908c-.461.245-1.128.461-1.704.116a1.316 1.316 0 01-.577-.798L6.76 12.56l-.628-2.634z"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="">
                                            {t("flexplan")}
                                        </span>
                                    </Link>
                                </li>
                            ) : null}
                            {union && union.opt_out_url && (
                                <li
                                    className="w-1/2 flex flex-col bg-light-blue justify-center items-center border border-light-blue rounded py-4 shadow text-center hover:border-stroke-yellow focus:border-stroke-yellow active:border-stroke-yellow"
                                    style={{ backgroundColor: "#F9F0D9" }}
                                >
                                    <a
                                        href={union.opt_out_url}
                                        target="_blank"
                                        className="w-full flex flex-col justify-center"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="72"
                                            height="69"
                                            viewBox="0 0 72 69"
                                            className="w-20 h-20 mx-auto mb-4"
                                        >
                                            <g
                                                fill="none"
                                                fillRule="evenodd"
                                                stroke="none"
                                                strokeWidth="1"
                                            >
                                                <g
                                                    fill="#E4B949"
                                                    transform="translate(-69 -536)"
                                                >
                                                    <g transform="translate(70 536.886)">
                                                        <path
                                                            stroke="#E4B949"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M27.042 55.199l-4.308-2.53s-2.273 2.207-5.683 2.207c-3.41 0-5.684-2.207-5.684-2.207l-1.98.48c-4.33 1.052-7.627 4.467-8.426 8.732L0 67.006h17.05c.849-2.891 1.87-5.095 3.064-6.611 1.193-1.516 3.503-3.248 6.928-5.196z"
                                                        ></path>
                                                        <path
                                                            stroke="#E4B949"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M20.255 48.452c3.147-2.049 2.855-3.364 3.23-6.344.201-1.608.263-3.34-.532-4.817-.728-1.352-2.064-2.288-3.524-2.77a8.253 8.253 0 00-5.123 0c-1.46.482-2.796 1.418-3.524 2.77-.795 1.476-.733 3.209-.531 4.817.374 2.98.082 4.295 3.228 6.344 3.147 2.048 3.63 2.048 6.776 0z"
                                                        ></path>
                                                        <path
                                                            stroke="#E4B949"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M45.542 57.139l3.335-1.097s2.224 2.193 5.559 2.193 5.559-2.193 5.559-2.193l1.608.453c3.82 1.077 6.767 4.082 7.73 7.883L70 67.006H53.976c-.771-2.458-1.668-4.285-2.691-5.482s-2.937-2.659-5.743-4.385z"
                                                        ></path>
                                                        <path
                                                            stroke="#E4B949"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M50.588 51.825c-3.146-2.048-2.855-3.364-3.229-6.343-.202-1.609-.263-3.341.531-4.818.728-1.351 2.064-2.287 3.525-2.769a8.253 8.253 0 015.122 0c1.46.482 2.796 1.418 3.524 2.77.795 1.476.734 3.208.532 4.817-.374 2.98-.083 4.295-3.23 6.343-3.145 2.049-3.63 2.049-6.775 0z"
                                                        ></path>
                                                        <path
                                                            stroke="#E4B949"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M23.166 64.594c.996-1.937 2.715-3.453 4.825-4.253l2.437-.925S32.198 60.5 35 60.5c2.801 0 4.572-1.084 4.572-1.084l2.438.925c2.109.8 3.828 2.316 4.824 4.253l1.238 2.412H21.928l1.238-2.412z"
                                                        ></path>
                                                        <path
                                                            stroke="#E4B949"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M32.247 55.199c-2.556-1.687-2.32-2.77-2.623-5.224-.164-1.325-.214-2.752.431-3.967.592-1.114 1.677-1.884 2.864-2.281a6.623 6.623 0 014.162 0c1.187.397 2.272 1.167 2.864 2.28.645 1.216.595 2.643.431 3.968-.304 2.454-.067 3.537-2.623 5.224-2.556 1.686-2.95 1.686-5.506 0z"
                                                        ></path>
                                                        <path
                                                            fillRule="nonzero"
                                                            d="M51.0775143 0.846091284L34.4036661 17.9868906 18.7093487 0.281102852 34.5632488 0.557833708z"
                                                            transform="rotate(179 34.893 9.134)"
                                                        ></path>
                                                        <path
                                                            fillRule="nonzero"
                                                            d="M44.7706853 16.8222304L44.7706853 27.8222304 24.7706853 27.8222304 24.7706853 16.8222304z"
                                                            transform="rotate(-91 34.77 22.322)"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="">{t("optout")}</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default withHelpers(WelcomePage);
