import React from 'react'
import { useHistory } from 'react-router'
import { Button, useTranslate, Toolbar, SaveButton, DeleteButton } from 'react-admin'

const CancelButton = props => {
    const t = useTranslate()
    const history = useHistory()
    const { basePath } = props
    return (
        <Button {...props}
            label={t('cfs.cancel')}
            color="secondary"
            onClick={() => history.push(basePath)}
        />
    )
}

const CustomCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <CancelButton />
    </Toolbar>
)

const CustomEditToolbar = props => {
    return (
        <Toolbar {...props}>
            <SaveButton />
            <CancelButton />
            <DeleteButton undoable={false} />
        </Toolbar>
    )
}

export {
    CustomCreateToolbar,
    CustomEditToolbar
}