import React from 'react'
import withHelpers from '../../hooks/withHelpers'
import Layout from '../Layout'
import DocumentsSummary from './comps/DocumentsSummary'
import FlexPlanSummary from './comps/FlexPlanSummary'
import PersonalInformationSummary from './comps/PersonalInformationSummary'

const ReviewFlexPage = ({ t, history, state, i18n, setState, prevNext: { prev, next } }) => {
    const [loaded, setLoaded] = React.useState(false)
    React.useEffect(() => {
        if (!state['student']) {
            history.push(prev)
        }
        setLoaded(true)
    }, [])
    const submitFlexPlan = () => {
        setState({ ...state, reviewed: true })
        history.push(next)
    }
    return (
        <Layout>
            <div className="my-4 mx-6">
                <h1 className="">{t('summary-title')}</h1>
                <p className="text-sm text-gray-600 mt-2">{t('summary-desc')}</p>
            </div>
            <div className="space-y-10">
                {loaded && <FlexPlanSummary state={state} i18n={i18n} />}
                {loaded && <PersonalInformationSummary state={state} i18n={i18n} flex={true} />}
                {loaded && state.hasOwnProperty('docs') && <DocumentsSummary state={state} i18n={i18n} flex={true} />}
            </div>
            <div className="m-6">
                <button onClick={submitFlexPlan} type="submit" className="form-btn flex justify-center items-center">
                    {t('flexplan-complete-submission')}
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
        </Layout>
    )
}

export default withHelpers(ReviewFlexPage)