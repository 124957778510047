import React from 'react'

export default function TogglePanel({ visible, content }) {
    return (
        <div className={`py-4 ${visible ? 'block' : 'hidden'}`}>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: content }} />
        </div >
    )
}
