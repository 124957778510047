import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import Tooltip from "./comps/Tooltip";
import SubmitBtn from "../SubmitBtn";
import { i18nUri } from "../../helpers";
import PaymentModal from "./PaymentModal";

const InstitutionPage = ({ t, history, state, setState }) => {
    const homeUri = i18nUri("/");
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            university: "",
            union: "",
        },
        validationSchema: Yup.object().shape({
            university: Yup.string().required(t("errors.university")),
            union: Yup.string().required(t("errors.union")),
        }),
        onSubmit: (values) => {
            delete state.flexPlan;
            setState({ ...state, selectedUnion: values.union });
            history.push(`${homeUri}?union=${values.union}`);
        },
    });
    return (
        <Layout>
            <div className="my-4 mx-6">
                <h1 className="">{t("institution-headline")}</h1>
                <p>{t("institution-description")}</p>

                <form onSubmit={formik.handleSubmit} className="mt-8">
                    <div
                        className={
                            formik.values.university === ""
                                ? "form-item"
                                : "form-item-selected"
                        }
                    >
                        <label htmlFor="university">
                            {t("forms.univName")}
                        </label>
                        <Tooltip label={t("helpers.university")} />
                        <br />
                        <select
                            id="university"
                            className="w-full form-ring-item"
                            {...formik.getFieldProps("university")}
                        >
                            <option value="">{t("forms.selectUniv")}</option>
                            {state &&
                                state.universities &&
                                state.universities.map((item) => (
                                    <option value={item.code} key={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                        </select>
                        {formik.touched.university &&
                        formik.errors.university ? (
                            <div className="form-error">
                                {formik.errors.university}
                            </div>
                        ) : null}
                    </div>
                    &nbsp;
                    <div
                        className={
                            formik.values.union === ""
                                ? "form-item"
                                : "form-item-selected"
                        }
                    >
                        <label htmlFor="union">{t("forms.unionName")}</label>
                        <Tooltip label={t("helpers.union")} />
                        <br />
                        <select
                            id="union"
                            className="w-full form-ring-item"
                            {...formik.getFieldProps("union")}
                        >
                            <option value="">{t("forms.selectUnion")}</option>
                            {formik.values.university &&
                                state.universities.find(
                                    (item) =>
                                        item.code === formik.values.university,
                                ) && // remove and use optional chaining if node > v12
                                state.universities
                                    .find(
                                        (item) =>
                                            item.code ===
                                            formik.values.university,
                                    )
                                    .unions.map((u) => (
                                        <option
                                            value={u.code}
                                            key={u.id}
                                            data-code={u.code}
                                        >
                                            {u.name}
                                        </option>
                                    ))}
                        </select>
                        {formik.touched.union && formik.errors.union ? (
                            <div className="form-error">
                                {formik.errors.union}
                            </div>
                        ) : null}
                    </div>
                    <div className="mt-4">
                        <SubmitBtn />
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default withHelpers(InstitutionPage);
