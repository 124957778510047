import React, { useState, useEffect } from 'react'
import withHelpers from '../../hooks/withHelpers'
import Layout from '../Layout'
import DocumentsSummary from './comps/DocumentsSummary'
import OptInOptionsSummary from './comps/OptInOptionsSummary'
import PersonalInformationSummary from './comps/PersonalInformationSummary'
import PersonsSummary from './comps/PersonsSummary'
import { i18nUri } from '../../helpers'

const ReviewPage = ({ t, history, state, i18n, setState, prevNext: { prev, next } }) => {
    const [loaded, setLoaded] = useState(false)
    const confirmUrl = i18nUri('/form/confirmation')
    useEffect(() => {
        if (!state['options']) {
            history.push(prev)
        }
        setLoaded(true)
    }, [])
    const proceedToPayment = () => {
        setState({ ...state, reviewed: true })
        history.push(next)
    }
    const skipToConfirmation = () => {
        setState({ ...state, reviewed: true, payment: {intentId: null, status: null} })
        history.push(confirmUrl);
    }

    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t('summary-title')}</h1>
                <p className="text-sm text-gray-600 mt-2">{t('summary-desc')}</p>
            </div>

            <div className="space-y-10">
                {loaded && <OptInOptionsSummary state={state} />}
                {loaded && <PersonalInformationSummary state={state} i18n={i18n} />}
                {loaded && <PersonsSummary state={state} i18n={i18n} />}
                {loaded && state.hasOwnProperty('docs') && <DocumentsSummary state={state} i18n={i18n} />}
            </div>

            <div className="m-6">
                { (state.billing_disabled) ?
                <button onClick={skipToConfirmation} type="submit" className="form-btn flex justify-center items-center">
                    {t('review-proceed-submission')}
                </button>
                :
                <button onClick={proceedToPayment} type="submit" className="form-btn flex justify-center items-center">
                    {t('review-proceed-payment')}
                </button>
                }
            </div>
        </Layout>
    )
}

export default withHelpers(ReviewPage)
