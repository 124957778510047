import { determineLangFromUri } from "../../helpers";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import ConfirmationWidget from "./comps/ConfirmationWidget";
import axios from "axios";
import React, { useState, useEffect } from "react";

const ConfirmationPage = ({
    t,
    history,
    state,
    i18n,
    setState,
    prevNext: { prev, next },
}) => {
    const [sending, setSending] = useState(true);
    const [code, setCode] = useState(null);
    const [submissionError, setSubmissionError] = useState();
    useEffect(() => {
        if (!state["payment"]) {
            history.push(prev);
        }

        const submit = async () => {
            const formData = new FormData();
            formData.append("language", determineLangFromUri());
            formData.append("selectedUnion", state.selectedUnion);
            formData.append("options", JSON.stringify(state.options));
            formData.append("school", JSON.stringify(state.school));
            formData.append("plans", JSON.stringify(state.plans));
            formData.append("student", JSON.stringify(state.student));
            formData.append("payment", JSON.stringify(state.payment));

            if (state.hasOwnProperty("person")) {
                formData.append("person", JSON.stringify(state.person));
            }

            if (state.hasOwnProperty("extras")) {
                formData.append("extras", JSON.stringify(state.extras));
            }

            if (state.hasOwnProperty("docs")) {
                let count = 0;
                for (let doc of state.docs) {
                    count++;
                    formData.append("docs[]", doc.file);
                }
            }

            const headers = {
                "Content-Type": "multipart/form-data",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            };
            await axios
                .post("/api/form/submissions", formData, { headers: headers })
                .then(({ data }) => {
                    setSending(false);
                    setCode(data.code);
                    // now get rid of forms data in state
                    const newState = { ...state };
                    delete newState.school;
                    delete newState.options;
                    delete newState.plans;
                    delete newState.student;
                    delete newState.person;
                    delete newState.extras;
                    delete newState.reviewed;
                    delete newState.payment;
                    setState(newState);
                })
                .catch((error) => {
                    setSending(false);
                    setSubmissionError(error.response.data.message);
                });
        };
        if (state.hasOwnProperty("payment")) submit();
    }, []);

    const goBack = () => {
        const url = `${next}?union=${state.selectedUnion}`;
        history.push(url);
    };
    return (
        <Layout schoolUnionInfo={true}>
            <ConfirmationWidget
                flex={false}
                sending={sending}
                code={code}
                goBack={goBack}
                message={t("optin-confirmation-desc")}
                warning={t("optin-not-approval-desc")}
                submissionError={submissionError}
            />
        </Layout>
    );
};

export default withHelpers(ConfirmationPage);
