import React from 'react'
import { useHistory } from 'react-router'
import { getT } from '../../../helpers'

export default function SummaryBlock({ header, editUrl, children }) {
    const history = useHistory()
    const t = getT()
    const onEdit = (e) => {
        e.preventDefault()
        history.push(editUrl)
    }
    return (
        <div className="mt-6 mx-6">
            <div className="pt-1 border-t border-gray-400">
                <div className="flex">
                    <div className="flex flex-1">
                        <h2 className="text-xl font-semibold">{header}</h2>
                    </div>
                    <div className="w-1/5 text-right text-sm underline font-bold">
                        <a
                            onClick={e => onEdit(e)}
                            href={editUrl}
                            title="Edit"
                            className="text-right text-sm underline font-bold">{editUrl.includes('extra') ? t('forms.add-edit') : t('forms.edit')}</a>
                    </div>
                </div>

            </div>
            <div className="w-full pt-2 text-md text-gray-600">
                {children()}
            </div>
        </div>
    )
}

