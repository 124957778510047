import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { determineLangFromUri } from "./helpers"
import english from "./locales/en.json"
import french from "./locales/fr.json"

const resources = {
    en: {
        translation: english
    },
    fr: {
        translation: french
    }
}

const lang = determineLangFromUri()

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })

export default i18n