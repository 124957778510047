import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import SubmitBtn from "../SubmitBtn";
import Drawer from "./comps/Drawer";
import Tooltip from "./comps/Tooltip";
import TogglePanel from "./comps/TogglePanel";

const OptionsFormPage = ({
    t,
    history,
    state,
    setState,
    prevNext: { prev, next },
}) => {
    const [drawerIsOpened, setDrawerIsOpened] = useState(false);
    const [content, setContent] = useState(null);
    const showCriteriaPanel = (e, ctt) => {
        e.preventDefault();

        const cttx = `<div class="">
            <div class="px-6">
                <div class="text-2xl font-bold mt-2 mb-4">Eligibility Criteria</div>
                <div class="text-xl text-gray-900 tracking-widest">Who Can I opt-in?</div>
            </div>
            <div class="p-6">${ctt}</div>
        </div>`;

        setContent(cttx);
        setDrawerIsOpened(true);
    };
    const formik = useFormik({
        initialValues: state["options"]
            ? state["options"]
            : // { "options": "optin", "additional": "family" },
              {
                  options: "",
                  additional: "",
              },
        validationSchema: Yup.object().shape({
            options: Yup.string().required(t("errors.plans-options")),
            additional: Yup.string()
                .required(t("errors.plans-additional-req"))
                .when("options", {
                    is: "enrolled",
                    then: Yup.string().test(
                        "invalidSection",
                        t("errors.invalid-options-selection"),
                        (val) => val !== "nobody",
                    ),
                }),
            // additional: Yup.string().when('options', {
            //     is: 'enrolled',
            //     then: Yup.string().required(t('errors.plans-additional-req'))
            // }),
        }),
        onSubmit: (values) => {
            const data = { options: values };
            setState({ ...state, ...data });
            history.push(next);
        },
    });

    useEffect(() => {
        if (!state["school"]) {
            history.push(prev);
        }
    }, []);

    const [optionPanelVisibility, setOptionPanelVisibility] = useState({});
    const toggleOptionPanel = (e, optionId) => {
        e.preventDefault();
        if (optionPanelVisibility[optionId]) {
            const newState = { ...optionPanelVisibility };
            newState[optionId] = false;
            setOptionPanelVisibility(newState);
        } else {
            const newState = { ...optionPanelVisibility };
            newState[optionId] = true;
            setOptionPanelVisibility(newState);
        }
    };
    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t("optin-options-title")}</h1>
                <p className="text-sm text-gray-600 mt-2">
                    {t("optin-options-desc")}
                </p>
                <form onSubmit={formik.handleSubmit} className="mt-4">
                    <div>
                        <ul className="space-y-6">
                            <li
                                className={
                                    formik.values.options === "optin"
                                        ? "form-item-selected"
                                        : "form-item"
                                }
                            >
                                <div>
                                    <Tooltip
                                        label={t("helpers.optin-option")}
                                    />
                                </div>
                                <label className="flex">
                                    <input
                                        name="options"
                                        name="options"
                                        type="radio"
                                        value="optin"
                                        checked={
                                            formik.values.options === "optin"
                                        }
                                        onChange={formik.handleChange}
                                        className="mt-1 text-dark-green"
                                    />
                                    <span className="ml-2">
                                        {t("optin-option-1")}
                                    </span>
                                </label>
                            </li>
                            <li
                                className={
                                    formik.values.options === "enrolled"
                                        ? "form-item-selected"
                                        : "form-item"
                                }
                            >
                                <div>
                                    <Tooltip
                                        label={t("helpers.enrolled-option")}
                                    />
                                </div>
                                <label className="flex">
                                    <input
                                        name="options"
                                        name="options"
                                        type="radio"
                                        value="enrolled"
                                        checked={
                                            formik.values.options === "enrolled"
                                        }
                                        onChange={formik.handleChange}
                                        className="mt-1 text-dark-green"
                                    />
                                    <span className="ml-2">
                                        {t("optin-option-2")}
                                    </span>
                                </label>
                            </li>
                        </ul>
                        {formik.touched.options && formik.errors.options ? (
                            <div className="form-error">
                                {formik.errors.options}
                            </div>
                        ) : null}
                    </div>
                    &nbsp;
                    <div className="">
                        <p className="font-bold">
                            {t("optin-additional-text")}
                        </p>
                    </div>
                    &nbsp;
                    <div>
                        <ul className="space-y-6">
                            <li
                                className={
                                    formik.values.additional === "nobody"
                                        ? "form-item-selected"
                                        : "form-item"
                                }
                            >
                                <div>
                                    <Tooltip
                                        label={t("helpers.nobody-additional")}
                                    />
                                </div>
                                <label className="flex">
                                    <input
                                        name="additional"
                                        type="radio"
                                        value="nobody"
                                        checked={
                                            formik.values.additional ===
                                            "nobody"
                                        }
                                        onChange={formik.handleChange}
                                        className="mt-1 text-dark-green"
                                    />
                                    <div className="inline-block ml-2">
                                        {t("optin-additional-nobody")}
                                    </div>
                                </label>
                            </li>
                            <li
                                className={
                                    formik.values.additional === "person"
                                        ? "form-item-selected"
                                        : "form-item"
                                }
                            >
                                <div>
                                    <Tooltip
                                        label={t("helpers.person-additional")}
                                    />
                                </div>
                                <label className="flex">
                                    <input
                                        name="additional"
                                        type="radio"
                                        value="person"
                                        checked={
                                            formik.values.additional ===
                                            "person"
                                        }
                                        onChange={formik.handleChange}
                                        className="mt-1 text-dark-green"
                                    />
                                    <div className="inline-block ml-2 w-full">
                                        {t("optin-additional-singlep")}
                                        <p className="text-sm mt-2">
                                            {t("optin-additional-singlep-desc")}
                                            <br />
                                        </p>
                                        <a
                                            onClick={(e) =>
                                                showCriteriaPanel(
                                                    e,
                                                    t(
                                                        "errors.person-additional-more",
                                                    ),
                                                )
                                            }
                                            className="mt-4 inline-block underline text-sm sm:hidden"
                                            href="#"
                                        >
                                            {t("optin-additional-criteria")}
                                        </a>
                                        <div className="hidden sm:block float-right">
                                            <a
                                                onClick={(e) =>
                                                    toggleOptionPanel(
                                                        e,
                                                        "single-person",
                                                    )
                                                }
                                                href="#"
                                                className="flex underline text-light-green text-sm"
                                            >
                                                {t("learn-more")}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 border border-dark-green rounded-full ml-2 "
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </label>
                                <TogglePanel
                                    visible={
                                        optionPanelVisibility["single-person"]
                                    }
                                    content={`<p>${t(
                                        "errors.person-additional-more",
                                    )}</p>`}
                                />
                            </li>
                            <li
                                className={
                                    formik.values.additional === "family"
                                        ? "form-item-selected"
                                        : "form-item"
                                }
                            >
                                <div>
                                    <Tooltip
                                        label={t("helpers.family-additional")}
                                    />
                                </div>
                                <label className="flex">
                                    <input
                                        name="additional"
                                        type="radio"
                                        value="family"
                                        checked={
                                            formik.values.additional ===
                                            "family"
                                        }
                                        onChange={formik.handleChange}
                                        className="mt-1 text-dark-green"
                                    />
                                    <div className="inline-block ml-2">
                                        {t("optin-additional-family")}
                                        <p className="text-sm mt-2">
                                            {t("optin-additional-family-desc")}
                                            <br />
                                        </p>
                                        <a
                                            onClick={(e) =>
                                                showCriteriaPanel(
                                                    e,
                                                    t(
                                                        "errors.family-additional-more",
                                                    ),
                                                )
                                            }
                                            className="mt-4 inline-block underline text-sm sm:hidden"
                                            href="#"
                                        >
                                            {t("optin-additional-criteria")}
                                        </a>
                                        <div className="hidden sm:block float-right">
                                            <a
                                                onClick={(e) =>
                                                    toggleOptionPanel(
                                                        e,
                                                        "family",
                                                    )
                                                }
                                                href="#"
                                                className="flex underline text-light-green text-sm"
                                            >
                                                {t("learn-more")}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 border border-dark-green rounded-full ml-2 "
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </label>
                                <TogglePanel
                                    visible={optionPanelVisibility["family"]}
                                    content={`<p>${t(
                                        "errors.family-additional-more",
                                    )}</p>`}
                                />
                            </li>
                        </ul>
                        {formik.touched.options && formik.errors.additional ? (
                            <div className="form-error">
                                {formik.errors.additional}
                            </div>
                        ) : null}
                    </div>
                    <div className="mt-4">
                        <SubmitBtn />
                    </div>
                </form>

                <Drawer
                    isOpen={drawerIsOpened}
                    setIsOpen={setDrawerIsOpened}
                    content={content}
                />
            </div>
        </Layout>
    );
};

export default withHelpers(OptionsFormPage);
