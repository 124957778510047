import React from 'react'
import { determineLangFromUri, getT, i18nUri } from '../../../helpers'
import SummaryBlock from './SummaryBlock'

export default function DocumentsSummary({ i18n, state: { docs }, flex }) {
    const t = getT()
    const lang = determineLangFromUri()
    const editUrl = flex ? i18nUri('/flex/documents') : i18nUri('/form/documents')
    return (
        <SummaryBlock header={flex ? t('flexplan') : t('optin')} editUrl={editUrl}>
            {() => {
                return (
                    <ul className="space-y-1">
                        {docs && docs.length
                            ? docs.map(doc => (
                                <li key={doc.id} className="">{doc.file.name}</li>
                            ))
                            : <li className="italic text-xs text-gray-600">
                                {t('flexplan-no-document')}
                            </li>
                        }
                    </ul>
                )
            }}
        </SummaryBlock>
    )
}
