import { httpClient } from "../helpers";

const plans = {
    all: async function () {
        const response = await httpClient("/api/plans", {});
        return response;
    },
    get: async function (id) {
        const params = new URLSearchParams();
        params.append("filter", JSON.stringify({ id: [id] }));
        try {
            const response = await httpClient(`/api/plans?${params}`);
            return response.json[0];
        } catch (err) {
            throw err;
        }
    },
};

const Api = { plans };

export default Api;
