import React from 'react'
import { FieldArray } from 'formik'
import { v4 as uuidv4 } from 'uuid'

export default function DocumentsUploader({ t, values, setValues, errors }) {
    const addDocument = (values, doc) => {
        const { docs } = values
        docs.push({ id: uuidv4(), file: doc })
        return { ...values, docs }
    }
    const removeDocument = (values, id) => {
        if (confirm(t('flexplan-confirm-file-delete'))) {
            return {
                ...values,
                docs: values.docs.filter(doc => doc.id !== id)
            }
        }
        return values
    }
    return (
        <>
            <label className="sr-only" htmlFor="docUpload">{t('flexplan-select-document')}</label>
            <input id="docUpload" className="flex-1" type="file" multiple={false}
                onChange={e => {
                    setValues(addDocument(values, e.currentTarget.files[0]))
                    e.currentTarget.value = ''
                }}
            />

            <hr className="my-4" />

            <FieldArray
                name="docs"
                render={arrayHelpers => (
                    <div>
                        <ul className="space-y-4">
                            {
                                values.docs && values.docs.length > 0
                                    ? (
                                        values.docs.map((doc, index) => (
                                            <li key={doc.id} className="flex items-center bg-white p-4 rounded-lg">

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="37"
                                                    height="46"
                                                    viewBox="0 0 37 46"
                                                    className="w-12 h-12"
                                                >
                                                    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                                        <g fillRule="nonzero" stroke="#000" transform="translate(-39 -262)">
                                                            <g transform="translate(24 251)">
                                                                <path d="M37.875 12h-17.5c-2.406 0-4.353 1.969-4.353 4.375l-.022 35c0 2.406 1.947 4.375 4.353 4.375h26.272A4.388 4.388 0 0051 51.375v-26.25L37.875 12zm4.375 31.719h-17.5v-4.375h17.5v4.375zm0-8.75h-17.5v-4.375h17.5v4.375zM38.969 24.03V12L51 24.031H38.969z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div className="flex-1">
                                                    {doc.file.name}
                                                    {errors.docs && errors.docs[index] &&
                                                        <div className="text-red-600 uppercase">
                                                            {errors.docs[index]}
                                                        </div>
                                                    }
                                                </div>
                                                <div className="flex justify-end">
                                                    <button type="button" onClick={e => setValues(removeDocument(values, doc.id))}
                                                        className="text-gray-500 rounded p-1 hover:bg-gray-200 hover:text-black">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </li>
                                        ))

                                    )
                                    : <li />
                            }
                        </ul>

                    </div>
                )}
            />

            {(typeof errors.docs !== 'undefined')
                ? <div className="bg-yellow-50 border border-red-200 p-2 text-gray-600 text-sm">
                    {t('errors.docs')}
                </div>
                : null}
        </>
    )
}
