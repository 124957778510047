import { Formik } from "formik";
import * as Yup from "yup";
import React from "react";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import SubmitBtn from "../SubmitBtn";
import PersonCommonInfo from "./comps/PersonCommonInfo";
import StudentContactInfo from "./comps/StudentContactInfo";
import { findUnionObject, getT, i18nUri } from "../../helpers";
import Tooltip from "./comps/Tooltip";
import axios from "axios";

export const studentValidationRules = (state) => {
    const t = getT();

    let studentEmailConditionalRule = Yup.string().email(
        t("errors.email-invalid"),
    );
    if (state?.invitation?.email) {
        const statedRegex = new RegExp(state.invitation?.email);
        studentEmailConditionalRule = Yup.string().matches(
            statedRegex,
            t("errors.invitation-email-invalid"),
        );
    }

    return {
        firstName: Yup.string().required(t("errors.required-field")),
        lastName: Yup.string().required(t("errors.required-field")),
        dobMM: Yup.number().required(t("errors.required-field")),
        dobDD: Yup.number()
            .typeError(t("errors.invalid-number"))
            .moreThan(0, t("errors.invalid-number"))
            .lessThan(32, t("errors.invalid-number"))
            .required(t("errors.required-field")),
        dobYY: Yup.number()
            .typeError(t("errors.invalid-number"))
            .moreThan(1919, t("errors.invalid-number"))
            .lessThan(new Date().getFullYear() + 1, t("errors.invalid-number"))
            .required(t("errors.required-field")),
        sex: Yup.string().required(t("errors.required-field")),
        address1: Yup.string().required(t("errors.required-field")),
        address2: Yup.string(),
        city: Yup.string().required(t("errors.required-field")),
        province: Yup.string()
            .required(t("errors.required-field"))
            .matches(/(AB|BC|MB|NB|NL|NT|NS|NU|ON|PE|QC|SK|YT)/),
        country: Yup.string().required(t("errors.required-field")),
        studEmail: studentEmailConditionalRule,
        studEmailConfirm: Yup.string()
            .required(t("errors.required-field"))
            .oneOf([Yup.ref("studEmail"), null], t("errors.emails-must-match")),
        studPhoneAreaCode: Yup.number()
            .typeError(t("errors.areaCode-num"))
            .required(t("errors.required-field"))
            .test("len", t("errors.area-code-3digits"), (val) =>
                val !== undefined ? val.toString().length === 3 : false,
            ),
        studPhone: Yup.number()
            .typeError(t("errors.phone-num"))
            .required(t("errors.required-field"))
            .test("len", t("errors.phone-number-7digits"), (val) =>
                val !== undefined ? val.toString().length === 7 : false,
            ),
    };
};

const StudentFormPage = ({
    t,
    history,
    state,
    i18n,
    setState,
    prevNext: { prev, next },
}) => {
    React.useEffect(() => {
        if (!state["plans"]) {
            history.push(prev);
        }
    }, []);
    const defaultValues = state["student"]
        ? state["student"]
        : // { "studId": "1122", "firstName": "Marie", "lastName": "UWANYIRIGIRA", "dobMM": "1", "dobDD": "5", "dobYY": "1984", "sex": "female", "address1": "130 Ch. Bates", "address2": "app 309", "city": "Mont-Royal", "province": "QC", "country": "Canada", "studEmail": "kassim@machioudi.com", "studEmailConfirm": "kassim@machioudi.com", "studPhoneAreaCode": "514", "studPhone": "8357156" }
          {
              studId: "",
              firstName: "",
              lastName: "",
              dobMM: "",
              dobDD: "",
              dobYY: "",
              sex: "",
              address1: "",
              address2: "",
              city: "",
              province: "",
              postalcode: "",
              country: "Canada",
              studEmail: "",
              studEmailConfirm: "",
              studPhoneAreaCode: "",
              studPhone: "",
          };
    const checkPreviousSubmission = async (studentId) => {
        return new Promise(async (resolve, reject) => {
            const {
                school: { university, union },
            } = state;
            const {
                options: { options },
            } = state;
            const params = { studentId, university, union, options };
            await axios
                .get("/api/verifySubmission", { params })
                .then(({ data }) => resolve(!data.hasSubmission))
                .catch(() => resolve(false));
        });
    };
    const validationRules = studentValidationRules(state);
    const validationSchema = Yup.object().shape({
        ...validationRules,
        studId: Yup.number()
            .typeError(t("errors.studId-num"))
            .required(t("errors.studId-req"))
            .test(
                "checkPreviousSubmission",
                t("errors.check-previous-submission"),
                checkPreviousSubmission,
            ),
        postalcode: Yup.string()
            .required(t("errors.required-field"))
            .matches(
                /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/i,
                t("errors.postalcode-invalid"),
            ),
    });
    const reviewUrl = i18nUri("/form/review");
    const documentsUrl = i18nUri("/form/documents");
    const submitForm = (values) => {
        const data = { student: values };
        setState({ ...state, ...data });
        if (state["options"]["additional"] === "nobody") {
            const unionObj = findUnionObject(
                state.selectedUnion,
                state.universities,
            );
            if (unionObj.flexDocUpload === 1) {
                history.push(documentsUrl);
            } else {
                history.push(reviewUrl);
            }
        } else {
            history.push(next);
        }
    };

    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t("student-options-title")}</h1>
                <p className="text-sm text-gray-600 mt-2">
                    {t("student-options-desc")}
                </p>
            </div>
            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submitForm}
            >
                {({ handleSubmit, getFieldProps, errors, touched }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="my-4 mx-6 space-y-4"
                    >
                        <div className="form-item space-y-4">
                            <div className="flex flex-col">
                                <div className="flex">
                                    <label className="flex" htmlFor="studId">
                                        {t("forms.lastID")}
                                    </label>
                                    <Tooltip label={t("helpers.studentID")} />
                                </div>
                                <input
                                    id="studId"
                                    type="text"
                                    className="mt-1 form-ring-item"
                                    placeholder={t("forms.EnterStudentID")}
                                    {...getFieldProps("studId")}
                                />
                                {touched.studId && errors.studId ? (
                                    <div className="form-error">
                                        {errors.studId}
                                    </div>
                                ) : null}
                            </div>

                            <PersonCommonInfo />
                        </div>

                        <div className="form-item space-y-4">
                            <StudentContactInfo />
                        </div>

                        <div className="mt-4">
                            <SubmitBtn />
                        </div>
                    </form>
                )}
            </Formik>
        </Layout>
    );
};

export default withHelpers(StudentFormPage);
