import React from 'react'
import { dateFormat, determineLangFromUri, getT, i18nUri, makeDate } from '../../../helpers'
import SummaryBlock from './SummaryBlock'

export default function PersonalInformationSummary({ i18n, state: { student }, flex }) {
    const t = getT()
    const editUrl = flex ? i18nUri(`/flex/student`) : i18nUri(`/form/student`)
    const data = [
        {
            label: t('forms.lastID'),
            value: student.studId,
        },
        {
            label: t('forms.firstName'),
            value: student.firstName,
        },
        {
            label: t('forms.lastName'),
            value: student.lastName,
        },
        {
            label: t('forms.dob'),
            value: dateFormat(makeDate(student.dobMM, student.dobDD, student.dobYY), i18n.language),
        },
        {
            label: t('forms.sex'),
            value: student.sex,
        },
        {
            label: t('forms.address'),
            value: student.address1,
        },
        {
            label: t('forms.address'),
            value: student.address2,
        },
        {
            label: t('forms.city'),
            value: student.city,
        },
        {
            label: t('forms.province'),
            value: student.province,
        },
        {
            label: t('forms.postalcode'),
            value: student.postalcode,
        },
        {
            label: t('forms.country'),
            value: student.country,
        },
        {
            label: t('forms.email'),
            value: student.studEmail,
        },
        {
            label: t('forms.phone'),
            value: student.studPhoneAreaCode ? `(${student.studPhoneAreaCode}) ${student.studPhone}` : '',
        }
    ]
    return (
        <SummaryBlock header={t('student-options-title')} editUrl={editUrl}>
            {() => {
                return (
                    <ul className="space-y-1">
                        {data.map((item, index) => (
                            <li className="flex" key={index}>
                                <span className="">{item.label}:&nbsp;</span>
                                <span className="font-semibold">{item.value}</span>
                            </li>
                        ))}
                    </ul>
                )
            }}
        </SummaryBlock>
    )
}
