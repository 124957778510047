import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
    maxValue,
    minValue,
    number,
    NumberInput,
    required,
    TextInput,
} from "react-admin";
import { useTranslate } from "ra-core";
import { currencyFormat } from "../../helpers";
import { useFormState } from "react-final-form";

// interface SubmissionRefundDialogProps {
//     updating: boolean,
//     open: boolean,
//     onClose: () => void,
//     onSubmit: () => void,
//     record: Submission
// }

function SubmissionRefundDialog({ updating, open, onClose, onSubmit, record }) {
    const t = useTranslate();
    const { values, hasValidationErrors } = useFormState();
    const validateRefundAmount = [
        required(),
        number(),
        minValue(1, "Value must be greater than 0"),
        maxValue(
            record.charge_amount - record.refund_amount,
            "Value must be less than or equal to the amount available to refund",
        ),
    ];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="submission-refund-dialog-title"
        >
            <DialogTitle id="submission-refund-dialog-title">
                {t("cfs.submission-refund-confirmation")}
            </DialogTitle>
            <DialogContent>
                <List dense>
                    <ListItem>
                        Amount Refunded: {currencyFormat(record.refund_amount)}
                    </ListItem>
                    <ListItem>
                        Amount Available to Refund:{" "}
                        {currencyFormat(
                            record.charge_amount - record.refund_amount,
                        )}
                    </ListItem>
                </List>
                <NumberInput
                    fullWidth
                    placeholder="Refund Amount"
                    source="new_refund_amount"
                    min={0}
                    max={record.charge_amount - record.refund_amount}
                    validate={validateRefundAmount}
                />
                <TextInput
                    multiline
                    fullWidth
                    resettable
                    placeholder="Refund Notes"
                    source="refund_notes"
                    disabled={!!(record && record.reviewed_at)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    type="submit"
                    onClick={onSubmit}
                    color="secondary"
                    disabled={
                        updating ||
                        values.new_refund_amount <= 0 ||
                        hasValidationErrors
                    }
                >
                    Refund
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SubmissionRefundDialog.propTypes = {
    updating: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
};

export default SubmissionRefundDialog;
