import AppContext from "./appContext";
import axios from "axios";
import { useContext } from "react";
import { fetchUtils } from "react-admin";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

/**
 * Returns which language is active based on the URI
 * @param uri
 * @returns {'en'|'fr'}
 */
const determineLangFromUri = (uri = null) => {
    const { pathname } = window.location;
    if (uri === null) {
        uri = pathname;
    }
    if (uri.startsWith("/fr")) {
        return "fr";
    }

    return "en";
};

const i18nUri = (uri = null, lang = null) => {
    const { i18n } = useTranslation();
    if (lang === null) lang = i18n.language;
    if (uri === null) uri = window.location.pathname;

    if (lang === "fr" && !uri.startsWith("/fr"))
        return uri === "/" ? "/fr" : `/fr${uri}`;
    if (lang === "en" && uri.startsWith("/fr"))
        return uri === "/" ? "/" : uri.slice(3);
    return uri;
};

const getT = () => {
    const { i18n } = useTranslation();
    return i18n.getFixedT(i18n.language);
};

const getUserSelectedUniversity = () => {
    const [state] = useContext(AppContext);
    if (typeof state["universities"] !== "object") return undefined;
    if (typeof state["school"] !== "object") return undefined;
    const univ = state["universities"].find(
        (u) => u.code === state["school"].university,
    );
    return univ;
};

const getUniversityAndUnionNames = (data, unionCode) => {
    const univ = findUniversityOfUnion(data, unionCode);
    if (univ === undefined) {
        return {
            university: null,
            union: null,
        };
    }

    const union = univ.unions.find((u) => u.code === unionCode);
    return {
        university: univ.name,
        union: union.name,
    };
};

const getUnionPlans = () => {
    const [state] = useContext(AppContext);

    if (state.invitation?.plan) return [state.invitation?.plan];

    const univ = getUserSelectedUniversity();
    if (!state["school"] || !state["school"].union) return undefined;

    const union = univ.unions.find((u) => u.code === state["school"].union);
    if (typeof union.plans === "object" && union.plans.length > 0) {
        return union.plans;
    }

    return univ.plans;
};

/**
 *
 * @param {string} name
 * @param {number} index
 * @returns {string|*}
 */
const getPersonFieldName = (name, index) =>
    index !== undefined ? `persons.${index}.${name}` : name;

const getRelationList = () => ({
    child: {
        en: "Child",
        fr: "Enfant",
    },
    commonlaw: {
        en: "Common Law Partner",
        fr: "Conjoint de fait",
    },
    spouse: {
        en: "Spouse",
        fr: "Conjointe",
    },
});

const optInInfo = (state) => {
    const t = getT();
    let info = t("option-student");
    if (state["options"] && state["options"].hasOwnProperty("additional")) {
        switch (state["options"]["additional"]) {
            case "person":
                info = `${info} ${t("optin-additional-singlep")}`;
                break;

            case "family":
                info = `${info} ${t("optin-additional-family")}`;
                break;
        }
    }
    return info;
};

const makeDate = (month, day, year) =>
    new Date(Number(year), Number(month) - 1, Number(day));

const currencyFormat = (value, lang = "en") =>
    new Intl.NumberFormat(lang === "en" ? "en-US" : "fr-FR", {
        style: "currency",
        currency: "CAD",
    }).format(value);
const dateFormat = (dt, lang = "en") =>
    new Intl.DateTimeFormat(lang === "en" ? "en-US" : "fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
    }).format(dt);

/**
 *
 * @param {string} url
 * @param {RequestInit} options
 * @returns {Promise<{status: number, headers: Headers, body: string, json: any} | unknown>}
 */
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }
    const { token } = JSON.parse(sessionStorage.getItem("auth"));
    options.headers.set("Authorization", `Bearer ${token}`);
    options.headers.set(
        "X-CSRF-TOKEN",
        $('meta[name="csrf-token"]').attr("content"),
    );
    return fetchUtils.fetchJson(url, options).catch((error) => {
        return new Promise(function (resolve, reject) {
            reject({ message: error.message, error });
        });
    });
};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 *
 * @param {string} unionCode
 * @returns {Promise<string>}
 */
const fetchUnionLogo = async (unionCode) => {
    let unionLogoPath = null;
    await axios
        .get(`/api/data/logos/${unionCode}`)
        .then(({ data }) => {
            const { path } = data;
            unionLogoPath = path;
            // setState(state => { return { ...state, unionLogoPath: path } })
        })
        .catch((error) => {});
    return unionLogoPath;
};

/**
 *
 * @param {{ unions: {code: string, name: string, logo: string}[]}[]} data
 * @param {string} unionCode
 * @returns {*}
 */
const findUniversityOfUnion = (data, unionCode) => {
    const univEntry = data.find((univ) => {
        const univUnions = univ.hasOwnProperty("unions") ? univ.unions : [];
        const unionEntry = univUnions.find((union) => union.code === unionCode);
        return unionEntry !== undefined;
    });
    return univEntry;
};

/**
 *
 * @param {string} code
 * @param {{unions: {code: string, name: string, logo: string}[]}[]} universities
 * @returns {undefined|*}
 */
const findUnionObject = (code, universities) => {
    if (universities === undefined || code === undefined) {
        return undefined;
    }

    for (let univ of universities) {
        const union = univ.unions.find((u) => u.code === code);
        if (union !== undefined) {
            return union;
        }
    }
    return undefined;
};

/**
 *
 * @param {{ options: 'optin'|'enrolled', additional: 'nobody'|'person'|'family' }} options
 * @returns {string}
 */
const getPlanCostField = (options) => {
    const option = options.options;
    const additional = options.additional;
    if (option === "optin" && additional === "nobody") return "cost";
    if (option === "optin" && additional === "person")
        return "cost_student_and_one";
    if (option === "optin" && additional === "family")
        return "cost_student_and_two_plus";
    if (option === "enrolled" && additional === "person")
        return "cost_plus_one";
    if (option === "enrolled" && additional === "family")
        return "cost_two_plus";
};

/**
 *
 * @param {{ options: 'optin'|'enrolled', additional: 'nobody'|'person'|'family' }} options
 * @param {{cost: number,cost_student_and_one: number, cost_student_and_two_plus: number, cost_plus_one: number, cost_two_plus: number}} plan
 * @returns {*}
 */
const determinePlanCost = (options, plan) => {
    const costField = getPlanCostField(options);
    return plan[costField];
};

const provinces = {
    AB: {
        en: "Alberta",
        fr: "Alberta",
    },
    BC: {
        en: "British Columbia",
        fr: "Colombie-Britannique",
    },
    MB: {
        en: "Manitoba",
        fr: "Manitoba",
    },
    NB: {
        en: "New Brunswick",
        fr: "Nouveau-Brunswick",
    },
    NL: {
        en: "Newfoundland and Labrador",
        fr: "Terre-Neuve-et-Labrador",
    },
    NT: {
        en: "Northwest Territories",
        fr: "Territoires du Nord-Ouest",
    },
    NS: {
        en: "Nova Scotia",
        fr: "Nouvelle-Écosse",
    },
    NU: {
        en: "Nunavut",
        fr: "Nunavut",
    },
    ON: {
        en: "Ontario",
        fr: "Ontario",
    },
    PE: {
        en: "Prince Edward Island",
        fr: "Île du Prince-Édouard",
    },
    QC: {
        en: "Quebec",
        fr: "Québec",
    },
    SK: {
        en: "Saskatchewan",
        fr: "Saskatchewan",
    },
    YT: {
        en: "Yukon",
        fr: "Yukon",
    },
};

export const isInvitation = (query) => {
    return query.has("expires") && query.has("id");
};

export {
    determineLangFromUri,
    i18nUri,
    getT,
    getPersonFieldName,
    getRelationList,
    optInInfo,
    makeDate,
    currencyFormat,
    dateFormat,
    getUserSelectedUniversity,
    getUnionPlans,
    httpClient,
    fetchUnionLogo,
    useQuery,
    findUniversityOfUnion,
    getUniversityAndUnionNames,
    provinces,
    findUnionObject,
    getPlanCostField,
    determinePlanCost,
};
