import { getPlanCostField } from "../../helpers";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import SubmitBtn from "../SubmitBtn";
import PaymentModal from "./PaymentModal";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { Formik } from "formik";
import React, { useState, useRef } from "react";
import * as Yup from "yup";

const PaymentFormPage = ({
    t,
    history,
    state,
    i18n,
    setState,
    prevNext: { prev, next },
}) => {
    const [isProcessing, setProcessing] = useState(false);
    const [paymentError, setPaymentError] = useState(null);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const paymentErrorRef = useRef();
    paymentErrorRef.current = paymentError;
    React.useEffect(() => {
        if (!state["reviewed"]) {
            history.push(prev);
        }
    }, []);
    const defaultValues = { agreement: false };
    const validationSchema = Yup.object().shape({
        agreement: Yup.boolean().oneOf([true], t("errors.accept-agreement")),
    });

    const stripe = useStripe();
    const elements = useElements();

    const submitForm = async () => {
        const headers = {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        };

        const { student, plans } = state;
        const data = {
            planId: plans.plan,
            planCostField: getPlanCostField(state.options),
            email: student.studEmail,
            studentId: student.studId,
        };

        setProcessing(true);
        setPaymentError(null);
        const {
            data: { clientSecret },
        } = await axios
            .post("/api/form/payment_intents", data, headers)
            .catch((err) => {
                setPaymentError(err.response.data.error);
                setProcessing(false);
            });

        if (paymentErrorRef.current === null) {
            const cardElement = elements.getElement(CardElement);
            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            if (paymentMethodReq.error) {
                setPaymentError(paymentMethodReq.error.message);
                setProcessing(false);
                return;
            }

            const confirmCardPayment = await stripe.confirmCardPayment(
                clientSecret,
                {
                    payment_method: paymentMethodReq.paymentMethod.id,
                },
            );
            if (confirmCardPayment.error) {
                setPaymentError(confirmCardPayment.error.message);
                setProcessing(false);
                return;
            }

            setState({
                ...state,
                payment: {
                    intentId: confirmCardPayment.paymentIntent.id,
                    status: confirmCardPayment.paymentIntent.status,
                },
            });

            // show modal then go to next page when the modal is dismissed
            setShowPaymentModal(true);
        }
    };

    const onNextPage = () => {
        history.push(next);
    };

    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t("payment-title")}</h1>
                <p className="text-sm text-gray-600 mt-2">
                    {t("payment-desc")}
                </p>
            </div>

            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={submitForm}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    errors,
                }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="my-4 mx-6 space-y-4"
                    >
                        <div className="form-item">
                            <span className="">{t("payment-amount")}:</span>
                            <span className="">
                                &nbsp;
                                <strong>
                                    ${state.plans && state.plans.cost}
                                </strong>
                            </span>
                        </div>

                        <div className="form-item">
                            <CardElement options={{ hidePostalCode: true }} />
                        </div>

                        <div className="">
                            <label className="mt-8 flex items-start">
                                <input
                                    id="agreement"
                                    name="agreement"
                                    type="checkbox"
                                    value="yes"
                                    className="mr-2 text-dark-green mt-1"
                                    checked={values.agreement}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {t("forms.payment-agreement")}
                            </label>
                            {touched.agreement && errors.agreement ? (
                                <div className="form-error">
                                    {errors.agreement}
                                </div>
                            ) : null}
                        </div>

                        {paymentError && (
                            <div className="form-error">{paymentError}</div>
                        )}

                        <div className="">
                            <SubmitBtn
                                label={t("submit-application")}
                                isProcessing={isProcessing}
                            />
                        </div>

                        <div className="flex justify-center mt-8">
                            {isProcessing && (
                                <svg
                                    className="text-light-green animate-spin -ml-1 mr-3 h-12 w-12"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                        className="opacity-25"
                                    ></circle>
                                    <path
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        className="opacity-75"
                                    ></path>
                                </svg>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
            <PaymentModal
                showModal={showPaymentModal}
                onNextButton={(e) => onNextPage()}
            />
        </Layout>
    );
};

export default withHelpers(PaymentFormPage);
