const defaultTemplateObject = {
    id: 0,
    union_id: 0,
    html_template: { en: "<div></div>", fr: "<div></div>" },
    text_template: { en: "", fr: "" },
    enabled: false,
    subject: { en: "", fr: "" },
    created_at: null,
    updated_at: null,
    variables: [],
};
export const defaultMailTemplates = [
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\FlexSubmissionReceivedMailable",
        subject: {
            en: "Submission (Flex Plan) received",
            fr: "Submission (Flex Plan) received",
        },
    },
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\SubmissionReceivedMailable",
        subject: { en: "Submission received", fr: "Submission received" },
    },
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\SubmissionApprovedMailable",
        subject: { en: "Submission approved", fr: "Submission approved" },
    },
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\SubmissionRejectedMailable",
        subject: { en: "Submission rejected", fr: "Submission rejected" },
    },
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\SubmissionNeedsUpdateMailable",
        subject: { en: "Submission has error", fr: "Submission has error" },
    },
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\PlanCoverageDetailsMailable",
        subject: {
            en: "You plan coverage details",
            fr: "You plan coverage details",
        },
    },
    {
        ...defaultTemplateObject,
        mailable: "App\\Mail\\SpecialOptInInvitationMailable",
        subject: { en: "A plan just for you", fr: "A plan just for you" },
    },
];

/**
 * Returns template name, based on the mailable value
 * @param {string} mailable
 */
export function getTemplateNameByMailable(mailable) {
    if (mailable.includes("App\\Mail\\FlexSubmissionReceived")) {
        return "Flex Submission Received";
    } else if (mailable.includes("App\\Mail\\SubmissionReceived")) {
        return "Opt-In Submission Received";
    } else if (mailable.includes("App\\Mail\\SubmissionApproved")) {
        return "Submission Approved";
    } else if (mailable.includes("App\\Mail\\SubmissionRejected")) {
        return "Submission Rejected";
    } else if (mailable.includes("App\\Mail\\SubmissionNeedsUpdate")) {
        return "Submission Incomplete";
    } else if (mailable.includes("App\\Mail\\PlanCoverageDetails")) {
        return "Plan Coverage Details";
    } else if (mailable.includes("App\\Mail\\SpecialOptInInvitation")) {
        return "Special Opt-In Invitation";
    } else {
        return "";
    }
}
