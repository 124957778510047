import { useFormikContext } from 'formik'
import React from 'react'
import { getT } from '../../../helpers'
import Tooltip from './Tooltip'
import { provinces } from '../../../helpers'
import i18n from '../../../i18n'

const StudentContactInfo = () => {
    const t = getT()
    const formik = useFormikContext()
    const { touched, errors, getFieldProps } = formik
    return (
        <>
            <div className="text-3xl" style={{ marginTop: '36px' }}>{t('forms.studContactInfo')}</div>

            <div className="flex flex-col">
                <div className="flex">
                    <label className="" htmlFor="address1">{t('forms.address')}</label>
                    <Tooltip label={t('helpers.address')} />
                </div>
                <input
                    id="address1"
                    type="text"
                    className="w-full mt-1 form-ring-item"
                    placeholder={t('forms.addr1Desc')}
                    {...getFieldProps('address1')}
                />
                {touched.address1 && errors.address1 ? (
                    <div className='form-error'>{errors.address1}</div>
                ) : null}
                <input
                    id="address2"
                    type="text"
                    className="w-full mt-3 form-ring-item"
                    placeholder={t('forms.addr2Desc')}
                    {...getFieldProps('address2')}
                />
            </div>

            <div className="">
                <div className="flex">
                    <label className="flex" htmlFor="city">{t('forms.city')}</label>
                    <Tooltip label={t('helpers.city')} />
                </div>
                <input
                    id="city"
                    type="text"
                    className="w-full mt-1 form-ring-item"
                    {...getFieldProps('city')}
                />
                {touched.city && errors.city ? (
                    <div className='form-error'>{errors.city}</div>
                ) : null}
            </div>

            <div>
                <div className="flex sm:space-x-4 flex-col sm:flex-row space-y-4 sm:space-y-0">
                    <div className="w-full sm:w-1/3">
                        <div>
                            <div className="flex mb-1">
                                <label htmlFor="province">{t('forms.province')}</label>
                                <Tooltip label={t('helpers.province')} />
                            </div>
                            <select id="province"
                                className="w-full form-ring-item"
                                style={{ borderColor: (formik.values.province === "") ? 'rgb(209, 213, 219)' : '#181818' }}
                                {...getFieldProps('province')}>
                                <option value="">{t('forms.selectProvince')}</option>
                                {Object.keys(provinces).map(code => (
                                    <option value={code} key={code}>{provinces[code][i18n.language]}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <div>
                                {touched.province && errors.province ? (
                                    <div className='form-error'>{errors.province}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3">
                        <div>
                            <div className="flex mb-1">
                                <label htmlFor="postalcode">{t('forms.postalcode')}</label>
                                <Tooltip label={t('helpers.postalcode')} />
                            </div>
                            <input
                                id="postalcode"
                                type="text"
                                value=""
                                className="w-full form-ring-item"
                                {...getFieldProps('postalcode')}
                            />
                        </div>
                        <div className="">
                            {touched.postalcode && errors.postalcode ? (
                                <div className='form-error'>{errors.postalcode}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3">
                        <div className="flex mb-1">
                            <label htmlFor="country">{t('forms.country')}</label>
                            <Tooltip label={t('helpers.country')} />
                        </div>
                        <input
                            id="country"
                            type="text"
                            value="Canada"
                            disabled
                            className="w-full form-ring-item"
                        />
                    </div>
                </div>
            </div>

            <div className="">
                <div className="flex">
                    <label className="flex" htmlFor="studEmail">{t('forms.email')}</label>
                    <Tooltip label={t('helpers.studEmail')} />
                </div>
                <input
                    id="studEmail"
                    name="studEmail"
                    type="text"
                    className="w-full mt-1 form-ring-item"
                    placeholder={t('forms.emailDesc')}
                    {...getFieldProps('studEmail')}
                />
                {touched.studEmail && errors.studEmail ? (
                    <div className='form-error'>{errors.studEmail}</div>
                ) : null}
            </div>

            <div className="">

                <div className="flex">
                    <label className="flex" htmlFor="studEmailConfirm">{t('forms.email-confirm')}</label>
                    <Tooltip label={t('helpers.studEmailConfirm')} />
                </div>
                <input
                    id="studEmailConfirm"
                    name="studEmailConfirm"
                    type="text"
                    className="w-full mt-1 form-ring-item"
                    placeholder={t('forms.emailDesc')}
                    {...getFieldProps('studEmailConfirm')}
                />
                {touched.studEmailConfirm && errors.studEmailConfirm ? (
                    <div className='form-error'>{errors.studEmailConfirm}</div>
                ) : null}
            </div>

            <div className="flex flex-col w-full">
                <div className="flex">
                    <label className="flex" htmlFor="studPhoneAreaCode">{t('forms.phone')}</label>
                    <Tooltip label={t('helpers.phone')} />
                </div>
                <ul className="flex space-x-4">
                    <li className="w-1/3">
                        <span className="sr-only">{t('forms.phone-area-code')}</span>
                        <input
                            id="studPhoneAreaCode"
                            type="text"
                            maxLength="3"
                            className="w-full mt-1 form-ring-item"
                            placeholder={t('forms.phone-area-code')}
                            {...getFieldProps('studPhoneAreaCode')}
                        />
                    </li>
                    <li className="w-2/3">
                        <span className="sr-only">{t('forms.phone')}</span>
                        <label htmlFor="studPhone">
                            <input
                                id="studPhone"
                                type="text"
                                maxLength="7"
                                className="w-full mt-1 form-ring-item"
                                {...getFieldProps('studPhone')}
                            />
                        </label>
                    </li>
                </ul>
                {((touched.studPhoneAreaCode && errors.studPhoneAreaCode) || (touched.studPhone && errors.studPhone)) ? (
                    <div className='form-error'>{errors.studPhoneAreaCode ? errors.studPhoneAreaCode : errors.studPhone}</div>
                ) : null}
            </div>
        </>
    )
}

export default StudentContactInfo