import FileDownloadField from "./FileDownloadField";
import jsonExport from "jsonexport/dist";
import { GridShowLayout, RaGrid } from "ra-compact-ui";
import React, { useState } from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ShowButton,
    TextInput,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    downloadCSV,
    ArrayField,
} from "react-admin";

const flexSubmissionFilters = [
    <TextInput label="Search" source="q" alwaysOn />,
    <ReferenceInput
        label="University"
        source="university_id"
        reference="universities"
    >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput label="Union" source="union_id" reference="unions">
        <SelectInput optionText="name" />
    </ReferenceInput>,
];

const exporter = (records) => {
    jsonExport(
        records,
        {
            headers: [
                "id",
                "created_at",
                "Client ID",
                "Billing Division",
                "university",
                "union",
                "flexplan",
                "student_first_name",
                "student_last_name",
                "student_address",
                "student_address_2",
                "student_city",
                "student_country",
                "student_dob",
                "student_email",
                "student_number",
                "student_area_code",
                "student_phone_number",
                "student_province",
                "student_sex",
                "code",
            ],
        },
        (err, csv) => {
            downloadCSV(csv, `flex-submissions-${new Date().getTime()}`);
        }
    );
};

export const FlexSubmissionList = (props) => (
    <List {...props} filters={flexSubmissionFilters} exporter={exporter}>
        <Datagrid>
            <DateField
                source="created_at"
                options={{ year: "numeric", month: "short", day: "numeric" }}
            />
            <TextField source="university" sortBy="university_id" />
            <TextField source="union" sortBy="union_id" />
            <TextField source="flexplan" />
            <TextField source="student_number" />
            <TextField source="student_first_name" />
            <TextField source="student_last_name" />
            <ShowButton />
        </Datagrid>
    </List>
);

const FlexSubmissionTitle = ({ record }) => {
    return (
        <span>
            Flex Submission for{" "}
            {record
                ? `${record.student_first_name} ${record.student_last_name} (${record.student_number})`
                : ""}
        </span>
    );
};

export const FlexSubmissionShow = (props) => {
    // const { record } = useShowController(props)
    return (
        <Show title={<FlexSubmissionTitle />} {...props}>
            <SimpleShowLayout>
                <GridShowLayout>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <DateField
                                addLabel={true}
                                source="created_at"
                                options={{
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                }}
                            />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="university" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="union" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="flexplan" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_number" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_first_name" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_last_name" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_dob" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_sex" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_email" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={4}>
                            <TextField source="student_address" />
                            <TextField source="student_address_2" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_city" />
                            <TextField source="student_province" />
                            <TextField source="student_postalcode" />
                        </RaGrid>
                        <RaGrid item xs={4}>
                            <TextField source="student_area_code" />
                            <TextField source="student_phone_number" />
                        </RaGrid>
                    </RaGrid>
                    <RaGrid container>
                        <RaGrid item xs={12}>
                            <ArrayField source="files" label="cfs.files">
                                <Datagrid>
                                    {/* <UrlField source="site_url" /> */}
                                    <FileDownloadField source="" />
                                </Datagrid>
                            </ArrayField>
                        </RaGrid>
                    </RaGrid>
                </GridShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};
