import axios from "axios"

export default function FileDownloadField({ record }) {
    const download = async (e) => {
        e.preventDefault()
        const fileId = e.target.getAttribute('data-id')
        const fileName = e.target.getAttribute('data-filename')
        const url = `/api/files/${fileId}/${fileName}`

        const { token } = JSON.parse(sessionStorage.getItem('auth'))
        const config = {
            responseType: 'blob',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                Authorization: `Bearer ${token}`
            }
        }
        await axios.get(url, config).then(response => {
            const { data } = response
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName);
            document.body.appendChild(link)
            link.click()
            link.parentNode.removeChild(link)
        })

    }
    return (
        <a data-id={record.item.id}
            data-filename={record.item.path}
            href={`/api/files/${record.item.path}`}
            onClick={e => download(e)}>
            {record.item.path}
        </a>
    )
}

