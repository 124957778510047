import React, { useState } from 'react'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Grid,
    Paper
} from '@material-ui/core'

import {
    School as SchoolIcon,
    Apartment as ApartmentIcon,
    LocalOffer as LocalOfferIcon,
    Warning as WarningIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    Save as SaveIcon
} from '@material-ui/icons'

import {
    useTranslate,
    FileInput,
    FileField
} from 'react-admin'

import { Form, Field } from 'react-final-form'
import { httpClient } from '../../helpers'

const ImportBtn = ({ resource, label, icon }) => {
    let uploadForm = null
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const [done, setDone] = useState(false)
    const translate = useTranslate()
    const handleSubmit = async values => {
        if (values.hasOwnProperty('file') && (typeof values.file.rawFile === 'object')) {
            const formData = new FormData()
            formData.append('file', values.file.rawFile)
            formData.append('resource', resource)
            httpClient(`/api/import`, {
                method: 'POST',
                body: formData
            }).then(({ json }) => {
                if (json.success) {
                    setError(null)
                    setDone(true)
                    uploadForm.reset()
                }
            }).catch(err => {
                setError(err.error)
            })
        }
    }
    const handleClose = () => setOpen(false)
    return (
        <>
            <button type="button"
                className="w-full shaddow border border-gray-300 p-2 rounded-lg bg-gray-100 flex justify-start"
                onClick={() => { setOpen(true); setDone(false); setError(null); }}>
                <span className="mr-2">{icon}</span>
                {label}
            </button>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle disableTypography id="form-dialog-title" className="flex justify-between">
                    <span className="text-xl font-bold">
                        {`${translate('cfs.import')} ${label}`}
                    </span>
                    <button className="rounded-full p-2 hover:bg-gray-200" onClick={handleClose}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-x-lg"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M13.854 2.146a.5.5 0 010 .708l-11 11a.5.5 0 01-.708-.708l11-11a.5.5 0 01.708 0z"
                            ></path>
                            <path
                                fillRule="evenodd"
                                d="M2.146 2.146a.5.5 0 000 .708l11 11a.5.5 0 00.708-.708l-11-11a.5.5 0 00-.708 0z"
                            ></path>
                        </svg>
                    </button>
                </DialogTitle>
                <DialogContent dividers>
                    <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {
                            uploadForm = form
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Paper elevation={0} style={{
                                        padding: '12px',
                                        backgroundColor: 'rgb(229, 246, 253)'
                                    }}>
                                        <p>
                                            {translate('cfs.csv-warning')}
                                        </p>
                                        {resource === 'universities'
                                            && (<pre><strong><em>code,name</em></strong></pre>)
                                        }
                                        {resource === 'unions'
                                            && (<pre><strong><em>name,code,start,end,university code</em></strong></pre>)
                                        }
                                        {resource === 'plans'
                                            && (<pre><strong><em>name_en,name_fr,cost,description_en,<br />description_fr,union code</em></strong></pre>)
                                        }
                                    </Paper>
                                    <Field name="file">
                                        {props => (
                                            <div>
                                                <FileInput source="file" label={translate('cfs.csv-file')} accept="text/csv" multiple={false}>
                                                    <FileField source="src" title="title" />
                                                </FileInput>
                                            </div>
                                        )}
                                    </Field>
                                    {error
                                        && <Box sx={{
                                            backgroundColor: "rgb(253, 237, 237)",
                                            marginBottom: "10px",
                                            padding: "8px"
                                        }}
                                        >
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <WarningIcon fontSize="large" />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <p>{error.message}</p>
                                                    <Box sx={{ overflow: 'scroll' }}>{JSON.stringify(error.body.item)}</Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    {done
                                        && <Box sx={{
                                            backgroundColor: "rgb(237, 247, 237)",
                                            marginBottom: "10px",
                                            padding: "8px"
                                        }}
                                        >
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <CheckCircleOutlineIcon fontSize="large" />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <p>{translate('cfs.import-successful')}</p>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    }
                                    {!done
                                        && <button type="submit" className="flex items-center bg-gray-600 text-white px-4 py-2 rounded-lg">
                                            <span className="mr-2"><SaveIcon /></span>Save
                                        </button>
                                    }
                                </form>
                            )
                        }} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default function Imports() {
    const translate = useTranslate()
    return (
        <ul className="mt-5 space-y-3">
            <li className="w-full text-lg font-semibold">Imports</li>
            <li className="">
                <ImportBtn
                    resource="universities"
                    icon={<SchoolIcon />}
                    label={translate('cfs.import-univ')}
                />
            </li>
            <li className="">
                <ImportBtn
                    resource="unions"
                    icon={<ApartmentIcon />}
                    label={translate('cfs.import-unions')}
                />
            </li>
            <li className="">
                <ImportBtn
                    resource="plans"
                    icon={<LocalOfferIcon />}
                    label={translate('cfs.import-plans')}
                />
            </li>
        </ul>
    )
}
