import React from "react";
import { getT } from "../helpers";

export default function SubmitBtn({ label, isProcessing }) {
    const t = getT();

    return (
        <button
            type="submit"
            className={`form-btn flex justify-center items-center ${
                isProcessing ? "bg-gray-300" : ""
            }`}
            disabled={isProcessing}
        >
            {label ? label : t("forms.nextBtn")}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </button>
    );
}
