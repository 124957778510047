import React from 'react'

export default function Drawer({ isOpen, setIsOpen, content, flex }) {
    return (
        <aside className={`transform top-0 right-0 fixed w-full h-full
                    overflow-auto ease-in-out transition-all duration-300 z-30
                    ${isOpen ? 'translate-x-0' : 'translate-x-full'} flex justify-end ${flex ? 'bg-black' : ''} `}>

            <div className={`${flex ? 'bg-light-blue' : 'bg-gray-100'} w-5/6 flex flex-col pt-4 ${flex ? 'rounded-tl-3xl' : ''}`}>
                <div className={`${flex ? 'bg-light-blue' : ''} w-full flex-1 flex flex-col justify-start items-end pt-4`}>
                    <svg onClick={() => setIsOpen(false)} className="h-7 w-7 mr-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                    <div className="bg-gray-100 w-full" dangerouslySetInnerHTML={{ __html: content }}></div>
                </div>
            </div>

        </aside>
    )
}
