import React, { useMemo, useState } from "react";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import { Formik } from "formik";
import SubmitBtn from "../SubmitBtn";
import * as Yup from "yup";
import DocumentsUploader from "./comps/DocumentsUploader";
import { determineLangFromUri, findUnionObject } from "../../helpers";

const DocumentsFormPage = ({
    t,
    history,
    state,
    setState,
    prevNext: { prev, next },
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const FILE_SIZE = 10000 * 1024; // 10MB
    const SUPPORTED_FORMATS = [
        "application/msword",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const [docsDescription, setDocsDescription] = useState({ en: "", fr: "" });

    React.useEffect(() => {
        if (!state["student"]) {
            history.push(prev);
        }

        const unionObj = findUnionObject(
            state.selectedUnion,
            state.universities,
        );
        if (unionObj) {
            setDocsDescription({
                en: unionObj.doc_description_en,
                fr: unionObj.doc_description_fr,
            });
        }

        setLoaded(true);
    }, []);

    const validationSchema = Yup.object().shape({
        docs: Yup.array().of(
            Yup.mixed()
                .test(
                    "fileSize",
                    t("errors.document-file-size"),
                    (value) => value.file.size <= FILE_SIZE,
                )
                .test("fileType", t("errors.document-file-type"), (value) =>
                    SUPPORTED_FORMATS.includes(value.file.type),
                ),
        ),
    });
    const defaultValues = state["docs"]
        ? { docs: state["docs"] }
        : {
              docs: [],
          };

    const submitForm = (values) => {
        const data = { docs: values.docs };
        setState({ ...state, ...data });
        history.push(next);
    };

    return (
        <Layout>
            {loaded && (
                <div className="my-4 mx-6">
                    <h1 className="">{t("flexplan-documents-title")}</h1>
                    <p className="text-sm text-gray-600 mt-2">
                        {docsDescription[determineLangFromUri()]}
                    </p>
                    <p className="text-sm text-gray-600 mt-2 font-bold">
                        {t("flexplan-documents-maxfilesize")}
                    </p>

                    <Formik
                        initialValues={defaultValues}
                        validationSchema={validationSchema}
                        onSubmit={submitForm}
                    >
                        {({ handleSubmit, values, setValues, errors }) => (
                            <form
                                onSubmit={handleSubmit}
                                className="my-4 mx-6 space-y-4"
                            >
                                <DocumentsUploader
                                    t={t}
                                    values={values}
                                    setValues={setValues}
                                    errors={errors}
                                />

                                <div className="mt-4">
                                    <SubmitBtn />
                                </div>
                            </form>
                        )}
                    </Formik>
                </div>
            )}
        </Layout>
    );
};

export default withHelpers(DocumentsFormPage);
