import React, { useState, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import {
    fetchUnionLogo,
    findUniversityOfUnion,
    i18nUri,
    useQuery,
    determineLangFromUri,
    getUnionPlans,
} from "../../helpers";
import SubmitBtn from "../SubmitBtn";
import axios from "axios";
import Tooltip from "./comps/Tooltip";
// import InstitutionSelector from './comps/InstitutionSelector'

const SchoolFormPage = ({
    t,
    history,
    state,
    setState,
    prevNext: { prev, next },
}) => {
    // const unionPlans = getUnionPlans();
    const query = useQuery();
    const institutionUri = i18nUri("/institution");
    const unionCode = query.get("union") || undefined;
    const [defaultUniversity, setDefaultUniversity] = useState();
    const [defaultUnion, setDefaultUnion] = useState(unionCode);
    const [studentTypes, setStudentTypes] = useState([]);
    const [startDates, setStartDates] = useState([]);
    const [degreeTypes, setDegreeTypes] = useState([]);
    const [hasAvailablePlans, setHasAvailablePlans] = useState(true);

    const getPlans = (school) => {
        let plans = [];
        const univ = state["universities"].find(
            (u) => u.code === school.university,
        );

        const union = univ.unions.find((u) => u.code === school.union);
        if (typeof union.plans === "object" && union.plans.length > 0) {
            plans = union.plans;
        } else plans = univ.plans;

        return plans.filter((plan) => {
            if (
                plan.valid_student_types == null ||
                !plan.valid_student_types.includes(parseInt(school.studentType))
            )
                return false;
            else if (
                plan.valid_degree_types == null ||
                !plan.valid_degree_types.includes(parseInt(school.degreeType))
            )
                return false;
            else
                return !(
                    plan.valid_start_dates == null ||
                    !plan.valid_start_dates.includes(
                        parseInt(school.academicYearStartDate),
                    )
                );
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: state["school"]
            ? state["school"]
            : // { "university": "IVSOFT", "union": "50", "enrollement": "fulltime", "studentType": "domestic", "degreeType": "undergraduate", "academicYearStartDate": "fall" },
              {
                  university: defaultUniversity,
                  union: defaultUnion,
                  enrollement: "",
                  studentType: 0,
                  degreeType: 0,
                  academicYearStartDate: 0,
              },
        validationSchema: Yup.object().shape({
            university: Yup.string().required(t("errors.university")),
            union: Yup.string().required(t("errors.union")),
            enrollement: Yup.string().required(t("errors.enrollement")),
            studentType: Yup.number()
                .moreThan(0, t("errors.studentType"))
                .required(t("errors.studentType")),
            degreeType: Yup.number()
                .moreThan(0, t("errors.degreeType"))
                .required(t("errors.degreeType")),
            academicYearStartDate: Yup.number()
                .moreThan(0, t("errors.academicYearStartDate"))
                .required(t("errors.academicYearStartDate")),
        }),
        onSubmit: (values) => {
            const eligiblePlans = getPlans(values);
            // filter against the selected
            setHasAvailablePlans(eligiblePlans.length > 0);
            // no plans available for the selected options
            if (eligiblePlans.length === 0) {
                return false;
            }

            // find billing disabled
            let billingDisabled = studentTypes.find(
                (item) => item.id == formik.values.studentType,
            ).billing_disabled;

            const data = { school: values, billing_disabled: billingDisabled };
            setState({ ...state, ...data });
            history.push(next);
        },
    });
    const getDegreeTypeNameForLang = (degreeType) => {
        const lang = determineLangFromUri();
        return lang === "fr" ? degreeType.name_fr : degreeType.name_en;
    };
    const getStudentTypeNameForLang = (studentType) => {
        const lang = determineLangFromUri();
        return lang === "fr" ? studentType.name_fr : studentType.name_en;
    };
    const getStartDatesNameForLang = (startDate) => {
        const lang = determineLangFromUri();
        return lang === "fr" ? startDate.name_fr : startDate.name_en;
    };
    useEffect(() => {
        if (unionCode === undefined) {
            history.push(institutionUri);
        }

        if (state && unionCode) {
            axios
                .get(`/api/data/studenttypes/${unionCode}`)
                .then(({ data }) => {
                    setStudentTypes(data);
                });

            axios.get(`/api/data/degreetypes/${unionCode}`).then(({ data }) => {
                setDegreeTypes(data);
            });

            axios.get(`/api/data/startdates/${unionCode}`).then(({ data }) => {
                setStartDates(data);
            });
        }
    }, []);
    useEffect(() => {
        if (state && state.universities) {
            const university = findUniversityOfUnion(
                state.universities,
                unionCode,
            );
            if (university && university.hasOwnProperty("code")) {
                setDefaultUniversity(university.code);
            }
        }
    }, [state]);

    return (
        <Layout>
            <div className="my-4 mx-6">
                <h1 className="">{t("program-info-title")}</h1>
                <p className="text-sm text-gray-600 mt-2">
                    {t("program-info-desc")}
                </p>
                <form onSubmit={formik.handleSubmit} className="mt-8">
                    <div className="">
                        {/* <InstitutionSelector
                            formik={formik}
                            universities={universities}
                            defaultUniversity={defaultUniversity}
                            setDefaultUniversity={setDefaultUniversity}
                            defaultUnion={defaultUnion}
                        /> */}
                        <div className="form-item-selected">
                            <label htmlFor="enrollement">
                                {t("forms.typeEnrollement")}
                            </label>
                            <Tooltip label={t("helpers.enrollement")} />
                            <br />
                            <ul>
                                <li>
                                    <label>
                                        <input
                                            name="enrollement"
                                            type="radio"
                                            value="fulltime"
                                            checked={
                                                formik.values.enrollement ===
                                                "fulltime"
                                            }
                                            onChange={formik.handleChange}
                                            className="text-dark-green"
                                        />
                                        <span className="ml-2">
                                            {t("forms.enrollementFull")}
                                        </span>
                                    </label>
                                </li>
                                <li>
                                    <label>
                                        <input
                                            name="enrollement"
                                            type="radio"
                                            value="parttime"
                                            checked={
                                                formik.values.enrollement ===
                                                "parttime"
                                            }
                                            onChange={formik.handleChange}
                                            className="text-dark-green"
                                        />
                                        <span className="ml-2">
                                            {t("forms.enrollementPart")}
                                        </span>
                                    </label>
                                </li>
                            </ul>
                            {formik.touched.enrollement &&
                            formik.errors.enrollement ? (
                                <div className="form-error">
                                    {formik.errors.enrollement}
                                </div>
                            ) : null}
                        </div>
                        &nbsp;
                        <div
                            className={
                                formik.values.studentType !== null
                                    ? "form-item-selected"
                                    : "form-item"
                            }
                        >
                            <label>{t("forms.typeStudent")}</label>
                            <Tooltip label={t("helpers.studentType")} />
                            <br />
                            <ul>
                                {studentTypes.map((studentType, index) => {
                                    return (
                                        <li key={index}>
                                            <label>
                                                <input
                                                    name="studentType"
                                                    type="radio"
                                                    value={studentType.id}
                                                    checked={
                                                        Number(
                                                            formik.values
                                                                .studentType,
                                                        ) === studentType.id
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    className="text-dark-green"
                                                />
                                                <span className="ml-2">
                                                    {getStudentTypeNameForLang(
                                                        studentType,
                                                    )}
                                                </span>
                                            </label>
                                        </li>
                                    );
                                })}
                            </ul>
                            {formik.touched.studentType &&
                            formik.errors.studentType ? (
                                <div className="form-error">
                                    {formik.errors.studentType}
                                </div>
                            ) : null}
                        </div>
                        &nbsp;
                        <div
                            className={
                                formik.values.degreeType !== null
                                    ? "form-item-selected"
                                    : "form-item"
                            }
                        >
                            <label>{t("forms.typeDegree")}</label>
                            <Tooltip label={t("helpers.degreeType")} />
                            <br />
                            <ul>
                                {degreeTypes.map((degreeType, index) => {
                                    return (
                                        <li key={index}>
                                            <label>
                                                <input
                                                    name="degreeType"
                                                    type="radio"
                                                    value={degreeType.id}
                                                    checked={
                                                        Number(
                                                            formik.values
                                                                .degreeType,
                                                        ) === degreeType.id
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    className="text-dark-green"
                                                />
                                                <span className="ml-2">
                                                    {getDegreeTypeNameForLang(
                                                        degreeType,
                                                    )}
                                                </span>
                                            </label>
                                        </li>
                                    );
                                })}
                            </ul>
                            {formik.touched.degreeType &&
                            formik.errors.degreeType ? (
                                <div className="form-error">
                                    {formik.errors.degreeType}
                                </div>
                            ) : null}
                        </div>
                        &nbsp;
                        <div
                            className={
                                formik.values.academicYearStartDate !== null
                                    ? "form-item-selected"
                                    : "form-item"
                            }
                        >
                            <label>{t("forms.acYearStartD")}</label>
                            <Tooltip
                                label={t("helpers.academicYearStartDate")}
                            />
                            <br />
                            <ul>
                                {startDates.map((startDate, index) => {
                                    return (
                                        <li key={index}>
                                            <label>
                                                <input
                                                    name="academicYearStartDate"
                                                    type="radio"
                                                    value={startDate.id}
                                                    checked={
                                                        Number(
                                                            formik.values
                                                                .academicYearStartDate,
                                                        ) === startDate.id
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    className="text-dark-green"
                                                />
                                                <span className="ml-2">
                                                    {getStartDatesNameForLang(
                                                        startDate,
                                                    )}
                                                </span>
                                            </label>
                                        </li>
                                    );
                                })}
                            </ul>
                            {formik.touched.academicYearStartDate &&
                            formik.errors.academicYearStartDate ? (
                                <div className="form-error">
                                    {formik.errors.academicYearStartDate}
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {!hasAvailablePlans && (
                        <div className="mt-4">
                            <p className="text-red-700">
                                {t("forms.ineligible-selection")}
                            </p>
                        </div>
                    )}

                    <div className="mt-4">
                        <SubmitBtn />
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default withHelpers(SchoolFormPage);
