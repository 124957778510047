import React, { Component, useState, useEffect } from "react";
import i18next from "i18next";

export default class PaymentModal extends Component {
    constructor(props) {
        super(props);

        this.state = { showModal: this.props.showModal };
    }

    setShowModal(show) {
        this.setState({ showModal: show });
    }

    onSubmission() {
        this.props.onNextButton();
        this.setShowModal(false);
    }

    render() {
        return (
            <>
                {this.props.showModal ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    {/*body*/}
                                    <div className="relative p-6 flex-auto">
                                        <p className="my-4 text-slate-500 text-lg leading-relaxed">
                                            {i18next.t(
                                                "forms.payment-agreement",
                                            )}
                                        </p>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="form-btn flex justify-center items-center"
                                            type="submit"
                                            onClick={() => this.onSubmission()}
                                        >
                                            {i18next.t("submit-application")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
            </>
        );
    }
}
