import React from 'react'
import {
    List,
    Create,
    Edit,
    Datagrid,
    TextField,
    TextInput,
    PasswordInput,
    EditButton,
    SimpleForm,
    required,
    minLength,
    email
} from 'react-admin'
import { CustomCreateToolbar, CustomEditToolbar } from './CustomToolbars'

export const StudentTypeList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name_en" />
            <TextField source="name_fr" />
            <EditButton />
        </Datagrid>
    </List>
)

export const StudentTypeCreate = props => {
    return (
        <Create title="Add Student Type" {...props}>
            <SimpleForm toolbar={<CustomCreateToolbar />}>
                <TextInput source="name_en" />
                <TextInput source="name_fr" />
            </SimpleForm>
        </Create>
    )
}

const StudentTypeTitle = ({ record }) => {
    return <span>{record ? record.name_en : ''}</span>;
};

export const StudentTypeEdit = props => {
    return (
        <Edit title={<StudentTypeTitle />} {...props}>
            <SimpleForm toolbar={<CustomEditToolbar alwaysEnableSaveButton />}>
                <TextInput disabled source="id" />
                <TextInput source="name_en" />
                <TextInput source="name_fr" />
            </SimpleForm>
        </Edit>
    )
}
