import React from 'react'
import withHelpers from '../../hooks/withHelpers'
import Layout from '../Layout'
import { Formik } from 'formik'
import * as Yup from 'yup'
import PersonCommonInfo from './comps/PersonCommonInfo'
import { studentValidationRules } from './studentFormPage'
import SubmitBtn from '../SubmitBtn'
import StudentContactInfo from './comps/StudentContactInfo'
import Tooltip from './comps/Tooltip'

const StudentFlexPage = ({ t, history, state, setState, prevNext: { prev, next } }) => {
    React.useEffect(() => {
        if (!state.hasOwnProperty('selectedUnion')) {
            history.push(prev)
        }
    }, [])

    const defaultValues = state['student'] ? state['student'] :
        // { "studId": "1122", "firstName": "Marie", "lastName": "UWANYIRIGIRA", "dobMM": "1", "dobDD": "5", "dobYY": "1984", "sex": "female", "address1": "130 Ch. Bates", "address2": "app 309", "city": "Mont-Royal", "province": "QC", "country": "Canada", "studEmail": "kassim@machioudi.com", "studEmailConfirm": "kassim@machioudi.com", "studPhoneAreaCode": "514", "studPhone": "8357156" }
        {
            studId: '',
            firstName: '',
            lastName: '',
            dobMM: '',
            dobDD: '',
            dobYY: '',
            sex: '',
            address1: '',
            address2: '',
            city: '',
            province: '',
            postalcode: '',
            country: 'Canada',
            studEmail: '',
            studEmailConfirm: '',
            studPhoneAreaCode: "",
            studPhone: ""
        }

    const validationRules = studentValidationRules()
    delete validationRules.address1
    delete validationRules.address2
    delete validationRules.city
    delete validationRules.province
    delete validationRules.country
    delete validationRules.studPhone
    delete validationRules.studPhoneAreaCode

    const checkPreviousSubmission = async (studentId) => {
        return new Promise(async (resolve, reject) => {
            const params = { studentId, union: state.selectedUnion, options: 'flex' }
            await axios.get('/api/verifySubmission', { params })
                .then(({ data }) => resolve(!data.hasSubmission))
                .catch(() => resolve(false))
        })
    }

    const validationSchema = Yup.object().shape({
        ...validationRules,
        studId: Yup.number().typeError(t('errors.studId-num')).required(t('errors.studId-req'))
            .test('checkPreviousSubmission', t('errors.check-previous-submission'), checkPreviousSubmission),
        postalcode: Yup.string().matches(/^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/i, t('errors.postalcode-invalid')),
        studPhoneAreaCode: Yup.number().typeError(t('errors.areaCode-num')).test('len', t('errors.area-code-3digits'), val => (val !== undefined) ? val.toString().length === 3 : true),
        studPhone: Yup.number().typeError(t('errors.phone-num')).test('len', t('errors.phone-number-7digits'), val => (val !== undefined) ? val.toString().length === 7 : true),
    })

    const submitForm = values => {
        const data = { student: values }
        setState({ ...state, ...data })
        history.push(next)
    }

    return (
        <Layout>
            <div className="my-4 mx-6">
                <h1 className="">{t('student-options-title')}</h1>
                <p className="text-sm text-gray-600 mt-2">{t('student-options-desc')}</p>
            </div>
            <Formik
                initialValues={defaultValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submitForm}
            >

                {({ handleSubmit, getFieldProps, touched, errors }) => (
                    <form onSubmit={handleSubmit} className="my-4 mx-6 space-y-4">

                        <div className="form-item space-y-4">
                            <div className="flex flex-col">
                                <div className="flex">
                                    <label className="flex" htmlFor="studId">{t('forms.lastID')}</label>
                                    <Tooltip label={t('helpers.studentID')} />
                                </div>
                                <input
                                    id="studId"
                                    type="text"
                                    className="mt-1 form-ring-item"
                                    placeholder={t('forms.EnterStudentID')}
                                    {...getFieldProps('studId')}
                                />
                                {touched.studId && errors.studId ? (
                                    <div className='form-error'>{errors.studId}</div>
                                ) : null}
                            </div>

                            <PersonCommonInfo />
                        </div>

                        <div className="form-item space-y-4">
                            <StudentContactInfo />
                        </div>
                        <div className="mt-4">
                            <SubmitBtn />
                        </div>
                    </form>
                )}

            </Formik>
        </Layout>
    )
}

export default withHelpers(StudentFlexPage)