import React from "react";
import ReactDOM from "react-dom";
import { Admin, Resource } from "react-admin";
import authProvider from "../authProvider";
import {
    UniversityCreate,
    UniversityEdit,
    UniversityList,
} from "./ra/universities";
import { UnionCreate, UnionEdit, UnionList, UnionShow } from "./ra/unions";
import { SubmissionList, SubmissionShow } from "./ra/submissions";
import { FlexSubmissionList, FlexSubmissionShow } from "./ra/flexSubmissions";
import {
    StudentTypeCreate,
    StudentTypeShow,
    StudentTypeList,
    StudentTypeEdit,
} from "./ra/studenttypes";
import SchoolIcon from "@material-ui/icons/School";
import ApartmentIcon from "@material-ui/icons/Apartment";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import Dashboard from "./ra/Dashboard";
import { UserList, UserCreate, UserEdit } from "./ra/users";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import frenchMessages from "ra-language-french";
import domainMessages from "./ra/i18n";
import cfsDataProvider from "./cfsDataProvider";

const messages = {
    fr: { ...frenchMessages, ...domainMessages.fr },
    en: { ...englishMessages, ...domainMessages.en },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);

function AdminPanel() {
    const role = sessionStorage.getItem("role");
    return (
        <Admin
            dashboard={Dashboard}
            dataProvider={cfsDataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
        >
            {(permissions) => [
                permissions === "admin" ? (
                    <Resource
                        name="users"
                        list={UserList}
                        create={UserCreate}
                        edit={UserEdit}
                        icon={PeopleIcon}
                    />
                ) : null,
                ["admin", "healthPlanAdmin"].includes(permissions) ? (
                    <Resource
                        name="unions"
                        show={UnionShow}
                        list={UnionList}
                        edit={UnionEdit}
                        create={UnionCreate}
                        icon={ApartmentIcon}
                    />
                ) : null,
                ["admin", "healthPlanAdmin"].includes(permissions) ? (
                    <Resource
                        name="universities"
                        list={
                            permissions === "admin" ? UniversityList : undefined
                        }
                        edit={UniversityEdit}
                        create={UniversityCreate}
                        icon={SchoolIcon}
                    />
                ) : null,
                ["admin", "healthPlanAdmin"].includes(permissions) ? (
                    <Resource
                        name="submissions"
                        list={SubmissionList}
                        show={SubmissionShow}
                    />
                ) : null,
                ["admin", "healthPlanAdmin"].includes(permissions) ? (
                    <Resource
                        name="flexSubmissions"
                        list={FlexSubmissionList}
                        show={FlexSubmissionShow}
                    />
                ) : null,
                <Resource name="plans" />,
            ]}
        </Admin>
    );
}

export default AdminPanel;

if (document.getElementById("admin")) {
    ReactDOM.render(<AdminPanel />, document.getElementById("admin"));
}
