import React from 'react'
import Button from '@material-ui/core/Button'
import { useLocale, useSetLocale, useTranslate } from 'react-admin'

const LanguageSwitcher = () => {
    const locale = useLocale()
    const translate = useTranslate()
    const setLocale = useSetLocale()
    return (
        <ul className="space-y-3">
            <li className="w-full text-lg font-semibold">{translate('cfs.lang-switch')}</li>
            <li className="">
                <button
                    className={`w-full rounded-md border border-gray-100 shadow px-2 py-1 ${locale === 'en' ? 'font-semibold text-blue-500' : ''}`}
                    type="button" onClick={() => setLocale('en')}>
                    {translate('cfs.english')}
                </button>
            </li>
            <li className="">
                <button
                    className={`w-full rounded-md border border-gray-100 shadow px-2 py-1 ${locale === 'fr' ? 'font-semibold text-blue-500' : ''}`}
                    type="button" onClick={() => setLocale('fr')}>
                    {translate('cfs.french')}
                </button>
            </li>
        </ul>
        // <div>
        //     <p>{translate('cfs.lang-switch')}</p>
        //     <Button color={`${locale === 'en' ? 'default' : 'secondary'}`} onClick={() => setLocale('en')}>{translate('cfs.english')}</Button>
        //     <Button color={`${locale === 'fr' ? 'default' : 'secondary'}`} onClick={() => setLocale('fr')}>{translate('cfs.french')}</Button>
        // </div>
    )
}

export default LanguageSwitcher;