import axios from 'axios'
import React, { useState } from 'react'
import { determineLangFromUri, fetchUnionLogo, useQuery } from '../../helpers'
import withHelpers from '../../hooks/withHelpers'
import Layout from '../Layout'
import { useFormik } from "formik"
import SubmitBtn from '../SubmitBtn'
import * as Yup from 'yup'
import Drawer from './comps/Drawer'
import TogglePanel from './comps/TogglePanel'

const PlansFlexPage = ({ t, history, state, setState, prevNext: { prev, next } }) => {
    const query = useQuery()
    const [loading, setLoading] = React.useState(true)
    const [flexplan, setFlexplan] = React.useState()
    const [drawerIsOpened, setDrawerIsOpened] = React.useState(false)
    const [content, setContent] = React.useState(null)
    const [flexPlanOptionPanelVisibility, setFlexPlanOptionPanelVisibility] = useState({})
    const buildOptionContent = option => {
        const bottom = []
        for (let category of flexplan.categories) {
            const item = `<div class="my-3 px-6">
                <h3 class="text-lg text-semibold py-1">${getValueForLang(category)}</h3>
                <p class="text-sm text-gray-600">${descriptionForLang(flexplan.descriptions[option.id][category.id])}</p>
            </div>`
            bottom.push(item)

        }
        return bottom.join('')
    }
    const showCriteriaPanel = (e, option) => {
        e.preventDefault()

        const ctt = ` <div class="">
            <div class="bg-light-blue border-b px-6">
                <h1 class="text-xl">Plan Details</h1>
                <h2 class="text-4xl font-semibold mt-2 mb-4">${getValueForLang(option)}</h2>
            </div>
            ${buildOptionContent(option)}
        </div>
        `
        setContent(ctt)
        setDrawerIsOpened(true)
    }
    const toggleOptionPlanPanel = (e, optionPlanId) => {
        e.preventDefault()
        if (flexPlanOptionPanelVisibility[optionPlanId]) {
            const newState = { ...flexPlanOptionPanelVisibility }
            newState[optionPlanId] = false
            setFlexPlanOptionPanelVisibility(newState)
        }
        else {
            const newState = { ...flexPlanOptionPanelVisibility }
            newState[optionPlanId] = true
            setFlexPlanOptionPanelVisibility(newState)
        }
    }
    React.useEffect(async () => {
        const unionCode = query.get('union') || undefined

        if ((unionCode === undefined) && state && !state.hasOwnProperty('selectedUnion')) {
            history.push(prev)
        }

        // load flex plan
        const code = unionCode || state.selectedUnion
        if (code) {
            if (state.hasOwnProperty('flexPlan')) {
                setFlexplan(state.flexPlan)
                setLoading(false)
            }
            else {
                await axios.get(`/api/data/flexplan/${code}`)
                    .then(({ data }) => {
                        setFlexplan(data)
                        setState(previous => ({ ...previous, flexPlan: data }))
                        setLoading(false)
                    })
                    .catch(err => {
                        // go to institution page in case of 404
                        history.push('/institution')
                    })
            }
        }
    }, [state.selectedUnion])
    const getValueForLang = option => {
        const lang = determineLangFromUri()
        return option['value'][lang]
    }
    const highlight = option => {
        const lang = determineLangFromUri()
        return option['highlight'][lang]
    }
    const descriptionForLang = entry => {
        const lang = determineLangFromUri()
        return entry[lang]
    }
    // if user choses plan and then changes institution, old plan will still be selected
    // so make sure selected plan id is for current flex plan
    const checkSelectedOptionId = flexPlanOptionId => {
        return new Promise((resolve, reject) => {
            const { flexPlan } = state
            const option = flexPlan.options.find(opt => opt.id === flexPlanOptionId)
            if (option === undefined) {
                resolve(false)
            }
            else {
                resolve(true)
            }
        })

    }
    const formik = useFormik({
        initialValues: state['plans'] ? state['plans'] :
            // { "options": "", "plans": "health-dental" },
            {
                'plan': ''
            },
        validationSchema: Yup.object().shape({
            'plan': Yup.string().required(t('errors.plan-option-required'))
                .test('checkSelectedOptionId', t('errors.plan-option-required'), checkSelectedOptionId),
        }),
        onSubmit: values => {
            const data = { plans: values }
            setState({ ...state, ...data })
            history.push(next)
        }
    })
    return (
        <Layout>
            <div className="my-4 mx-6">
                <h1 className="">{t('flexplan')}</h1>
                <p className="">{t('flexplan-description')}</p>
                <hr className="my-8" />

                {loading && <div className="flex justify-center">
                    <svg className="text-light-green animate-spin -ml-1 mr-3 h-24 w-24" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" className="opacity-25" ></circle>
                        <path
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            className="opacity-75"
                        ></path>
                    </svg>
                </div>}

                <form onSubmit={formik.handleSubmit} className="mt-4">
                    <ul className="space-y-4 mt-2 mb-8">
                        {flexplan && flexplan.hasOwnProperty('options') && (
                            flexplan.options.length
                                ? flexplan.options.map(option => (
                                    <li key={option.id} className={`form-item ${formik.values.plan === option.id ? 'border-dark-green' : 'bg-white'}`}>
                                        <label className="flex justify-between">
                                            <div className="flex w-full">
                                                <input
                                                    name="plan"
                                                    type="radio"
                                                    value={option.id}
                                                    checked={formik.values.plan == option.id}
                                                    onChange={formik.handleChange}
                                                    className="mt-1 text-dark-green"
                                                />
                                                <div className="w-full">
                                                    <span className="ml-2">{getValueForLang(option)}</span>
                                                    <br />
                                                    <span className="text-sm">{t('key-highlights')}</span>
                                                    <p className="text-sm text-gray-500">{highlight(option)}</p>
                                                    <a onClick={e => showCriteriaPanel(e, option)}
                                                        href="#"
                                                        className="sm:hidden underline text-light-green text-sm">{t('plan-details')}</a>
                                                    <div className="hidden sm:block float-right">
                                                        <a onClick={e => toggleOptionPlanPanel(e, option.id)}
                                                            href="#"
                                                            className={`flex underline text-light-green text-sm ${flexPlanOptionPanelVisibility[option.id] ? 'no-underline' : ''}`}
                                                        >
                                                            <span className={`${flexPlanOptionPanelVisibility[option.id] ? 'font-bold' : ''}`}>{t('learn-more')}</span>
                                                            {flexPlanOptionPanelVisibility[option.id]
                                                                ? <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="26"
                                                                    height="26"
                                                                    viewBox="0 0 26 26"
                                                                    className="h-6 w-6 ml-2"
                                                                >
                                                                    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                                                        <g transform="translate(-1019 -472)">
                                                                            <g transform="translate(376 344)">
                                                                                <g transform="translate(558 129) translate(86)">
                                                                                    <circle
                                                                                        cx="12"
                                                                                        cy="12"
                                                                                        r="12"
                                                                                        fill="#0A8A17"
                                                                                        stroke="#0A8A17"
                                                                                    ></circle>
                                                                                    <path
                                                                                        stroke="#FFF"
                                                                                        strokeWidth="2"
                                                                                        d="M17.624 11.714L6.376 11.714"
                                                                                    ></path>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                                : <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="26"
                                                                    height="26"
                                                                    viewBox="0 0 26 26"
                                                                    className="h-6 w-6 ml-2"
                                                                >
                                                                    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                                                        <g stroke="#0A8A17" transform="translate(-1019 -1296)">
                                                                            <g transform="translate(376 1168)">
                                                                                <g transform="translate(560 129) translate(84)">
                                                                                    <circle cx="12" cy="12" r="12"></circle>
                                                                                    <g strokeWidth="2" transform="translate(6.376 6.09)">
                                                                                        <path d="M5.624 0L5.624 11.247"></path>
                                                                                        <path d="M11.247 5.624L0 5.624"></path>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            }
                                                        </a>
                                                    </div>
                                                    <div className="clear-both" />
                                                    <TogglePanel visible={flexPlanOptionPanelVisibility[option.id]} content={buildOptionContent(option)} />
                                                </div>
                                            </div>
                                            {/* <span className="font-bold">${plans[id].cost}</span> */}
                                        </label>
                                    </li>
                                ))
                                : <div className="w-full flex justify-center items-center h-40 italic">
                                    <span className="text-sm text-center text-gray-600">{t('flexplan-not-available')}</span>
                                </div>
                        )}
                    </ul>

                    {formik.touched.plan && formik.errors.plan ? (
                        <div className='form-error'>{formik.errors.plan}</div>
                    ) : null}

                    <div className="mt-4">
                        <SubmitBtn />
                    </div>

                </form>
            </div>

            <Drawer
                isOpen={drawerIsOpened}
                setIsOpen={setDrawerIsOpened}
                content={content}
                flex={true}
            />
        </Layout>
    )
}

export default withHelpers(PlansFlexPage)