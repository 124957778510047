import { findUnionObject, i18nUri } from "../../helpers";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import SubmitBtn from "../SubmitBtn";
import Person from "./comps/Person";
import SinglePersonEdit from "./comps/SinglePersonEdit";
import { Formik, Form, Field, FieldArray } from "formik";
import React, { useState, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

const ExtraPersonsFormPage = ({
    t,
    history,
    state,
    i18n,
    setState,
    prevNext: { prev, next },
}) => {
    const [loaded, setLoaded] = useState(false);
    const [extraPersonCheckFailed, setExtraPersonCheckFailed] = useState(false);
    const [partnerCheckFailed, setPartnerCheckFailed] = useState(false);
    const [multiplePartnerCheck, setMultiplePartnerCheck] = useState(false);
    useEffect(() => {
        if (!state["person"]) {
            history.push(prev);
        }
        setLoaded(true);
    }, []);

    const partnerAlreadyExists = useMemo(() => {
        const partnerTypes = ["spouse", "commonlaw"];
        return (
            partnerTypes.includes(state.person?.relation) ||
            state.persons?.some((p) => partnerTypes.includes(p.relation))
        );
    }, [state.person, state.persons]);

    const validationSchema = Yup.object().shape({
        persons: Yup.array().of(
            Yup.object().shape({
                relation: Yup.string().required(t("errors.required-field")),
                firstName: Yup.string().required(t("errors.required-field")),
                lastName: Yup.string().required(t("errors.required-field")),
                dobMM: Yup.number().required(t("errors.required-field")),
                dobDD: Yup.number()
                    .typeError(t("errors.invalid-number"))
                    .moreThan(0, t("errors.invalid-number"))
                    .lessThan(32, t("errors.invalid-number"))
                    .required(t("errors.required-field")),
                dobYY: Yup.number()
                    .typeError(t("errors.invalid-number"))
                    .moreThan(1919, t("errors.invalid-number"))
                    .lessThan(
                        new Date().getFullYear() + 1,
                        t("errors.invalid-number")
                    )
                    .required(t("errors.required-field")),
                sex: Yup.string().required(t("errors.required-field")),
            })
        ),
    });
    const countOccurrences = (item, relations) => {
        let count = 0;
        relations.forEach((r) => {
            if (r === item) count++;
        });
        return count;
    };
    const documentsUrl = i18nUri("/form/documents");
    const submitForm = (values) => {
        const relations = [
            state.person.relation,
            ...values.persons.map((p) => p.relation),
        ];

        // check extra person
        if (values.persons.length === 0) {
            setExtraPersonCheckFailed(true);
            setMultiplePartnerCheck(false);
            setPartnerCheckFailed(false);
            return;
        }

        if (countOccurrences("commonlaw", relations) > 1) {
            setMultiplePartnerCheck(true);
            setPartnerCheckFailed(false);
            return;
        } else if (countOccurrences("spouse", relations) > 1) {
            setMultiplePartnerCheck(true);
            setPartnerCheckFailed(false);
            return;
        } else if (
            relations.includes("commonlaw") &&
            relations.includes("spouse")
        ) {
            setPartnerCheckFailed(true);
            setMultiplePartnerCheck(false);
            return;
        } else {
            setPartnerCheckFailed(false);
        }

        const data = { extras: values };
        setState({ ...state, ...data });
        const unionObj = findUnionObject(
            state.selectedUnion,
            state.universities
        );
        if (unionObj.flexDocUpload === 1) {
            history.push(documentsUrl);
        } else {
            history.push(next);
        }
    };
    const defaultPerson =
        // { "id": "5f30e4b9-febd-4c9f-9e1f-3c303f619dfa", "relation": "child", "firstName": "Faria", "lastName": "MACHIOUDI", "dobMM": "9", "dobDD": "15", "dobYY": "2014", "sex": "female" }
        {
            id: uuidv4(),
            relation: "",
            firstName: "",
            lastName: "",
            dobMM: "",
            dobDD: "",
            dobYY: "",
            sex: "",
        };
    const defaultValues = {
        persons: [{ ...defaultPerson }],
    };
    const addPerson = (values) => {
        const { persons } = values;
        persons.push({ ...defaultPerson, id: uuidv4() });
        return { ...values, persons };
    };
    const removePerson = (values, id) => {
        return {
            ...values,
            persons: values.persons.filter((person) => person.id !== id),
        };
    };
    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t("extra-persons-title")}</h1>
                <p className="text-sm text-gray-600 mt-2">
                    {t("extra-persons-desc")}
                </p>
            </div>

            {loaded && <SinglePersonEdit data={state["person"]} />}

            <Formik
                initialValues={
                    state["extras"] ? state["extras"] : defaultValues
                }
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submitForm}
            >
                {({ handleSubmit, values, errors, setValues }) => (
                    <form
                        onSubmit={handleSubmit}
                        className="my-4 mx-6 space-y-4"
                    >
                        <FieldArray
                            name="persons"
                            render={(arrayHelpers) => (
                                <div>
                                    {values.persons &&
                                    values.persons.length > 0 ? (
                                        values.persons.map((person, index) => (
                                            <Person
                                                hasPartner={
                                                    partnerAlreadyExists
                                                }
                                                key={person.id}
                                                pos={index}
                                                canClose={!!index}
                                                discard={() =>
                                                    setValues(
                                                        removePerson(
                                                            values,
                                                            person.id
                                                        )
                                                    )
                                                }
                                            />
                                        ))
                                    ) : (
                                        <div />
                                    )}

                                    <button
                                        onClick={() =>
                                            setValues(addPerson(values))
                                        }
                                        type="button"
                                        className="text-dark-green font-bold w-full flex my-12 p-4 justify-center border border-dashed border-dark-green rounded-xl"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-7 w-7 mr-6"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        {t("forms.addPerson")}
                                    </button>
                                </div>
                            )}
                        />

                        {extraPersonCheckFailed ? (
                            <div className="bg-yellow-50 border border-red-200 p-2 text-gray-600 text-sm">
                                {t("errors.extra-person-req")}
                            </div>
                        ) : null}

                        {partnerCheckFailed ? (
                            <div className="bg-yellow-50 border border-red-200 p-2 text-gray-600 text-sm">
                                {t("errors.partner-check")}
                            </div>
                        ) : null}

                        {multiplePartnerCheck ? (
                            <div className="bg-yellow-50 border border-red-200 p-2 text-gray-600 text-sm">
                                {t("errors.multiple-partner-check")}
                            </div>
                        ) : null}

                        <div className="mt-4">
                            <SubmitBtn />
                        </div>
                    </form>
                )}
            </Formik>
        </Layout>
    );
};

export default withHelpers(ExtraPersonsFormPage);
