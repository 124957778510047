import React, { useRef } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/material.css'

export default function Tooltip({ label }) {
    return (
        <Tippy content={label} theme="material">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                className="h-6 w-6 focus:outline-none inline float-right ml-2 text-gray-400"
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g fill="#0A8A17" transform="translate(-130 -475)">
                        <g transform="translate(24 255)">
                            <g transform="translate(21 218)">
                                <g transform="translate(0 2.5)">
                                    <path
                                        d="M7.354 5.6H8.99V4H7.354v1.6zm0 6.4H8.99V7.2H7.354V12zm.817-12C3.661 0 0 3.584 0 8s3.66 8 8.171 8c4.511 0 8.172-3.584 8.172-8s-3.66-8-8.172-8z"
                                        transform="translate(85.336)"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </Tippy>
    )
}
