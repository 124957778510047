import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { getRelationList, getT, i18nUri } from '../../../helpers'
import withHelpers from '../../../hooks/withHelpers'

const SinglePersonEdit = ({ data }) => {
    const t = getT()
    const history = useHistory()
    const { i18n } = useTranslation()
    const name = `${data['firstName']} ${data['lastName']}`
    const relation = getRelationList()[data['relation']][i18n.language]
    const editUrl = i18nUri('/form/single-person')
    const editSinglePerson = () => {
        history.push(editUrl)
    }
    return (
        <div className="mt-6 mx-6">
            <div className="flex p-4 border border-gray-400">
                <div className="flex flex-1">
                    <span className="font-bold">{name}</span>
                    <span className="ml-3">{relation}</span>
                </div>
                <div className="w-1/5 text-right text-sm underline font-bold">
                    <button
                        onClick={editSinglePerson}
                        className="text-right text-sm underline font-bold">{t('forms.edit')}</button>
                </div>
            </div>
        </div>
    )
}

export default withHelpers(SinglePersonEdit)