import React from 'react'
import { Grid } from '@material-ui/core'
import LanguageSwitcher from './LanguageSwitcher'
import { useTranslate } from 'ra-core'
import Imports from './Imports';

export default function Dashboard() {
    const translate = useTranslate()
    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                {translate('cfs.control-panel')}
            </Grid>
            <Grid item xs={4}>
                <LanguageSwitcher />
                <Imports />
            </Grid>
        </Grid>
    )
}
