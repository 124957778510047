import { useFormikContext } from 'formik'
import React from 'react'
import { getPersonFieldName, getT } from '../../../helpers'
import ArrayFieldErrorMessage from './ArrayFieldErrorMessage'
import Tooltip from './Tooltip'

export default function Identity({ pos }) {
    const t = getT()
    const formik = useFormikContext()
    const index = Number(pos)
    return (
        <>
            <div className="flex flex-col">
                <div className="flex">
                    <label className="flex" htmlFor={getPersonFieldName('firstName', pos)}>{t('forms.firstName')}</label>
                    <Tooltip label={t('helpers.firstName')} />
                </div>
                <input
                    id={getPersonFieldName('firstName', pos)}
                    type="text"
                    className="mt-1 form-ring-item"
                    {...formik.getFieldProps(getPersonFieldName('firstName', pos))}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                    <div className='form-error'>{formik.errors.firstName}</div>
                ) : null}
                <ArrayFieldErrorMessage name={`persons[${index}].firstName`} />
            </div>

            <div className="flex flex-col">
                <div className="flex">
                    <label className="flex" htmlFor={getPersonFieldName('lastName', pos)}>{t('forms.lastName')}</label>
                    <Tooltip label={t('helpers.lastName')} />
                </div>
                <input
                    id={getPersonFieldName('lastName', pos)}
                    type="text"
                    className="mt-1 form-ring-item"
                    {...formik.getFieldProps(getPersonFieldName('lastName', pos))}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                    <div className='form-error'>{formik.errors.lastName}</div>
                ) : null}
                <ArrayFieldErrorMessage name={`persons[${index}].lastName`} />
            </div>
        </>
    )
}
