import { determineLangFromUri } from "../../helpers";
import withHelpers from "../../hooks/withHelpers";
import Layout from "../Layout";
import ConfirmationWidget from "./comps/ConfirmationWidget";
import axios from "axios";
import React, { useState, useEffect } from "react";

const ConfirmationFlexPage = ({
    t,
    history,
    state,
    i18n,
    setState,
    prevNext: { prev, next },
}) => {
    const [sending, setSending] = useState(true);
    const [code, setCode] = useState(null);
    const [submissionError, setSubmissionError] = useState();
    useEffect(() => {
        if (!state["reviewed"]) {
            history.push(prev);
        }

        const submit = async () => {
            const formData = new FormData();
            formData.append("language", determineLangFromUri());
            formData.append("union", state.selectedUnion);
            formData.append("plan", state.plans.plan);
            formData.append("student", JSON.stringify(state.student));
            if (state.hasOwnProperty("docs")) {
                let count = 0;
                for (let doc of state.docs) {
                    count++;
                    formData.append("docs[]", doc.file);
                }
            }
            const headers = {
                "Content-Type": "multipart/form-data",
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            };
            await axios
                .post("/api/flex/submissions", formData, { headers: headers })
                .then(({ data }) => {
                    setSending(false);
                    setCode(data.code);
                    // now get rid of forms data in state
                    const newState = { ...state };
                    delete newState.plans;
                    delete newState.student;
                    delete newState.docs;
                    delete newState.reviewed;
                    setState(newState);
                })
                .catch((error) => {
                    setSending(false);
                    setSubmissionError(error.response.data.message);
                });
        };
        if (state.hasOwnProperty("reviewed")) submit();
    }, []);

    const goBack = () => {
        history.push(next);
    };
    return (
        <Layout schoolUnionInfo={true}>
            <ConfirmationWidget
                flex={true}
                sending={sending}
                code={code}
                goBack={goBack}
                message={t("flex-confirmation-desc")}
                warning={t("flex-not-approval-desc")}
                submissionError={submissionError}
            />
        </Layout>
    );
};

export default withHelpers(ConfirmationFlexPage);
