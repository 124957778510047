import { useEffect } from "react";

const websiteName = "National Student Health Network";

export const useDocumentTitle = (title) => {
    useEffect(() => {
        setDocumentTitle(title);
    }, [title]);

    return null;
};

export const setDocumentTitle = (title) =>
    (document.title = title ? `${title} | ${websiteName}` : websiteName);
