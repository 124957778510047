import React from 'react'
import { determineLangFromUri, determinePlanCost, getT, getUnionPlans, i18nUri, optInInfo } from '../../../helpers'
import SummaryBlock from './SummaryBlock'

export default function OptInOptionsSummary({ state }) {
    const t = getT()
    const lang = determineLangFromUri()
    const editUrl = i18nUri('/form/options')
    const getSelectedPlan = () => {
        const plans = getUnionPlans()
        const { plan } = state.plans
        const planObj = plans.find(p => p.id === Number(plan))
        const { name_en, name_fr } = planObj
        const cost = determinePlanCost(state.options, planObj)
        const name = (lang === 'en') ? name_en : name_fr
        return `${name} <strong>$${cost}</strong>`
    }
    return (
        <SummaryBlock header={t('optin-options-title')} editUrl={editUrl}>
            {() => {
                return (
                    <ul className="space-y-1">
                        <li>{optInInfo(state)}</li>
                        <li className="">
                            <span className="" dangerouslySetInnerHTML={{ __html: getSelectedPlan() }}></span>
                        </li>
                    </ul>
                )
            }}
        </SummaryBlock >
    )
}
