import {
    ArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    DateInput,
    email,
    FormDataConsumer,
    number,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TextField,
    TextInput,
    useNotify,
} from "react-admin";
import React, { useState } from "react";
import { useTranslate } from "ra-core";
import PropTypes from "prop-types";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    Link,
    TextField as MuiTextField,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
// import PlanInvitationForm from "../Forms/PlanInvitationForm";
import { useForm } from "react-final-form";
import Api from "../../../../services/api";
import RichTextInput from "ra-input-rich-text";

import LinkIcon from "@material-ui/icons/Link";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import Api from "../../../../services/api";

function OptInPeriodTab({
    record,
    studentTypeItems = [],
    degreeTypeItems = [],
    startDateItems = [],
}) {
    const notify = useNotify();
    const { change } = useForm();
    const translate = useTranslate();
    const populateInvitationValues = async (
        planId,
        scopeId = "invite_only_plans[0]",
    ) => {
        try {
            const plan = await Api.plans.get(planId);
            for (const property in plan) {
                switch (property) {
                    case "id":
                        break;
                    case "parent_plan_id":
                        change(`${scopeId}.${property}`, planId);
                        break;
                    case "valid_degree_types":
                    case "valid_student_types":
                    case "valid_start_dates":
                        change(
                            `${scopeId}.${property}`,
                            JSON.parse(plan[property]),
                        );
                        break;
                    default:
                        change(`${scopeId}.${property}`, plan[property]);
                        break;
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const expirationDisplay = (expirationDate) => {
        if (!expirationDate) return "Not Set";

        if (new Date(expirationDate).getTime() > new Date().getTime()) {
            return new Date(expirationDate).toLocaleDateString();
        }
        return "Expired";
    };

    return (
        <div className="w-full">
            <Grid container>
                <Grid container item xs={10} spacing={3}>
                    <Grid item xs={5}>
                        <DateInput fullWidth source="start" />
                    </Grid>
                    <Grid item xs={5}>
                        <DateInput fullWidth source="end" />
                    </Grid>
                </Grid>
            </Grid>

            <BooleanInput
                source="enable_invitations"
                label={translate("labels.unions.enable_invitations")}
            />

            <FormDataConsumer subscription={{ values: true }}>
                {({ formData, ...rest }) => {
                    if (!formData.enable_invitations) return null;

                    return (
                        <ArrayInput source="invite_only_plans" {...rest}>
                            <SimpleFormIterator
                                disableReordering
                                variant="outlined"
                                TransitionProps={{
                                    enter: false,
                                }}
                            >
                                <FormDataConsumer
                                    subscription={{
                                        values: true,
                                    }}
                                >
                                    {({
                                        scopedFormData,
                                        getSource,
                                        ...rest
                                    }) => {
                                        const [open, setOpen] = useState(false);

                                        return (
                                            <Accordion
                                                expanded={open}
                                                onChange={(ev) =>
                                                    setOpen(!open)
                                                }
                                                TransitionProps={{
                                                    unmountOnExit: true,
                                                }}
                                            >
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    aria-label="Expand"
                                                >
                                                    <Grid container>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                variant="caption"
                                                                color="textPrimary"
                                                            >
                                                                {scopedFormData?.email ||
                                                                    "Not Set"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography
                                                                variant="caption"
                                                                color="textPrimary"
                                                            >
                                                                {`${
                                                                    scopedFormData?.name_en ||
                                                                    "Not Set"
                                                                } / ${
                                                                    scopedFormData?.name_fr ||
                                                                    "Not Set"
                                                                }` || "Not Set"}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Typography
                                                                variant="caption"
                                                                color="textPrimary"
                                                            >
                                                                {expirationDisplay(
                                                                    scopedFormData
                                                                        ?.plan_invitation
                                                                        ?.expired_at,
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <MuiTextField
                                                                fullWidth
                                                                variant="standard"
                                                                value={
                                                                    scopedFormData
                                                                        ?.plan_invitation
                                                                        ?.link ||
                                                                    "#"
                                                                }
                                                                InputProps={{
                                                                    endAdornment:
                                                                        (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="Copy Invitation Link"
                                                                                    onFocus={(
                                                                                        event,
                                                                                    ) =>
                                                                                        event.stopPropagation()
                                                                                    }
                                                                                    onClick={(
                                                                                        event,
                                                                                    ) => {
                                                                                        event.stopPropagation();
                                                                                        event.preventDefault();
                                                                                        navigator.clipboard
                                                                                            .writeText(
                                                                                                scopedFormData
                                                                                                    ?.plan_invitation
                                                                                                    ?.link ||
                                                                                                    "#",
                                                                                            )
                                                                                            .then(
                                                                                                () => {
                                                                                                    notify(
                                                                                                        "Link copied",
                                                                                                        "success",
                                                                                                    );
                                                                                                },
                                                                                            );
                                                                                    }}
                                                                                >
                                                                                    <LinkIcon />
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        xs={12}
                                                    >
                                                        <Grid
                                                            container
                                                            item
                                                            spacing={1}
                                                        >
                                                            <Grid item xs={4}>
                                                                <ReferenceInput
                                                                    label={translate(
                                                                        "labels.unions.parent_plan_id",
                                                                    )}
                                                                    source={getSource(
                                                                        "parent_plan_id",
                                                                    )}
                                                                    reference="plans"
                                                                    filter={{
                                                                        planable_id:
                                                                            record.id,
                                                                    }}
                                                                    onChange={(
                                                                        evt,
                                                                    ) =>
                                                                        populateInvitationValues(
                                                                            evt
                                                                                .target
                                                                                .value,
                                                                            rest.id,
                                                                        )
                                                                    }
                                                                >
                                                                    <SelectInput
                                                                        optionText="name_en"
                                                                        fullWidth
                                                                        disabled={
                                                                            !!scopedFormData?.id
                                                                        }
                                                                    />
                                                                </ReferenceInput>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <TextInput
                                                                    {...rest}
                                                                    source={getSource(
                                                                        "email",
                                                                    )}
                                                                    validate={[
                                                                        required(),
                                                                        email(),
                                                                    ]}
                                                                    type="email"
                                                                    label="Email Address"
                                                                    fullWidth
                                                                    disabled={
                                                                        !!scopedFormData?.id
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <RadioButtonGroupInput
                                                                    {...rest}
                                                                    fullWidth
                                                                    source={getSource(
                                                                        "language",
                                                                    )}
                                                                    disabled={
                                                                        !!scopedFormData?.id
                                                                    }
                                                                    label={translate(
                                                                        "resources.unions.fields.language",
                                                                    )}
                                                                    choices={[
                                                                        {
                                                                            id: "en",
                                                                            name: "English",
                                                                        },
                                                                        {
                                                                            id: "fr",
                                                                            name: "French",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <NumberInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        label={translate(
                                                                            "resources.unions.fields.cost",
                                                                        )}
                                                                        source={getSource(
                                                                            "cost",
                                                                        )}
                                                                        validate={[
                                                                            required(),
                                                                            number(),
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <NumberInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        label={translate(
                                                                            "resources.unions.fields.cost_student_and_one",
                                                                        )}
                                                                        source={getSource(
                                                                            "cost_student_and_one",
                                                                        )}
                                                                        validate={[
                                                                            required(),
                                                                            number(),
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <NumberInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        label={translate(
                                                                            "resources.unions.fields.cost_student_and_two_plus",
                                                                        )}
                                                                        source={getSource(
                                                                            "cost_student_and_two_plus",
                                                                        )}
                                                                        validate={[
                                                                            required(),
                                                                            number(),
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <NumberInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        label={translate(
                                                                            "resources.unions.fields.cost_plus_one",
                                                                        )}
                                                                        source={getSource(
                                                                            "cost_plus_one",
                                                                        )}
                                                                        validate={[
                                                                            required(),
                                                                            number(),
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <NumberInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        label={translate(
                                                                            "resources.unions.fields.cost_two_plus",
                                                                        )}
                                                                        source={getSource(
                                                                            "cost_two_plus",
                                                                        )}
                                                                        validate={[
                                                                            required(),
                                                                            number(),
                                                                        ]}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                spacing={1}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <TextInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        source={getSource(
                                                                            "name_en",
                                                                        )}
                                                                        label={translate(
                                                                            "labels.unions.name_en",
                                                                        )}
                                                                        validate={required()}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                >
                                                                    <TextInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        source={getSource(
                                                                            "name_fr",
                                                                        )}
                                                                        label={translate(
                                                                            "labels.unions.name_fr",
                                                                        )}
                                                                        validate={required()}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <RadioButtonGroupInput
                                                            {...rest}
                                                            fullWidth
                                                            source={getSource(
                                                                "plan_type",
                                                            )}
                                                            disabled
                                                            label={translate(
                                                                "resources.unions.fields.plan_type",
                                                            )}
                                                            choices={[
                                                                {
                                                                    id: "EH",
                                                                    name: "Health",
                                                                },
                                                                {
                                                                    id: "DE",
                                                                    name: "Dental",
                                                                },
                                                                {
                                                                    id: "EHDE",
                                                                    name: "Health & Dental",
                                                                },
                                                            ]}
                                                        />
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                spacing={3}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <RichTextInput
                                                                        {...rest}
                                                                        label={translate(
                                                                            "labels.unions.description_en",
                                                                        )}
                                                                        source={getSource(
                                                                            "description_en",
                                                                        )}
                                                                        fullWidth
                                                                        multiline
                                                                        minRows={
                                                                            3
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    <RichTextInput
                                                                        {...rest}
                                                                        fullWidth
                                                                        source={getSource(
                                                                            "description_fr",
                                                                        )}
                                                                        label={translate(
                                                                            "labels.unions.description_fr",
                                                                        )}
                                                                        multiline
                                                                        minRows={
                                                                            3
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                        <CheckboxGroupInput
                                                            {...rest}
                                                            label={translate(
                                                                "resources.unions.fields.valid_student_types",
                                                            )}
                                                            source={getSource(
                                                                "valid_student_types",
                                                            )}
                                                            choices={
                                                                studentTypeItems
                                                            }
                                                        />
                                                        <CheckboxGroupInput
                                                            {...rest}
                                                            label={translate(
                                                                "resources.unions.fields.valid_degree_types",
                                                            )}
                                                            source={getSource(
                                                                "valid_degree_types",
                                                            )}
                                                            choices={
                                                                degreeTypeItems
                                                            }
                                                        />
                                                        <CheckboxGroupInput
                                                            label={translate(
                                                                "resources.unions.fields.valid_start_dates",
                                                            )}
                                                            source={getSource(
                                                                "valid_start_dates",
                                                            )}
                                                            choices={
                                                                startDateItems
                                                            }
                                                        />
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    );
                }}
            </FormDataConsumer>
        </div>
    );
}

OptInPeriodTab.propTypes = {
    record: PropTypes.object.isRequired,
    studentTypeItems: PropTypes.array,
    degreeTypeItems: PropTypes.array,
    startDateItems: PropTypes.array,
};

OptInPeriodTab.defaultProps = {
    studentTypeItems: [],
    degreeTypeItems: [],
    startDateItems: [],
};

export default OptInPeriodTab;
