import { Formik, useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { findUnionObject, i18nUri } from '../../helpers'
import withHelpers from '../../hooks/withHelpers'
import Layout from '../Layout'
import SubmitBtn from '../SubmitBtn'
import Person from './comps/Person'
import Tooltip from './comps/Tooltip'

const SinglePersonFormPage = ({ t, history, state, i18n, setState, prevNext: { prev, next } }) => {
    const defaultValues =
    // { "relation": "commonlaw", "firstName": "Zei", "lastName": "Yaya", "dobMM": "5", "dobDD": "1", "dobYY": "1985", "sex": "female", "agreement": true }
    {
        relation: '',
        firstName: '',
        lastName: '',
        dobMM: '',
        dobDD: '',
        dobYY: '',
        sex: '',
        agreement: false
    }
    React.useEffect(() => {
        if (!state['student']) {
            history.push(prev)
        }
    }, [])
    const validationSchema = Yup.object().shape({
        relation: Yup.string().required(t('errors.required-field')),
        firstName: Yup.string().required(t('errors.required-field')),
        lastName: Yup.string().required(t('errors.required-field')),
        dobMM: Yup.number().required(t('errors.required-field')),
        dobDD: Yup.number().typeError(t('errors.invalid-number'))
            .moreThan(0, t('errors.invalid-number'))
            .lessThan(32, t('errors.invalid-number'))
            .required(t('errors.required-field')),
        dobYY: Yup.number().typeError(t('errors.invalid-number'))
          .moreThan(1919, t('errors.invalid-number'))
          .lessThan(new Date().getFullYear()+1, t('errors.invalid-number'))
          .required(t('errors.required-field')),
        sex: Yup.string().required(t('errors.required-field')),
        agreement: Yup.boolean().oneOf([true], t('errors.accept-agreement'))
    })
    const reviewUrl = i18nUri('/form/review')
    const documentsUrl = i18nUri('/form/documents')
    const submitForm = values => {
        const data = { person: values }
        setState({ ...state, ...data })
        if (state['options']['additional'] === 'person') {
            const unionObj = findUnionObject(state.selectedUnion, state.universities)
            if (unionObj.flexDocUpload === 1) {
                history.push(documentsUrl)
            }
            else {
                history.push(reviewUrl)
            }
        }
        else {
            history.push(next)
        }
    }
    return (
        <Layout schoolUnionInfo={true}>
            <div className="my-4 mx-6">
                <h1 className="">{t('singe-person-title')}</h1>
                <p className="text-sm text-gray-600 mt-2">{t('singe-person-desc')}</p>
            </div>
            <Formik
                initialValues={state['person'] ? state['person'] : defaultValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={submitForm}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
                    <form onSubmit={handleSubmit} className="my-4 mx-6 space-y-4">

                        <Person />

                        <div className="">
                            <label className="mt-8 flex items-start">
                                <input
                                    id="agreement"
                                    name="agreement"
                                    type="checkbox"
                                    value="yes"
                                    className="mr-2 text-dark-green mt-1"
                                    checked={values.agreement}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {t('forms.agreement')}
                                <Tooltip label={t('helpers.person-agreement')} />
                            </label>
                            {touched.agreement && errors.agreement ? (
                                <div className='form-error'>{errors.agreement}</div>
                            ) : null}
                        </div>

                        <div className="mt-4">
                            <SubmitBtn />
                        </div>
                    </form>
                )}
            </Formik>
        </Layout>
    )
}

export default withHelpers(SinglePersonFormPage)