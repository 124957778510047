import React from 'react'
import {
    List,
    Create,
    Edit,
    Datagrid,
    TextField,
    TextInput,
    PasswordInput,
    EditButton,
    SimpleForm,
    required,
    minLength,
    email,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    useTranslate,
} from 'react-admin'
import { CustomCreateToolbar, CustomEditToolbar } from './CustomToolbars'

const validateName = [required(), minLength(6)]
const validateEmail = [required(), email()]
const validatePassword = [required(), minLength(6)]
const validatePasswordEdit = [minLength(6)]
const validatePasswordConfirm = (value, allValues) => {
    if (value !== allValues.password)
        return 'Password mismatch'

    return undefined
}

export const UserList = props => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="email" />
            <EditButton />
        </Datagrid>
    </List>
)

export const UserCreate = props => {
    const translate = useTranslate();
    return (
        <Create title="Add User" {...props}>
            <SimpleForm toolbar={<CustomCreateToolbar />}>
                <TextInput source="name" validate={validateName} />
                <TextInput source="email" validate={validateEmail} />
                <PasswordInput source="password" validate={validatePassword} />
                <PasswordInput source="confirm" validate={validatePasswordConfirm} />
                <BooleanInput source="healthPlanAdmin" helperText={translate("cfs.enableHealthPlanAdminHelperText")} />
                <ReferenceInput source="union_id" reference="unions">
                    <SelectInput optionText="name" helperText={translate("cfs.healthPlanAdminUnionListHelperText")} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
}

const UserTitle = ({ record }) => {
    return <span>{record ? `"${record.name} (${record.email})"` : ''}</span>;
};

export const UserEdit = props => {
    const translate = useTranslate();
    return (
        <Edit title={<UserTitle />} {...props}>
            <SimpleForm toolbar={<CustomEditToolbar alwaysEnableSaveButton />}>
                <TextInput disabled source="id" />
                <TextInput source="name" validate={validateName} />
                <TextInput source="email" validate={validateEmail} />
                <PasswordInput source="password" validate={validatePasswordEdit} />
                <PasswordInput source="confirm" validate={validatePasswordConfirm} />
                <BooleanInput source="healthPlanAdmin" helperText={translate("cfs.enableHealthPlanAdminHelperText")} />
                <ReferenceInput source="union_id" reference="unions">
                    <SelectInput optionText="name" helperText={translate("cfs.healthPlanAdminUnionListHelperText")} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
}